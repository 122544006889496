import { fullMonthDate, numberWithCommas } from "../../../../helpers/GetDuration";
import classes from "./PieChartCustomTooltip.module.css"

const PieChartCustomTooltip = ({ active, payload, total }) => {

    
    if (active && payload && payload.length) {
      console.log("PIE CHART", payload)
      return (
        <div className={`${classes.wrapper} gain-chart-tooltip-wrapper`}>
          {/* <h6>{payload[0]?.name}</h6> */}
          <h6>{`${payload[0]?.name} - ${(Math.round(((+payload[0].value)/total) * 100) * 100) / 100}%`}</h6>
          {/* <div>
            {payload.map((pld) => (
              <div style={{ display: "inline-block", padding: 10 }}>
                <div style={{ color: pld.fill }}>{pld.value}</div>
                <div style={{ color: "gray" }}>{pld.dataKey} drivers</div>
              </div>
            ))}
          </div> */}
        </div>
      );
    }
  
    return null;
  };

  export default PieChartCustomTooltip