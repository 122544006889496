import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import BinIcon from "../../icons/BinIcon";
import FileIcon from "../../icons/FileIcon";
import InfoIcon from "../../icons/InfoIcon";
import classes from "./UploadContainer.module.css"

const UploadContainer = ({title, accept, open, fileSelected, multipleFiles }) =>{
  const [myFiles, setMyFiles] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    setMyFiles([...myFiles, ...acceptedFiles])
    console.log("CHANGE FROM CHILD", acceptedFiles)
  }, [myFiles])

  const { getRootProps, getInputProps, acceptedFiles, isDragActive } =
  useDropzone({
    accept,
    onDrop,
    multiple: multipleFiles ? true : false
  });

  useEffect(() => {
    fileSelected(myFiles)
  }, [myFiles])

  const removeFile = file => () => {
    const newFiles = [...acceptedFiles]
    newFiles.splice(newFiles.indexOf(file), 1)
    setMyFiles(newFiles)
  }

  const files = myFiles.map((file) => (
    <div className="d-flex justify-content-between align-items-center px-3 w-100" key={file.path}>
      <div><FileIcon style={{marginRight: "10px"}}/>{file.path}</div> <BinIcon onClick={removeFile(file)}/>
    </div>
  ));

    return(
      <div className={classes.wrapper}>
        <h4>{title}<span className={`${classes.hoverIcon} ms-2`}><InfoIcon /></span></h4>
        <div className={classes.infoCardWrapper}>
        <div className={classes.infoCard}>
        Am here
      </div>
        </div>
        {files.length > 0 ?  <div className={`${classes.uploadCard} d-flex justify-content-center align-items-center`}>{files}</div>
     : <div {...getRootProps({ className: `${classes.uploadCard} ${isDragActive ? classes.activeDrag : undefined} d-flex justify-content-center align-items-center dropzone` })}>
        <input {...getInputProps()} />
       Drag and drop file here or<button type="button" className={classes.selectButton} role="button" onClick={open}>Select file</button>
        
      </div>}
      
    </div>
   
        // <div className={classes.wrapper}>
        //     <h4>{title}</h4>
        //     <div className={`${classes.uploadCard} d-flex justify-content-center align-items-center`}>
        //     Drag and drop file here or<div className={classes.selectButton} role="button" onClick={handleClick}>Select file</div>
        //     <input
        //         type="file"
        //         ref={hiddenFileInput}
        //         onChange={handleChange}
        //         style={{display: 'none'}} 
        //     />
        //     </div>
        // </div>
    )
}

export default UploadContainer