import React from "react";
import { usePageContext } from "../contexts/page.context";

const PencilIcon = () => {
  const { lightMode } = usePageContext();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.34984 1.28687C10.4624 0.174345 12.2661 0.174345 13.3787 1.28688L14.1844 2.09264C15.297 3.20517 15.297 5.00894 14.1844 6.12147L4.91813 15.3878L1.22306 15.3878C0.593721 15.3878 0.083537 14.8776 0.083539 14.2482L0.083538 10.5532L9.34984 1.28687ZM11.7671 2.89841L12.5729 3.70417C12.7954 3.92668 12.7954 4.28743 12.5729 4.50994L11.3643 5.71859L9.75273 4.10706L10.9614 2.89841C11.1839 2.6759 11.5446 2.6759 11.7671 2.89841ZM9.75273 7.33012L3.97412 13.1087L2.36259 13.1087L2.36259 11.4972L8.1412 5.71859L9.75273 7.33012Z"
        fill={lightMode ? "#000000" : "#ffffff"}
      />
    </svg>
  );
};

export default PencilIcon;
