import classes from "./Setup.module.css";

const Setup = ({children, style}) => {
  return (
    <div
      className={`${classes.wrapper} d-flex justify-content-center align-items-center`}
      
    >
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${classes.cardWrapper}`} style={style}
      >{children}</div>
    </div>
  );
};

export default Setup;
