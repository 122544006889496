import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack } from '@chakra-ui/layout'
import React, { useState } from 'react'
import { PRIMARY_COLOR } from './../../../../../constants/colors'
import { Text } from '../../../components/text'
import CashDetails from './cash/CashDetails'
import useAddFundsStore from '../store'

const DetailsInfo = () => {
    const [detailsShown, setDetailsShown] = useState(false)
    const toggleDetails = useAddFundsStore(s => s.toggleDetails)
    const isBankMethod = useAddFundsStore(s => s.isBankMethod)
    const isCashMethod = useAddFundsStore(s => s.isCashMethod)
    const isCryptoMethod = useAddFundsStore(s => s.isCryptoMethod)

    return (
        <HStack w={"100%"} alignItems={"flex-end"} justifyContent={"space-between"}>
            {isCashMethod() && <CashDetails />}
            {!isCashMethod() && <>
                <Text fontSize={"14px"} fontWeight={"600"} lineHeight={"25px"}>
                    {isBankMethod() && <>
                        To complete, go to your banking application and <br />make the transfer to the custody's account.
                    </>}
                    {isCryptoMethod() && <>
                        To complete, go to your crypto application and <br />make the transfer to the custody's crypo wallet.
                    </>}

                </Text>
                <HStack cursor={"pointer"} id='toggleDetailsBtn' onClick={() => {
                    toggleDetails()
                    setDetailsShown(!detailsShown)
                }} pr={"30px"} pb={"6px"} alignItems={"center"} gap={"10px"}>
                    <Text fontWeight={"700"} fontSize={"13px"} color={PRIMARY_COLOR}>
                        {detailsShown ? 'Show details' : 'Hide details'}
                    </Text>
                    <ChevronDownIcon color={PRIMARY_COLOR} />
                </HStack>
            </>}

        </HStack>
    )
}

export default DetailsInfo