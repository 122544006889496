import { useState } from "react";
import DropDownIcon from "../../icons/DropDownIcon"
import HelpCenterBreadCrumb from "./components/HelpCenterBreadCrumb"
import classes from "./ContactUs.module.css"
import { env } from "../../constants/env";
import { getCookie } from "../../utilities/functions";
import { Helmet } from "react-helmet";

const useOptions = ["My Assets", "Transactions - Add Funds", "Transactions - Send Funds", "Service Fee", "Team Management", "Other"];

const ContactUs = ({themeMode}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [isSending, setIsSending] = useState(false)
    const [message, setMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState()

    const companyId = getCookie("companyId");
    const userId = getCookie("userId");

  const sendRequestHandler = async () => {
    let dataContent = {user: userId, company: companyId, subject: selectedOption, lastMessage: message}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/conversation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      sendMessage(result._id)
      setIsSending(false)
      setSelectedOption("")
      setMessage("")

      setShowSuccess(true)
      setTimeout(() => {
        setShowSuccess(false)
      }, 5000);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      
        await sendRequest();
      
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };


  const sendMessage = async (conversationId) => {
    let dataContent = {user: userId, conversation: conversationId, message: message}
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("ERROR RESPONSE", error?.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
 

      console.log("Message saved", result);

      return result;
    };

    try {
        await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

    return(
        <div className={`${classes.wrapper} container`}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us | Prime Bridge Portal</title>
            <link rel="canonical" href="https://portal.primebridge.io/dashboard/help/contact-us" />
          </Helmet>
          {showSuccess && <div
              className={`${classes.successContainer} d-flex justify-content-center align-items-center`}
            >
              Your inquiry has been sent. Follow up in the <strong className="mx-1">Open Tickets</strong> tab.
            </div>}
            <HelpCenterBreadCrumb mainText="Contact Us" root="/dashboard/help" rootText="Help Center"/>
            <div className={classes.helpText}>Let us know how we can help you. You will hear back from us in 24 business hours via email.</div>
            <div className={`${classes.optionsWrapper}`}>
          <div
            className={`${classes.dropDownField} ${
              showOptions ? classes.bridgeDropDown : undefined
            }`}
            onClick={() => setShowOptions(!showOptions)}
          >
            <div
              className={`${classes.selectedOption} d-flex justify-content-between align-items-center`}
              role="button"
            >
              {selectedOption
                ? selectedOption
                : "Subject"}
              <DropDownIcon dropDown={showOptions} themeColor={themeMode === "light" ? "#141414" : "#fff"}/>
            </div>
            {showOptions &&
              useOptions.map(
                (item) =>
                  item !== selectedOption && (
                    <div
                      className={classes.dropDownOption}
                      onClick={() => setSelectedOption(item)}
                      role="button"
                    >
                      {item}
                    </div>
                  )
              )}
          </div>
          </div>
          <textarea placeholder="How can we help you?" value={message} onChange={(e) => setMessage(e.target.value)}/>
          <div className="d-flex justify-content-center align-items-center">
            {!isSending && <button onClick={sendRequestHandler} style={{background: (message?.length <= 0 || !selectedOption) && "#D0D0D0"}} disabled={message?.length <= 0 || !selectedOption}>Send</button>}
            {isSending && (
              <div className="spinner-border" role="status">
              </div>
            )}
          </div>
          
        </div>
    )
}

export default ContactUs