import InfoIcon from "../../icons/InfoIcon"
import classes from "./IssueBanner.module.css"

const IssueBanner = ({onClick}) => {
    return(
        <div className={`${classes.wrapper} d-flex justify-content-between align-items-center`}>
            <div className={`${classes.infoText} d-flex justify-content-center align-items-center`}><InfoIcon />API service issues are affecting our metrics. We're moving to a backup service to restore accuracy soon.</div>
            <button onClick={onClick}>Close</button>
        </div>
    )
}

export default IssueBanner