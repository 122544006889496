import React, { useEffect } from 'react'
import useAddFundsStore from '../store'
import BankInput from './bank/BankInput'
import CryptoInput from './crypto/CryptoInput'

const MethodInput = () => {
    const isBankMethod = useAddFundsStore(s => s.isBankMethod)
    const isCryptoMethod = useAddFundsStore(s => s.isCryptoMethod)
    const isCashMethod = useAddFundsStore(s => s.isCashMethod)

    return (
        <>
            {isBankMethod() && <BankInput />}
            {isCashMethod() && <BankInput />}
            {isCryptoMethod() && <CryptoInput />}
        </>
    )
}

export default MethodInput