import { AxiosError } from "axios"
import { toast } from "react-hot-toast"

export const MethodOptions = [
    "Wire Transfer · 3-5 business days ",
    "Crypto · 2* confirmations",
    // "Cash Account"
]

export const Coins = [
    "BTC",
    "ETH",
    "USDT",
    "USDC"
]

