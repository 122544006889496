import { env } from "../constants/env";
import { usersActions } from "./users-slice";

// export const fetchWagers = (page, limit) => {
//   return async (dispatch) => {
//     const sendRequest = async () => {
//       const response = await fetch(
//         `${env.marketplaceUrl}/api/wager?page=${page}&limit=${limit}&sort=newest&category=All`
//       );

//       if (!response.ok) {
//         throw new Error("fetching wager data failed.");
//       }

//       const result = await response.json();

//       console.log("Results from wagers redux", result);

//       return result;
//     };

//     try {
//       const data = await sendRequest();
//       dispatch(wagersActions.allWagers({ wagers: data.wagers }));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };



export const  fetchSingleUser = (userId) => {
  return async (dispatch) => {
  const sendRequest = async () => {
    const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Getting user failed.");
    }

    const result = await response.json();
   
    console.log("Results get user redux", result);

    return result;
  };

  try {
    const data = await sendRequest();
    dispatch(usersActions.getUser({ user: data }));
  } catch (error) {
    console.log(error);
  }
}
};


// export const fetchWagersCatergory = (page, limit, sort, category, search) => {
//     return async (dispatch) => {
//       const sendRequest = async () => {
//         const response = await fetch(
//           `${env.marketplaceUrl}/api/wager?page=${page}&limit=${limit}&sort=${sort}&category=${category}&search=${search}`
//         );
  
//         if (!response.ok) {
//           throw new Error("fetching wager data failed.");
//         }
  
//         const result = await response.json();
  
//         console.log("Results from wagers Categories  redux", result);
  
//         return result;
//       };
  
//       try {
//         const data = await sendRequest();
//         dispatch(wagersActions.categoryWagers({ wagersCategory: data.wagers }));
//       } catch (error) {
//         console.log(error);
//       }
//     };
//   };

//   export const submitWager = (option, amount, wagerId, page, limit, sort, category) => {
//     return async (dispatch) => {
//       let wagerContent = { option, amount };
//       dispatch(wagersActions.wagerBetSending({ isSendingWagerBet: true }));
//       const sendRequest = async () => {
//         const response = await fetch(
//           `${env.marketplaceUrl}/api/wager/bet/${wagerId}`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               "x-auth-token": token,
//             },
//             body: JSON.stringify(wagerContent),
//           }
//         );
  
//         if (!response.ok) {
//           dispatch(wagersActions.wagerBetSending({ isSendingWagerBet: false }));
//           const errorText = await response.text();
//           var error = JSON.parse(errorText);
//           dispatch(wagersActions.wagersErrors({ wagerBetError: error.error }));
//           console.log("JSON ERROR ", error);
//           throw new Error(errorText);
//         }
  
//         const result = await response.json();
//         console.log("create wager Results", result);
  
//         return result;
//       };
  
//       try {
//         await sendRequest();
//         dispatch(MostPlay());
//         dispatch(playBoard());
//         dispatch(fetchWagers(1, 3));
//         dispatch(fetchWagersCatergory (page, limit, sort, category));
//         dispatch(wagersActions.wagerBetSending({ isSendingWagerBet: false }));
//         dispatch(wagersActions.wagerBetSent({ sentWagerBet: true }));
//         slotStake()
//       } catch (error) {
//         console.log(error);
//         dispatch(wagersActions.wagerBetSending({ isSendingWagerBet: false }));
//       }
//     };
//   };

// export const getUserTcts = (userId) => {
//   return async (dispatch) => {
//     const sendRequest = async () => {
//       const response = await fetch(`${env.tctUrl}/wallet/${userId}`, {
//         method: "GET",
//         headers: {
//           "x-auth-token": token,
//         },
//       });

//       if (!response.ok) {
//         throw new Error("Fetching user tcts failed.");
//       }

//       const result = await response.json();

//       console.log("Reply from TCT Redux data", result);

//       return result;
//     };

//     try {
//       const data = await sendRequest();
//       dispatch(wagersActions.getUserTcts({ userTcts: data.balance }));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

// export const fetchTctsUsers = () => {
//   return async (dispatch) => {
//     const sendRequest = async () => {
//       const response = await fetch(`${env.marketplaceUrl}/api/tcts-users`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           "x-auth-token": token,
//         },
//       });

//       if (!response.ok) {
//         throw new Error("fetching TCTS and USERS data failed.");
//       }

//       const result = await response.json();

//       console.log("Results from TCTS and USERS recieved", result.data);

//       return result.data;
//     };

//     try {
//       const data = await sendRequest();
//       dispatch(wagersActions.tctsUsers({ tctsStats: data.numTcts,usersStats: data.numUser }));
//     } catch (error) {
//       console.log(error);
//     }
//   };
// };

// export const MostPlay = () => {
//   return async (dispatch) => {
//   let gameContent = {
//     numPlayed: 1,
//     gameName: "wagers",
//     link: "/havana/wagers",
//     imageUrl:
//       "https://s3.amazonaws.com/cdn.prime-bridge/marketplace/games-images/dice.png",
//   };
//   const sendRequest = async () => {
//     const response = await fetch(`${env.marketplaceUrl}/api/most-played`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth-token": token,
//       },
//       body: JSON.stringify(gameContent),
//     });

//     if (!response.ok) {
//       throw new Error("adding Most Played Games stats failed.");
//     }

//     const result = await response.json();

//     console.log("Reply from Most PLayed Game stats data", result);

//     return result;
//   };

//   try {
//     await sendRequest();
//   } catch (error) {
//     console.log(error);
//   }
// }
// };

// export const playBoard = () => {
//   return async (dispatch) => {
//   let gameContent = {
//     game: "Wagers",
//     winnings: 0,
//     imageUrl:
//       "https://s3.amazonaws.com/cdn.prime-bridge/marketplace/games-images/dice.png",
//   };
//   const sendRequest = async () => {
//     const response = await fetch(`${env.marketplaceUrl}/api/playboard`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth-token": token,
//       },
//       body: JSON.stringify(gameContent),
//     });

//     if (!response.ok) {
//       throw new Error("adding Game stats failed.");
//     }

//     const result = await response.json();

//     console.log("Reply from Game stats data", result);

//     return result;
//   };

//   try {
//     await sendRequest();
//   } catch (error) {
//     console.log(error);
//   }
// }
// };

// const slotStake = async () => {
//   let gameContent = { winnings: 0, won: 0, played: 1 };
//   const sendRequest = async () => {
//     const response = await fetch(`${env.marketplaceUrl}/api/slot/stats`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth-token": token,
//       },
//       body: JSON.stringify(gameContent),
//     });

//     if (!response.ok) {
//       throw new Error("adding Game stats failed.");
//     }

//     const result = await response.json();

//     console.log("Reply from Game stats data", result);

//     return result;
//   };

//   try {
//     await sendRequest();
//   } catch (error) {
//     console.log(error);
//   }
// };
