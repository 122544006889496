import { Input } from "@chakra-ui/input";
import React from "react";
import { usePageContext } from "../../../../contexts/page.context";

const TextInput = ({ id, value, onChange, placeholder }) => {
  const { lightMode } = usePageContext();
  return (
    <Input
      value={value}
      id={id}
      flexGrow={1}
      onChange={onChange}
      placeholder={placeholder}
      borderRadius={"50px"}
      px={"20px"}
      fontSize={"14px"}
      focusBorderColor={"transparent"}
      py={"11px"}
      border={"none"}
      width={"100%"}
      fontWeight={"500"}
      fontFamily={"Lato"}
      color={lightMode ? "#000" : "#fff"}
      _placeholder={{
        fontWeight: "500",
        color: lightMode ? "#7f7f7f" : "#d0d0d0",
      }}
      bg={lightMode ? "#f5f5f5" : "#01293E"}
    />
  );
};

export default TextInput;
