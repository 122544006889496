import { useNavigate } from "react-router-dom";
import SmallWhiteEnvelopIcon from "../../../icons/SmallWhiteEnvelopIcon"
import classes from "./HelpCard.module.css"

const HelpCard = ({children, title, button, desc, clickable}) => {
    const navigate = useNavigate();
    return(
       <div className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`} role={clickable && "button"} onClick={() => clickable && navigate("/dashboard/help/categories")}>
           {children}
           <div className="d-flex flex-column justify-content-center align-items-center">
           <h4>{title}</h4>
           {desc && <div className={classes.descText}>
            {desc}
           </div>}
           {button && <div className={`d-flex justify-content-center align-items-center ${classes.buttonsWrapper}`} ><button style={{background: "#033C59"}} onClick={() => navigate("/dashboard/help/open-tickets")}>Open Tickets</button><button onClick={() => navigate("/dashboard/help/contact-us")}>New Ticket</button></div>}
           </div>
       </div> 
    )
}

export default HelpCard