import BankIcon from "../../icons/BankIcon";
import IdentityIcon from "../../icons/IdentityIcon";
import RestrictionIcon from "../../icons/RestrictionIcon";
import SetupButton from "../UI/SetupButton";
import classes from "./VerifyCard.module.css";

const InfoData = ({ children, descText }) => {
  return (
    <div className={`d-flex align-items-center ${classes.infoWrapper}`}>
      <div>{children}</div>
      <div>{descText}</div>
    </div>
  );
};

const VerifyCard = ({onClick}) => {
  return (
    <>
      <img
        src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/user-card.svg"
        alt=""
      />
      <h2 className={classes.titleText}>
        Verify your company identity to keep using Prime Bridge
      </h2>
      <div className={`${classes.listContainer} `}>
        <InfoData descText="Your account is restricted from transacting">
          <RestrictionIcon />
        </InfoData>
        <InfoData descText="For compliance and anti-money laundering policies, we are required to verify you and your company">
          <BankIcon />
        </InfoData>
        <InfoData descText="Verify your identity to continue using Prime Bridge">
          <IdentityIcon />
        </InfoData>
      </div>
      <SetupButton text="Verify Identity" onClick={onClick} />
    </>
  );
};
export default VerifyCard;
