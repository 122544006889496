import React, { PureComponent } from 'react';
import styles from "./PieChartComponent.module.css"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import PieChartCustomTooltip from './components/PieChartCustomTooltip';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 }
// ];

// const COLORS = ['#416CAD', '#6792FF', '#023C5A'];

const COLORS = ['#332693', '#1f5ba8', '#2d2bc4', '#1f2da8', '#221e8c', '#4e4cbf', '#1f0760', '#01a8b7', '#0627cc', '#102072', '#4878bf', '#4624cc', '#629aef', '#0b687c', '#166972', '#0202dd', '#381aaf', '#2c049b', '#5743b7', '#4d71b5'];



const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default class PieChartComponent extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj';

  render() {
    const {data} = this.props

    

     const newData = data.map(obj => {
        const pair = Array.from(obj.name)  
        pair.splice(3, 0, '/')
        return {...obj, name: pair.join('') }
        } 
    )

    const total = newData.reduce((n, {value}) => n + value, 0)


    // const legendData = [
    //   {
    //     color: "#416CAD",
    //     title: data[0].name
    //   },
    //   {
    //     color: "#6792FF",
    //     title: data[1].name
    //   },
    //   {
    //     color: "#023C5A",
    //     title: data[2].name
    //   }
    // ]
    return (
      <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={300} height={300}>
        <Tooltip content={<PieChartCustomTooltip  total={total}/>} cursor={{ fill: "transparent" }} />
          <Pie
            data={newData}
            cx="50%"
            cy="45%"
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={180}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend  verticalAlign="bottom" align="center"   height={16} />
        </PieChart>
        {/* <Legend /> */}
      </ResponsiveContainer>
      {/* <div className='d-flex justify-content-center align-items-center gap-4'>
        {legendData.map((item, i) => (<div className={`${styles.legendWrapper} d-flex justify-content-center align-items-center gap-2`} key={i}><div className={styles.legendContainer} style={{background: item.color}}></div>{item.title}</div>))}
      </div> */}
      </div>
    );
  }
}
