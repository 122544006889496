import { useNavigate } from "react-router-dom";
import AuthWrapper from "../../components/Auth/AuthWrapper";
import classes from "./ForgotPassword.module.css";
import { env } from "../../constants/env";
import { useState } from "react";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState()
  const [email, setEmail] = useState()
  const [loginError, setLoginError] = useState("")
  const [success, setSuccess] = useState()

  const resetPassword = async() => {
    const dataContent = {email: email, baseUrl: env.baseUrl}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/password-reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("Error from Reset Password: ",error)
        setLoginError(error.error)
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setIsSending(false)

      setSuccess(true)
      setLoginError(result?.message)
      setEmail("")

      console.log("Reset response", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  }
  return (
    <AuthWrapper cardHeight="300px">
      <h3>Forgot Password?</h3>
        <h6>Enter your email below to receive your password reset instructions</h6>
        <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
        {loginError && <h6 style={{color: success ? "#5DBE94" : "#BD3437"}}>{loginError}</h6>}
        {!isSending &&  <button onClick={resetPassword}>
          Submit
        </button>}
        {isSending && (
          <div class="spinner-border" role="status">
          </div>
        )}
        <div className={classes.forgotPasswordText} role="button" onClick={() => navigate("/")}>Back to Login</div>
    </AuthWrapper>
  );
};
export default ForgotPassword;
