import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Setup from "../../components/UI/Setup";
import { env } from "../../constants/env";
import useWindowDimensions from "../../hooks/getWindowDimensions";
import { getCookie } from "../../utilities/functions";
import PickYourCustody from "../../components/CustodyProvider/PickYourCustody";
import CustodyProviders from "../../components/CustodyProvider/CustodyProviders";
import YourCustody from "../../components/CustodyProvider/YourCustody";
import { toast } from "react-hot-toast";

const CustodyProvider = () => {
  const [pickYourCustody, setPickYourCustody] = useState(true);
  const [custodyProviders, setCustodyProviders] = useState(false);
  const [yourCustody, setYourCustody] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState([])
  const [isSending, setIsSending] = useState(false);

  const navigate = useNavigate();

  const companyId = getCookie('companyId')

  const sendCustodyProvider = async () => {
    const sendRequest = async () => {
    setIsSending(true);
    let dataContent = { company: companyId, provider: selectedProvider.provider, title: selectedProvider.title, details: selectedProvider.details, footNote: selectedProvider.footNote, index: selectedProvider.index};
    const response = await fetch(
      `${env.primeBridgeUrl}/api/custody-provider`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent),
      }
    );

    if (!response.ok) {
      setIsSending(false);
      const error = await response.json();
      console.log("ERRROR", error)
      toast.error(error.error)
      throw new Error("posting data failed.");
    }

    const result = await response.json();

    setYourCustody(false); 
    navigate("/dashboard")

    return result;
  };

  try {
    await sendRequest();
  } catch (error) {
    console.log(error);
    setIsSending(false);
  }
}
  
  return (
    <Setup>
      {pickYourCustody && <PickYourCustody onClick={() => {setPickYourCustody(false); setCustodyProviders(true)}}/>}
      {custodyProviders && <CustodyProviders onClick={() => {setCustodyProviders(false); setYourCustody(true)}} selectedProvider={(result) => setSelectedProvider(result)} currectProvider={selectedProvider}/>}
      {yourCustody && <YourCustody onClick={sendCustodyProvider} changeProvider={() => { setYourCustody(false); setCustodyProviders(true)}} selectedProvider={selectedProvider} isSending={isSending}/>}
      
    </Setup>
  );
};

export default CustodyProvider;
