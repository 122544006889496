import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Setup from "../UI/Setup";
import SetupButton from "../UI/SetupButton";
import classes from "./PoliciesCard.module.css";

const policiesData = [
  {
    title: "Terms of Use",
    desc: "",
    tag: "terms",
  },
  {
    title: "Privacy Policy",
    desc: "",
    tag: "privacy",
  },
  {
    title: "Insurance",
    desc: "",
    tag: "insurance",
  },
];

const PoliciesCard = ({onClick}) => {
  const [activeTab, setActiveTab] = useState("terms");
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center  ${classes.logoWrapper} `}
      >
        <img
          src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-logo.svg"
          alt=""
        />
        <h3>Prime Bridge</h3>
      </div>
      <h2 className={classes.titleText}>Agreements</h2>
      <div className={classes.agreementsContainer}>
        <div className="d-flex justify-content-between align-items-center p-3">
          {policiesData.map((item, i) => (
            <div
              className={`${classes.tab} ${
                activeTab === item.tag ? classes.activeTab : undefined
              }`}
              key={i}
              role="button"
              onClick={() => setActiveTab(item.tag)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className={classes.descWrapper}>
        {activeTab === "terms" && <div>
        {/* <h5>TERMS OF USE</h5> */}
<p>Welcome to Prime Bridge's platform for market-making and liquidity solutions. By accessing or
using our platform, you agree to be bound by the following terms and conditions of use (the
"Terms"). Please do not use our platform if you do not agree to these Terms.</p>
<p>1. Service Description</p>
<p>Prime Bridge provides a platform for market-making and liquidity solutions. We offer our
services to clients interested in maximizing their returns and managing risk in the financial
markets.</p>
<p>2. Account Eligibility</p>
<p>To use our platform, you must be at least 18 years of age and have the legal capacity to enter
into a binding agreement. Using our platform, you represent and warrant that you meet these
eligibility requirements.</p>
<p>3. User Conduct</p>
<p>When using our platform, you agree to comply with all applicable laws and regulations and to
not engage in any illegal or harmful activities, including but not limited to the transmission of
viruses or other harmful code or the use of our platform for any unauthorized or malicious
purposes.</p>
<p>4. Intellectual Property</p>
<p>text, graphics, logos, icons, images, and software, are protected by intellectual property laws
and are owned or licensed by Prime Bridge. Without our prior written consent, you may not use
any content or materials on our platform for any commercial purposes.</p>
<p>5. Limitation of Liability</p>
<p>Prime Bridge shall not be liable for any damages arising from or in connection with the use of
our platform. This includes, but is not limited to, any indirect, incidental, special, or
consequential damages, even if Prime Bridge has been advised of the possibility of such
damages.</p>
<p>6. Changes to Terms</p>
<p>Prime Bridge reserves the right to modify these Terms at any time, and your continued use of
our platform after any changes have been made constitutes your acceptance of the revised
Terms.</p>
<p>7. Termination</p>
<p>Prime Bridge may terminate or suspend your use of our platform at any time, with or without
cause, with or without notice.</p>
<p>These Terms constitute the entire agreement between you and Prime Bridge concerning the use
of our platform and supersede all prior agreements and understandings, whether written or oral,
between the parties.</p>
<p>By using our platform, you agree to be bound by these Terms. Don't hesitate to contact our
support team if you have any questions or concerns regarding these Term</p>
        </div>}
        {activeTab === "privacy" && <div>
          {/* <h5>PRIVACY POLICY</h5> */}
<p>At Prime Bridge, we take the privacy of our client's personal data seriously and are committed to
protecting it. This Privacy Policy outlines our practices concerning collecting, using, sharing, and
protecting personal data.</p>
<p>1. Information Collection</p>
<p>Prime Bridge collects information from its clients and website visitors to provide them with the
best possible services and experience. This information may include personal data such as
names, addresses, contact information, and financial information.</p>
<p>2. Information Use</p>
<p>Prime Bridge uses collected information to provide its clients with the requested services and
improve its platform's overall functionality. This may include using personal data for account
management, trade execution, and risk management.</p>
<p>3. Information Sharing</p>
<p>Prime Bridge does not sell, rent, or otherwise share its clients' data with third parties except as
required by law. Prime Bridge may share information with its affiliates and service providers to
provide its services.</p>
<p>4. Information Security</p>
<p>Prime Bridge takes the security of its client's data seriously and implements appropriate
technical and organizational measures to protect it. This includes secure storage and
transmission of information and regular security audits and risk assessments.</p>
<p>5. Data Retention</p>
<p>Prime Bridge retains personal data for as long as necessary to provide its services and meet
legal requirements. After this time, personal data will be securely deleted.</p>
<p>6. Data Access</p>
<p>Prime Bridge's clients have the right to access their personal data and request that it be
corrected or deleted. Clients may exercise this right by contacting Prime Bridge's support team.</p>
<p>7. Changes to Privacy Policy</p>
<p>Prime Bridge may update this privacy policy occasionally. Clients should regularly review this
policy to stay informed of any changes.</p></div>}
        {activeTab === "insurance" && <div>
          {/* <p>"Prime Bridge Customer Deposit Insurance Policy Statement"</p> */}
<p>The management of Prime Bridge is dedicated to providing its clients with a secure and 
dependable platform for market-making and liquidity solutions. While we try to protect our 
client's funds, we must clarify that we do not offer insurance coverage for customer deposits.</p>
<p>We strongly recommend that our clients take appropriate measures to secure their funds and 
explore their options for obtaining private insurance coverage. This will provide them with 
additional protection against potential losses in unforeseen circumstances.</p>
<p>It is important to note that while we utilize regulated third-party custodians to hold our clients' 
crypto and fiat deposits, this does not imply that these deposits are insured against the failure of 
these third parties. Our clients are solely responsible for ensuring the security of their funds and 
should carefully consider their insurance options before depositing funds with Prime Bridge.</p>
<p>This policy statement constitutes an integral part of the agreement between Prime Bridge and its 
clients and is binding on both parties. If you have any questions or concerns regarding this policy
statement, please do not hesitate to contact our support team</p></div>}
      </div>
      </div>
      
      <div className="w-100">
        <SetupButton text="Accept All" onClick={onClick}/>
        <h6 className={`mt-2 ${classes.reviewLaterText}`}> 
          You can also <span role="button" onClick={() => navigate("/")}>sign out</span> and review this later.
        </h6>
      </div>
    </>
  );
};

export default PoliciesCard;
