import Setup from "../UI/Setup"
import classes from "./VerificationPendingCard.module.css"

const VerificationPendingCard = () =>{
    return(
        <>
            <img src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/time.svg" alt=""/>
            <h2 className={classes.titleText}>Your verification is being processed by the compliance team</h2>
            <div className={classes.descText}>
                <p>Please allow up to 24 business hours. In the meantime, if you have any questions, please <a href="mailto:accounts@primebridge.io">contact your account manager.</a></p>
            </div>
        </>
    )
}

export default VerificationPendingCard