import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { env } from "../../constants/env";
import AddUserIcon from "../../icons/AddUserIcon";
import PencilIcon from "../../icons/PencilIcon";
import { getCookie } from "../../utilities/functions";
import classes from "./MembersTable.module.css";
import AddUserSolidIcon from "../../icons/AddUserSolidIcon";
import { Helmet } from "react-helmet";
import Switcher from "../UI/switcher/Switcher";
import { useDispatch, useSelector } from "react-redux";
import { companyActions } from "../../Redux/company-slice";

const MemberHeader = () => {
  return (
    <>
      <tr style={{ color: "#0077B5" }}>
        <th>Members</th>
        <th>Access</th>
        <th>Actions</th>
      </tr>
    </>
  );
};

const MemberRow = ({ member }) => {
  const navigate = useNavigate();
  const formatText = (str) => {
    const replaced = str.replaceAll("-", " ").replaceAll(",", ", ");
    const words = replaced.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };

  return (
    <tr>
      <td>{member.firstName}</td>
      <td>{formatText(member.accessLevel.toString())}</td>
      <td style={{ color: "#0077B5" }}>
        <span
          role="button"
          className="d-flex align-items-center gap-1"
          onClick={() => navigate(`/dashboard/team/edit-member/${member._id}`)}
        >
          <PencilIcon /> Edit
        </span>
      </td>
    </tr>
  );
};

const MembersTable = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [teamMembers, setTeamMembers] = useState([]);
  const [themeMode, setThemeMode] = useState("light");
  const companyId = getCookie("companyId");
  const userId = getCookie("userId");
  const [activate2FA, setActivate2FA] = useState(false);

  const twoFactor = useSelector(state => state.company.twoFactor)

  const singleUser = useSelector(state => state.users.user)

  console.log("2FA STATUS", activate2FA)

  console.log("2FA REDUX", twoFactor)

  useEffect(() => {
    getUsers();
    const theme = localStorage.getItem("theme");
    setThemeMode(theme);
  }, []);

  useEffect(() => {
    setActivate2FA(twoFactor)
  }, [twoFactor])

  const getUsers = async () => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/auth/portal-users/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setTeamMembers(result);

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateTwoFactor = async (status) => {

    console.log("STATUS", status)
    const dataContent = { status }
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/two-factor/${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent)
        }
      );

      if (!response.ok) {
        const error = await response.json()

        console.log("2 Factor AUTH ERROR", error)

        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("2 Factor Authentication", result);

      setActivate2FA(status)
      dispatch(companyActions.getTwoFactorStatus({ twoFactor: status }));

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={`${classes.wrapper} container`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Team | Prime Bridge Portal</title>
        <link
          rel="canonical"
          href="https://portal.primebridge.io/dashboard/team"
        />
      </Helmet>
      <div
        className={`${classes.topSection} d-flex justify-content-between align-items-center mb-4`}
      >
        <h3>Team</h3>
        <button
          className="d-flex justify-content-center align-items-center"
          onClick={() => navigate("/dashboard/team/add-member")}
        >
          {themeMode === "dark" ? <AddUserSolidIcon /> : <AddUserIcon />}Add
          Member
        </button>
      </div>
      <div className={classes.tableDiv}>
        <table>
          <MemberHeader />
          {teamMembers.map((member, i) => (
            <>
              {!member.isAdmin && member._id !== userId && (
                <MemberRow member={member} key={i} />
              )}
            </>
          ))}
        </table>
        {singleUser?.isAdmin && <div className="d-flex justify-content-between align-items-end mt-5">
          <div className={classes.twoFAText}>
            <h3>2-Factor Authentication Security</h3>
            <h6>Request Login Access Code</h6>
          </div>
          <Switcher
            value={activate2FA}
            onChange={val => {
              console.log('STATUS VALUE', val)
              updateTwoFactor(val)
            }}
          />
        </div>}
      </div>
    </div>
  );
};

export default MembersTable;
