import React from "react";
import { usePageContext } from "../../../contexts/page.context";

const CloseIcon = () => {
  const { lightMode } = usePageContext();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M2.09821 2L19 18M18.9018 2L2 18"
        stroke={lightMode ? "black" : "white"}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
