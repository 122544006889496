import CardContainer from "../cardContainer/CardContainer";
import styles from "./MonthlyGainMobile.module.css";

const MonthlyGainMobile = ({ num, isNegative, data, index }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div className={styles.wrapper}>
    <CardContainer>
      <div className="d-flex justify-content-between">
        <div className={``}>
          <div className={styles.yearText}>Year</div>
          {months.map((item, i) => (
            <div className={styles.monthText}>{item}</div>
          ))}
          <div className={styles.totalText}>Total</div>
        </div>
        <div className={styles.vertical}></div>
        {data.map((item, i) => (
          <div>
            <div className={styles.yearText}>{item.year}</div>
            {item.data.map((dataItem, index) => (
            <div className={`${styles.percentageText} ${item.data[index].num <= -1 && 'isNegativeNumber'}`} style={{color: item.data[index].num <= -1 && "#BD3437" }}>{dataItem.num}</div>
          ))}
          </div>
        ))}

        {/* {data.map((item, i)=> (<div className={`${styles.percentageText} ${item.data[index].num <= -1 && 'isNegativeNumber'}`} style={{color: item.data[index].num <= -1 && "#F83458" }}>{item.data[index].num && (item.data[index].num <= -1 ? item.data[index].num + "%" : "+" + item.data[index].num + "%")}</div>))} */}
      </div>
    </CardContainer>
    </div>
  );
};

export default MonthlyGainMobile;
