import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { env } from "../../constants/env";
import HelpCenterBreadCrumbs from "../Help/components/HelpCenterBreadCrumb"
import classes from "./AddMember.module.css"
import { Helmet } from "react-helmet";
import { getCookie } from "../../utilities/functions";

const EditMember = () => {

  const { id } = useParams()

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [assetChecked, setAssetChecked ] = useState(true);
  const [transactionsChecked, setTransactionsChecked] = useState(false);
  const [helpChecked, setHelpChecked] = useState(false);
  const [accessLevel, setAccessLevel] = useState([])
  
  const navigate = useNavigate();
  

  const [isSending, setIsSending] = useState(false)

  const [overview, setOverview] = useState(false)

  const companyId = getCookie('companyId')


  useEffect(() => {
    fetchSingleCompany(companyId)
  },[companyId])

  useEffect(() => {
    getUser()
  }, [])

  const  getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setFirstName(result?.firstName)
      setLastName(result?.lastName)
      setEmail(result?.email)
      setAccessLevel(result?.accessLevel)


      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const updateUserHandler = async () => {
    
    let dataContent = {firstName: firstName, lastName: lastName, email: email,  accessLevel: accessLevel}
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/update-user/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false)
        console.log("ERROR RESPONSE", response)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)


      navigate('/dashboard/team')

      console.log("Updated User", result);

      return result;
    };

    try {
        await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };


  const  deleteUserHandler = async () => {
    const sendRequest = async () => {
      setIsSending(true)
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/delete/${id}`, {
        method: 'DELETE',
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setIsSending(false)
      navigate('/dashboard/team')

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      if(!isSending){
        await sendRequest();
      }
      
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const fetchSingleCompany =  async(companyId) => {
    try {
        const response = await fetch(
          `${env.primeBridgeUrl}/api/company/${companyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("fetching company failed.");
        }
  
        const result = await response.json();

        console.log("SINGLE COMPANY", result)
        setOverview(result.overview)
  
        return result;
  
      } catch (error) {
        console.log(error);
      }
  };


  
    return(
        <div className={`${classes.wrapper} container`}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Edit Member | Prime Bridge Portal</title>
              <link rel="canonical" href="https://portal.primebridge.io/dashboard/team" />
          </Helmet>
          <div className={`${classes.breadCrumbs} d-flex justify-content-between align-items-baseline`}>
            <div>
            <h6 onClick={() => navigate('/dashboard/team')} role="button">Team</h6>
            <h3>Edit Member</h3>
            </div>
            <div>
            <button onClick={deleteUserHandler}>Delete</button>
            </div>
          </div>

            <h5>Login Information</h5>

            <div className={classes.formWrapper}>
        <div className={`row`}>
          <div className="col-6">
            <input
              value={firstName}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="col-6">
            <input
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <input placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)}/>
       
      </div>

      <h4>User Access</h4>
      {overview && <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.checkInput}
          type="checkbox"
          name="overview"
          value="overview"
          checked={accessLevel.includes("overview")}
          onChange={(e) => {
            
            if(!accessLevel.includes("overview")){
              setAccessLevel([...accessLevel, "overview"])
            }else{
              setAccessLevel((current) => current.filter((access) => access !== "overview"))
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Overview section</div>
      </div>}
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
            
            <input
              className={classes.checkInput}
              type="checkbox"
              name="asset"
              value="asset"
              checked={accessLevel.includes("asset-dashboard")}
              onChange={(e) => {
                if(!accessLevel.includes("asset-dashboard")){
                  setAccessLevel([...accessLevel, "asset-dashboard"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "asset-dashboard"))
                }
              }}
            />
            <div className={`${classes.radioLabel} ms-2`}>Asset Dashboard</div>
          </div>
          <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
            
            <input
              className={classes.radioInput}
              type="checkbox"
              name="transactions"
              value="transactions"
              checked={accessLevel.includes("transactions")}
              onChange={(e) => {
                if(!accessLevel.includes("transactions")){
                  setAccessLevel([...accessLevel, "transactions"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "transactions"))
                }
              }}
            />
            <div className={`${classes.radioLabel} ms-2`}>Transactions</div>
          </div>
          <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
            className={classes.radioInput}
            type="checkbox"
            name="team"
            value="team"
            checked={accessLevel.includes("team")}
            onChange={(e) => {
              if(!accessLevel.includes("team")){
                setAccessLevel([...accessLevel, "team"])
              }else{
                setAccessLevel((current) => current.filter((access) => access !== "team"))
              }
            }}
          />
          <div className={`${classes.radioLabel} ms-2`}>Team</div>
        </div>
        <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="weekly-reports"
          value="weekly-reports"
          checked={accessLevel.includes("weekly-reports")}
          onChange={(e) => {
            if(!accessLevel.includes("weekly-reports")){
              setAccessLevel([...accessLevel, "weekly-reports"])
            }else{
              setAccessLevel((current) => current.filter((access) => access !== "weekly-reports"))
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Weekly Reports (via email)</div>
      </div>
      <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
        <input
          className={classes.radioInput}
          type="checkbox"
          name="weekly-snapshot"
          value="weekly-snapshot"
          checked={accessLevel.includes("weekly-snapshot")}
          onChange={(e) => {
            if(!accessLevel.includes("weekly-snapshot")){
              setAccessLevel([...accessLevel, "weekly-snapshot"])
            }else{
              setAccessLevel((current) => current.filter((access) => access !== "weekly-snapshot"))
            }
          }}
        />
        <div className={`${classes.radioLabel} ms-2`}>Overview Weekly Snapshot (via email)</div>
      </div>
          <div className={`${classes.checkboxContainer} d-flex align-items-center`}>
            
            <input
              className={classes.radioInput}
              type="checkbox"
              name="help"
              value="help"
              checked={accessLevel.includes("help-center")}
              onChange={(e) => {
                if(!accessLevel.includes("help-center")){
                  setAccessLevel([...accessLevel, "help-center"])
                }else{
                  setAccessLevel((current) => current.filter((access) => access !== "help-center"))
                }
              }}
            />
            <div className={`${classes.radioLabel} ms-2`}>Help Center</div>
          </div>

          <div className={`${classes.bottomButtons} d-flex justify-content-center align-items-center`}>
          {!isSending && (<><button className={classes.cancleButton} onClick={() => navigate('/dashboard/team')}>Cancel</button> <button className="addbutton" onClick={updateUserHandler}>Save</button></>)}
            {isSending && (
          <div className="spinner-border" role="status">
          </div>
        )}
          </div>
        </div>

    )
}

export default EditMember