const FileIcon = ({style}) => {
    return(
        <svg width="27" height="32" viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <path d="M25 10C24.7348 10 24.4804 10.1054 24.2929 10.2929C24.1054 10.4804 24 10.7348 24 11V29C24 29.2652 23.8946 29.5196 23.7071 29.7071C23.5196 29.8946 23.2652 30 23 30H3C2.73478 30 2.48043 29.8946 2.29289 29.7071C2.10536 29.5196 2 29.2652 2 29V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H18V7C18 7.26522 18.1054 7.51957 18.2929 7.70711C18.4804 7.89464 18.7348 8 19 8H25C25.1986 8.00115 25.3931 7.94313 25.5586 7.83333C25.7241 7.72353 25.8532 7.56693 25.9294 7.38348C26.0055 7.20004 26.0254 6.99807 25.9863 6.80332C25.9472 6.60857 25.851 6.42987 25.71 6.29L19.71 0.29C19.5319 0.123323 19.303 0.0212099 19.06 0C18.76 0 18.25 0 3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V29C0 29.7956 0.316071 30.5587 0.87868 31.1213C1.44129 31.6839 2.20435 32 3 32H23C23.7956 32 24.5587 31.6839 25.1213 31.1213C25.6839 30.5587 26 29.7956 26 29V11C26 10.7348 25.8946 10.4804 25.7071 10.2929C25.5196 10.1054 25.2652 10 25 10ZM20 6V3.41L22.59 6H20Z" fill="#033C59"/>
        <path d="M20 14H11C10.7348 14 10.4804 13.8946 10.2929 13.7071C10.1054 13.5196 10 13.2652 10 13C10 12.7348 10.1054 12.4804 10.2929 12.2929C10.4804 12.1054 10.7348 12 11 12H20C20.2652 12 20.5196 12.1054 20.7071 12.2929C20.8946 12.4804 21 12.7348 21 13C21 13.2652 20.8946 13.5196 20.7071 13.7071C20.5196 13.8946 20.2652 14 20 14Z" fill="#D0D0D0"/>
        <path d="M20 18H6C5.73478 18 5.48043 17.8946 5.29289 17.7071C5.10536 17.5196 5 17.2652 5 17C5 16.7348 5.10536 16.4804 5.29289 16.2929C5.48043 16.1054 5.73478 16 6 16H20C20.2652 16 20.5196 16.1054 20.7071 16.2929C20.8946 16.4804 21 16.7348 21 17C21 17.2652 20.8946 17.5196 20.7071 17.7071C20.5196 17.8946 20.2652 18 20 18Z" fill="#D0D0D0"/>
        <path d="M20 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21C5 20.7348 5.10536 20.4804 5.29289 20.2929C5.48043 20.1054 5.73478 20 6 20H20C20.2652 20 20.5196 20.1054 20.7071 20.2929C20.8946 20.4804 21 20.7348 21 21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22Z" fill="#D0D0D0"/>
        <path d="M15 26H6C5.73478 26 5.48043 25.8946 5.29289 25.7071C5.10536 25.5196 5 25.2652 5 25C5 24.7348 5.10536 24.4804 5.29289 24.2929C5.48043 24.1054 5.73478 24 6 24H15C15.2652 24 15.5196 24.1054 15.7071 24.2929C15.8946 24.4804 16 24.7348 16 25C16 25.2652 15.8946 25.5196 15.7071 25.7071C15.5196 25.8946 15.2652 26 15 26Z" fill="#D0D0D0"/>
        </svg> 
    )
}

export default FileIcon