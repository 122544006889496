import { Center } from "@chakra-ui/layout";
import React from "react";
import { PRIMARY_COLOR } from "../../../../constants/colors";
import { usePageContext } from "../../contexts/page.context";
import CheckIcon from "../../icons/Check.icon";

const Checkbox = ({ value, onChange }) => {
  const { lightMode } = usePageContext();
  return (
    <Center
      onClick={() => onChange(!value)}
      width={"20px"}
      height={"20px"}
      borderRadius={"3px"}
      borderWidth={"2px"}
      cursor={"pointer"}
      bg={!!value ? (lightMode ? PRIMARY_COLOR : "white") : "transparent"}
      borderColor={lightMode ? PRIMARY_COLOR : "white"}
    >
      {!!value ? <CheckIcon /> : null}
    </Center>
  );
};

export default Checkbox;
