import classes from './CustodyProvider.module.css'

const YourCustody = ({onClick, changeProvider, selectedProvider, isSending}) => {
    return(
        <div className={`${classes.wrapper} d-flex flex-column justify-content-between align-items-center gap-5 w-100`}>
            <h3>Your Custody Provider</h3>
            <div className={`${classes.resultBox} w-100`}>
                <h5>{selectedProvider.title}</h5>
                {selectedProvider?.details?.map((item, i) => (<p key={i}><span>{item.label}: </span>{item.desc}</p>))}
                {/* <p><span>Banking: </span>First National Bank</p>
                <p><span>Location: </span>South Africa</p>
                <p><span>Leverage: </span>Maximum of 1:100</p> */}
            </div>
            {!isSending && <div className='w-100'>
            <button onClick={onClick}>Confirm</button>
            <button className={classes.changeButton} onClick={changeProvider}>Change</button>
            </div>}
            {isSending && (
                <div className="spinner-border" role="status">
                </div>
            )}

        </div>
    )
} 

export default YourCustody