import { Box, Center, Grid, HStack, VStack } from "@chakra-ui/react";
import { useMonth } from "@datepicker-react/hooks";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../contexts/page.context";
import { Text } from "../text";
import Day from "./Day";
const WEEK_LABELS = {
  Mo: "Mon",
  Tu: "Tue",
  We: "Wed",
  Th: "Thu",
  Fr: "Fri",
  Sa: "Sat",
  Su: "Sun",
};
function Month({ year, month }) {
  const { days, weekdayLabels } = useMonth({
    year,
    month,
    firstDayOfWeek: 1,
  });
  const { lightMode } = usePageContext();
  const [daysOfMonth, setDaysOfMonth] = useState(days);

  function getPreviousDay(date = new Date(), num) {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - num);

    return previous;
  }

  function getNextDay(date = new Date(), num) {
    const next = new Date(date.getTime());
    next.setDate(date.getDate() + num);

    return next;
  }

  const getNewDays = () => {
    let max = 0;
    days.map((x) => {
      if (parseInt(x.dayLabel) > max) {
        max = parseInt(x.dayLabel);
      }
    });
    var zeros = days.filter((x, i) => x === 0);
    var no_zeros = days.filter((x, i) => x !== 0);
    var new_days = days.map((x, i) => {
      if (x === 0) {
        var date = getPreviousDay(no_zeros[0].date, zeros.length);
        if (zeros.length > 0) zeros.pop();
        return {
          date,
          dayLabel:
            date.getDate() < 10
              ? "0" + date.getDate()
              : date.getDate().toString(),
          old_month: true,
        };
      } else return x;
    });

    return new_days;
  };

  useEffect(() => {
    // setDaysOfMonth(getNewDays())

    return () => {};
  }, []);
  return (
    <VStack w={"300px"} id="month">
      <Grid
        w={"100%"}
        pb={"6px"}
        gridTemplateColumns={"repeat(7, 1fr)"}
        borderBottom={`1px solid ${lightMode ? "#d0d0d0" : "#343C40"}`}
      >
        {weekdayLabels.map((dayLabel) => (
          <Center width={"31px"} borderRadius={"4px"}>
            <Text fontSize={"15px"} key={dayLabel}>
              {WEEK_LABELS[dayLabel]}
            </Text>
          </Center>
        ))}
      </Grid>
      <Grid
        flexWrap={"wrap"}
        width={"100%"}
        gridTemplateColumns={"repeat(7, 1fr)"}
        py={"6px"}
      >
        {daysOfMonth.map((day, index) => {
          if (typeof day === "object") {
            return (
              <Day
                days={daysOfMonth}
                date={day.date}
                old_month={day.old_month}
                index={index}
                key={day.date.toString()}
                dayLabel={day.dayLabel}
              />
            );
          }
          return <Box id="day" key={index}></Box>;
        })}
      </Grid>
    </VStack>
  );
}

export default Month;
