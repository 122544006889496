import React from 'react'

const DotMenuIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="15" viewBox="0 0 4 15" fill="none">
            <path d="M1.575 14.9414C1.35833 14.9414 1.15417 14.9039 0.9625 14.8289C0.770833 14.7456 0.604167 14.6372 0.4625 14.5039C0.320834 14.3622 0.208333 14.1956 0.125 14.0039C0.0416666 13.8122 0 13.6081 0 13.3914C0 13.1747 0.0416666 12.9706 0.125 12.7789C0.208333 12.5872 0.320834 12.4206 0.4625 12.2789C0.604167 12.1372 0.770833 12.0247 0.9625 11.9414C1.15417 11.8581 1.35833 11.8164 1.575 11.8164C1.8 11.8164 2.00833 11.8581 2.2 11.9414C2.38333 12.0247 2.54583 12.1372 2.6875 12.2789C2.82917 12.4206 2.9375 12.5872 3.0125 12.7789C3.09583 12.9706 3.1375 13.1747 3.1375 13.3914C3.1375 13.6081 3.09583 13.8122 3.0125 14.0039C2.9375 14.1956 2.82917 14.3622 2.6875 14.5039C2.54583 14.6372 2.38333 14.7456 2.2 14.8289C2.00833 14.9039 1.8 14.9414 1.575 14.9414Z" fill={color} />
            <path d="M1.575 9.0332C1.35833 9.0332 1.15417 8.9957 0.9625 8.9207C0.770833 8.83737 0.604167 8.72904 0.4625 8.5957C0.320834 8.45404 0.208333 8.28737 0.125 8.0957C0.0416666 7.90404 0 7.69987 0 7.4832C0 7.26654 0.0416666 7.06237 0.125 6.8707C0.208333 6.67904 0.320834 6.51237 0.4625 6.3707C0.604167 6.22904 0.770833 6.11654 0.9625 6.0332C1.15417 5.94987 1.35833 5.9082 1.575 5.9082C1.8 5.9082 2.00833 5.94987 2.2 6.0332C2.38333 6.11654 2.54583 6.22904 2.6875 6.3707C2.82917 6.51237 2.9375 6.67904 3.0125 6.8707C3.09583 7.06237 3.1375 7.26654 3.1375 7.4832C3.1375 7.69987 3.09583 7.90404 3.0125 8.0957C2.9375 8.28737 2.82917 8.45404 2.6875 8.5957C2.54583 8.72904 2.38333 8.83737 2.2 8.9207C2.00833 8.9957 1.8 9.0332 1.575 9.0332Z" fill={color} />
            <path d="M1.575 3.125C1.35833 3.125 1.15417 3.0875 0.9625 3.0125C0.770833 2.92917 0.604167 2.82083 0.4625 2.6875C0.320834 2.54583 0.208333 2.37917 0.125 2.1875C0.0416666 1.99583 0 1.79167 0 1.575C0 1.35833 0.0416666 1.15417 0.125 0.9625C0.208333 0.770833 0.320834 0.604166 0.4625 0.4625C0.604167 0.320833 0.770833 0.208332 0.9625 0.124999C1.15417 0.0416651 1.35833 -9.53674e-07 1.575 -9.53674e-07C1.8 -9.53674e-07 2.00833 0.0416651 2.2 0.124999C2.38333 0.208332 2.54583 0.320833 2.6875 0.4625C2.82917 0.604166 2.9375 0.770833 3.0125 0.9625C3.09583 1.15417 3.1375 1.35833 3.1375 1.575C3.1375 1.79167 3.09583 1.99583 3.0125 2.1875C2.9375 2.37917 2.82917 2.54583 2.6875 2.6875C2.54583 2.82083 2.38333 2.92917 2.2 3.0125C2.00833 3.0875 1.8 3.125 1.575 3.125Z" fill={color} />
        </svg>
    )
}

export default DotMenuIcon