import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classes from "./Category.module.css";
import HelpCenterBreadCrumbs from "./components/HelpCenterBreadCrumb";

const articlesAssetsData = [
  {
    title: "How to switch between different accounts",
    link: "switch",
  },
  {
    title: "How to view MT5 Login",
    link: "mt5login",
  },
  {
    title: "How to download the assets portfolio",
    link: "assets-portfolio",
  },
  {
    title: "How to see statistics",
    link: "statistics",
  },
  {
    title: "How to see monthly gains",
    link: "monthly-gains",
  },
  {
    title: "How to see the open and closed trades",
    link: "closed-trades",
  },
];

const articlesTransactionsData = [
  {
    title: "What methods can I use to add funds to my account?",
    link: "methods",
  },
  {
    title: "How to add funds via wire transfer",
    link: "wire",
  },
  {
    title: "How to add funds via crypto",
    link: "crypto",
  },
  {
    title: "What methods can I use to send funds from my account?",
    link: "send-funds",
  },
  {
    title: "How to send funds via wire transfer",
    link: "send-funds-wire",
  },
  {
    title: "How to send funds via crypto",
    link: "send-funds-crypto",
  },
  {
    title: "How will I know my service fee?",
    link: "service-fee",
  },
  {
    title: "How can I see service fee history?",
    link: "service-fee-history",
  },
];

const articlesTeamData = [
  {
    title: "How can I add a team member to my company?",
    link: "add-member",
  },
  {
    title: "How can I see the team members of my company?",
    link: "see-member",
  },
  {
    title: "How can I remove a team member?",
    link: "remove-member",
  },
  {
    title: "How can I see user access?",
    link: "see-user-access",
  },
  {
    title: "How can I edit user access?",
    link: "edit-user-access",
  },
  {
    title: "How does the team member accept the invite?",
    link: "accept-the-invite",
  },
];

const Category = () => {
  const params = useParams();
  const [showArticle, setShowArticle] = useState("question");
  const [article, setArticle] = useState("");
  const [searchText, setSearchText] = useState("")
  const [assetsArticles, setAssetsArticles] = useState([])
  const [transactionsArticles, setTransactionsArticles] = useState([])
  const [teamArticles, setTeamArticles] = useState([])
  const [themeMode, setThemeMode] = useState("light")
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    if(params.id === "assets"){
      setAssetsArticles(articlesAssetsData)
    }else if(params.id === "transactions"){
      setTransactionsArticles(articlesTransactionsData)
    }else if(params.id === "team"){
      setTeamArticles(articlesTeamData)
    }
    
    
  },[])

  useEffect(() => {
    setThemeMode(theme)
  }, [theme])


  const submitSearchHandler = () => {
    if(params.id === "assets"){
      var keyword = searchText.toLowerCase();
      var filteredArticles = assetsArticles.filter(function(article){

        article = article.title.toLowerCase();
          return article.indexOf(keyword) > -1; 
      });
      
      setAssetsArticles(filteredArticles);
    }else if(params.id === "transactions"){
      var keyword = searchText.toLowerCase();
      var filteredArticles = transactionsArticles.filter(function(article){

        article = article.title.toLowerCase();
          return article.indexOf(keyword) > -1; 
      });
      
      setTransactionsArticles(filteredArticles);
    }else if(params.id === "team"){
      var keyword = searchText.toLowerCase();
      var filteredArticles = teamArticles.filter(function(article){

        article = article.title.toLowerCase();
          return article.indexOf(keyword) > -1; 
      });
      
      setTeamArticles(filteredArticles);
    }
    
  }

  return (
    <div className={`${classes.wrapper} container`}>
      <HelpCenterBreadCrumbs
        mainText={
          params.id === "assets"
            ? "My Assets"
            : params.id === "transactions"
            ? "Transactions"
            : params.id === "team" && "Team"
        }
        search={true}
        activeLink="Categories"
        link="/dashboard/help/categories"
        root="/dashboard/help"
        rootText="Help Center"
        onClick={() => {
            setShowArticle("question");
        }}
        onChange={(e) => {setSearchText(e.target.value)}}
        submit={submitSearchHandler}
      />
      <div></div>

      {params.id === "assets" && showArticle === "question" && (
        <>
          <h6>Articles</h6>
          {assetsArticles.map((item, i) => (
            <div
              className={classes.articleTitle}
              key={i}
              onClick={() => {
                setShowArticle("article");
                setArticle(item.link);
              }}
              role="button"
            >
              {item.title}
            </div>
          ))}
        </>
      )}

      {params.id === "transactions" && showArticle === "question" && (
        <>
          <h6>Articles</h6>
          {transactionsArticles.map((item, i) => (
            <div
              className={classes.articleTitle}
              key={i}
              onClick={() => {
                setShowArticle("article");
                setArticle(item.link);
              }}
              role="button"
            >
              {item.title}
            </div>
          ))}
        </>
      )}
      {params.id === "team" && showArticle === "question" && (
        <>
          <h6>Articles</h6>
          {teamArticles.map((item, i) => (
            <div
              className={classes.articleTitle}
              key={i}
              onClick={() => {
                setShowArticle("article");
                setArticle(item.link);
              }}
              role="button"
            >
              {item.title}
            </div>
          ))}
        </>
      )}

      {article === "switch" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to switch between different accounts</h3>
          To switch from the different trading accounts you have under your
          Prime Bridge account, do the following:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" section.</li>
            <li>
              Find the box with your trading accounts and click it.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-1${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "400px" }}
                alt=""
              />
            </li>
            <li>Select the account you want to switch to.</li>
          </ol>
          The Assets page will automatically show stats from that given account.
        </div>
      )}

      {article === "mt5login" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to view MT5 Login</h3>
          To view your MT5 login details, follow these steps:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" section.</li>
            <li>
              Click "View MT5 Login"
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-2${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "200px" }}
                alt=""
              />
            </li>
          </ol>
        </div>
      )}

      {article === "assets-portfolio" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to download the assets portfolio</h3>
          To download your assets portfolio, follow these steps:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" section.</li>
            <li>
              Next to the MT5 Login button, you will click the "Fact Sheet"
              Button.
              <br />
              <img
                src=
                {`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-3${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "200px" }}
                alt=""
              />
            </li>
            <li>
              Click on it and the portfolio will automatically be downloaded.
            </li>
          </ol>
        </div>
      )}

      {article === "statistics" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to see statistics</h3>
          To see the statistics on the trading account, follow these steps:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" page.</li>
            <li>
              Then, click "Statistics" to see the section.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-4${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "600px" }}
                alt=""
              />
            </li>
          </ol>
        </div>
      )}

      {article === "monthly-gains" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to see monthly gains</h3>
          To see the monthly gains of the trading account, follow these steps:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" page.</li>
            <li>
              Then, click "Statistics" and go to the Monthly Gains section.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-5${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "600px" }}
                alt=""
              />
            </li>
          </ol>
        </div>
      )}

      {article === "closed-trades" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to see the open and closed trades</h3>
          To see the monthly gains of the trading account, follow these steps:
          <ol>
            <li>Log into your Prime Bridge account.</li>
            <li>Go to the "My Assets" page.</li>
            <li>
              Then, click "Trading" to see open and closed trades.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-6${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "600px" }}
                alt=""
              />
            </li>
          </ol>
        </div>
      )}

      {article === "methods" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>What methods can I use to add funds to my account?</h3>
          To add funds to your liquidity account, you can do that via wire
          transfer or crypto.
        </div>
      )}

      {article === "wire" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to add funds via wire transfer</h3>
          To add funds to your liquidity accounts via Wire Tranfer,
          <ol>
            <li>
              Go to the Funds page, under Transactions.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-7${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Select Wire Transfer
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-8${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Choose the currency (USD or EUR) and specify the amount you wish
              to transfer.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-9${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "400px" }}
                alt=""
              />
            </li>
            <li>You will see the total amount to be transferred.</li>
            <li>Click Continue</li>
            <li>
              You will see the banking details. Proceed to your banking
              application to make the transfer.
            </li>
            <li>Once done, click Submit</li>
            Allow 3 to 5 business days for the funds to appear in your account.
          </ol>
        </div>
      )}

      {article === "crypto" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to add funds via crypto</h3>
          To add funds to your liquidity accounts via Crypto,,
          <ol>
            <li>
              Go to the Funds page, under Transactions.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-10${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Select Crypto<br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-11${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Choose the coin (BTC, ETH, USDT) and specify the amount you wish
              to transfer in USD.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-12${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "400px" }}
                alt=""
              />
            </li>
            <li>You will see the total amount to be transferred.</li>
            <li>Click Confirm</li>
            <li>You will see the crypto wallet address.</li>
            <li>Once done with the crypto deposit, click Confirm</li>
            Allow 3 to 5 business days for the funds to appear in your account.
          </ol>
        </div>
      )}

      {article === "send-funds" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>What methods can I use to send funds from my account?</h3>
          To send funds outside Prime Bridge, you can do that via wire transfer
          or crypto.
        </div>
      )}

      {article === "send-funds-wire" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to add funds via wire transfer</h3>
          To send funds outside Prime Bridge via Wire Transfer,
          <ol>
            <li>
              Go to the Funds page, under Transactions.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-13${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Select Wire Transfer
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-14${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Choose the currency (USD or EUR) and specify the amount you wish
              to have transferred.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-15${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Then, insert your baking information.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-16${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "400px" }}
                alt=""
              />
            </li>
            <li>
              Once you've been entered all the information, click Get Code,
              inside the Security Verification field.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-17${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "250px" }}
                alt=""
              />
            </li>
            <li>
              The 6-digit code will be sent to the admin email. Once you get the
              code, insert it in the field.
            </li>
            <li>Then click Confirm</li>
            <li>
              You will see the banking details. Proceed to your banking
              application to make the transfer.
            </li>
            <li>Once done, click Submit</li>
            Allow 3 to 5 business days for the funds to be sent. From this
            point, you will receive email updates.
          </ol>
        </div>
      )}

      {article === "send-funds-crypto" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How to add funds via crypto</h3>
          To send funds outside Prime Bridge via crypto,
          <ol>
            <li>
              Go to the Funds page, under Transactions.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-18${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Select Crypto
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-19${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "300px" }}
                alt=""
              />
            </li>
            <li>
              Choose the coin (BTC, ETH, USDT) and specify the amount you wish
              to send in USD.
            </li>
            <li>Then, insert the crypto wallet address.</li>
            <li>
              Once you've been entered all the information, click Get Code,
              inside the Security Verification field.
              <br />
              <img
                src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-20${themeMode === 'dark' ? '-dark' : ''}.png`}
                style={{ width: "250px" }}
                alt=""
              />
            </li>
            <li>
              The 6-digit code will be sent to the admin email. Once you get the
              code, insert it in the field.
            </li>
            <li>Then click Confirm</li>
            Allow 3 to 5 business days for the funds to be sent. From this
            point, you will receive email updates.
          </ol>
        </div>
      )}

      {article === "service-fee" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How will I know my service fee?</h3>
          After you've requested a demo, your account manager will reach out to
          schedule a call. That will be the moment when you will become aware of
          the service fee that will apply to your account, as the plan will be
          tailored-made for your needs.
        </div>
      )}

      {article === "service-fee-history" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I see service fee history?</h3>
          To see the Service Fee history,
          <ol>
          <li>
            Go to the Service Fees page, under Transactions.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-21${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "300px" }}
              alt=""
            />
          </li>
          <li>
            There, you will see the date, amount and status of your service fees
            history.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-22${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          </ol>
        </div>
      )}

      {article === "add-member" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I add a team member to my company?</h3>
          To add a team member to your company on Prime Bridge,
          <ol>
          <li>
            Go to the Team section.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-23${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            Click Add Member
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-24${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            Insert the login information for the team member (first and last
            name, email address, and user access).
          </li>
          <li>
            Once done, click Add<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-25${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          </ol>
          The team member will receive an email with instructions to join Prime
          Bridge.
        </div>
      )}

      {article === "see-member" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I see the team members of my company?</h3>
          To see the team members of your company on Prime Bridge,
          <ol>
          <li>
            Go to the Team section.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-26${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            See list of members in your team.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-27${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          </ol>
        </div>
      )}

      {article === "remove-member" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I remove a team member?</h3>
          To remove a team member from your company on Prime Bridge,
          <ol>
          <li>
            Go to the Team section.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-28${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            See list of members in your team.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-29${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          <li>
            Click Edit, next to the user you want to remove.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-30${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "150px" }}
              alt=""
            />
          </li>
          <li>
            Then, click Delete<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-31${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "150px" }}
              alt=""
            />
          </li>
          </ol>
        </div>
      )}

      {article === "see-user-access" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I see user access?</h3>
          To see the user access on Prime Bridge,
          <ol>
          <li>
            Go to the Team section.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-32${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            See list of members in your team.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-33${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          <li>
            In the access column, you will see the sections they can access.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-34${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "150px" }}
              alt=""
            />
          </li>
          </ol>
        </div>
      )}

      {article === "edit-user-access" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How can I edit user access?</h3>
          To edit the user access on Prime Bridge,
          <ol>
          <li>
            Go to the Team section.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-35${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "200px" }}
              alt=""
            />
          </li>
          <li>
            See list of members in your team.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-36${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          <li>
            Click Edit, next to the user you want to change access.
            <br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-37${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "150px" }}
              alt=""
            />
          </li>
          <li>
            Update the access you want to grant.<br />
            <img
              src={`https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/article-image-38${themeMode === 'dark' ? '-dark' : ''}.png`}
              style={{ width: "400px" }}
              alt=""
            />
          </li>
          </ol>
          <li>Once done, click Save</li>
        </div>
      )}

      {article === "accept-the-invite" && showArticle === "article" && (
        <div
          className={classes.articleWrapper}
        >
          <h3>How does the team member accept the invite?</h3>
          Once you add a team member to your team, they will receive an email
          with the instructions. They should join Prime Bridge with the email
          address provided by the admin and they will automatically be under the
          company.
        </div>
      )}
    </div>
  );
};

export default Category;
