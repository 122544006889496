import React from 'react'

const SuccessGreyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <g clipPath="url(#clip0_294_691)">
                <path d="M13 0C5.83158 0 0 5.83158 0 13C0 20.1684 5.83158 26 13 26C20.1684 26 26 20.1684 26 13C26 5.83158 20.1684 0 13 0ZM19.7167 11.388L14.9218 16.0951C14.0736 16.9271 12.9816 17.342 11.8885 17.342C10.8073 17.342 9.72617 16.9347 8.88117 16.1189L6.82392 14.0963C6.39708 13.6771 6.39167 12.9913 6.81092 12.5645C7.22908 12.1366 7.917 12.1312 8.34275 12.5515L10.3935 14.5676C11.2342 15.3801 12.5613 15.3758 13.4063 14.5481L18.2 9.84208C18.6257 9.42175 19.3093 9.42933 19.7318 9.85617C20.1511 10.283 20.1446 10.9688 19.7167 11.388Z" fill="#D0D0D0" />
            </g>
            <defs>
                <clipPath id="clip0_294_691">
                    <rect width="26" height="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default SuccessGreyIcon