import React from 'react'
import { getCookie } from '../../../../../utilities/functions';
import PBAPIClient from '../../../../../services/prime-client'
import { usePageContext } from "../../../contexts/page.context";
import useSendFundsStore from '../store';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { DestOptions } from '../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InfoIcon from '../../../components/Info.icon';
import SuccessIcon from '../../../icons/Success.icon';

const useSaveAddress = () => {
    const { lightMode } = usePageContext()
    const companyId = getCookie("companyId");
    const authToken = getCookie("authToken");
    const userId = getCookie("userId");
    const queryClient = useQueryClient();
    const apiClient = new PBAPIClient('/transaction/save-address', authToken)
    const destination = useSendFundsStore(s => s.firstDetails.destination)
    const bankName = useSendFundsStore(s => s.bankDetails.bankName)
    const accountNumber = useSendFundsStore(s => s.bankDetails.accountNumber)
    const accountName = useSendFundsStore(s => s.bankDetails.accountName)
    const address = useSendFundsStore(s => s.bankDetails.address)
    const sortCode = useSendFundsStore(s => s.bankDetails.sortCode)
    const swift = useSendFundsStore(s => s.bankDetails.swift)
    const coin = useSendFundsStore(s => s.cryptoDetails.coin)
    const walletAddress = useSendFundsStore(s => s.cryptoDetails.walletAddress)

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")
            // toast.error(error.response?.status === 500 ?
            //     "Network Error" : error.response?.data, {
            //     position: "top-right",
            //     style: {
            //         fontSize: "14px",
            //         color: lightMode ? "black" : "white",
            //         borderRadius: "4px",
            //         backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
            //     }
            // })
        } else
            throw error
    }

    return useMutation({
        mutationFn: () => apiClient.post({
            name: destination === DestOptions[1] ? coin : `${bankName} - ${accountNumber}`,
            company: companyId,
            user: userId,
            sendType: destination === DestOptions[1] ? "crypto" : "wire-transfer",
            cryptoAddress: walletAddress,
            wireBankName: destination === DestOptions[1] ? coin : bankName,
            wireAccountName: accountName,
            wireAccountNumber: accountNumber,
            wireAddress: destination === DestOptions[1] ? walletAddress : address,
            wireBranch: sortCode,
            wireSwift: swift
        }),
        onMutate() {
            // toast.loading("Sending request.", {
            //     icon: <InfoIcon />,
            //     id: "loading",
            //     duration: 1000,
            //     position: "top-right",
            //     style: {
            //         fontSize: "14px",
            //         color: lightMode ? "black" : "white",
            //         borderRadius: "4px",
            //         backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
            //     }
            // })
        },
        onSuccess() {
            queryClient.refetchQueries("saved addresses", destination === DestOptions[1] ? "crypto" : "wire-transfer")
        },
        onError(error) {
            showError(error)
        }
    })
}

export default useSaveAddress