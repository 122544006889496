import React from "react";
import { usePageContext } from "../contexts/page.context";

const TransferIcon = () => {
  const { currentTheme } = usePageContext();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="26"
      viewBox="0 0 38 26"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.7901 0C27.2191 0 26.6695 0.219599 26.2626 0.613651C25.8554 1.00805 25.6246 1.54523 25.6246 2.10765C25.6246 2.67007 25.8559 3.20775 26.2631 3.60215L29.8916 7.10115H9.47911C8.91008 7.10115 8.36233 7.31998 7.95688 7.71267C7.55107 8.10571 7.32108 8.64105 7.32108 9.20154C7.32108 9.76204 7.55107 10.2974 7.95688 10.6904C8.36233 11.0831 8.91008 11.3019 9.47911 11.3019H35.1145L35.1161 11.3019C35.5399 11.2999 35.9544 11.1764 36.3074 10.946C36.6602 10.7158 36.9364 10.3885 37.0996 10.0043C37.2657 9.62088 37.3113 9.19779 37.2299 8.78872C37.1484 8.37923 36.9438 8.00374 36.6436 7.70906L29.3176 0.613651C29.1161 0.418457 28.8774 0.264152 28.6154 0.159056C28.3535 0.0539668 28.0731 0 27.7901 0ZM2.1547 14.195L2.15312 14.195C1.72935 14.1971 1.31486 14.3205 0.961838 14.551C0.60909 14.7812 0.332913 15.1084 0.169655 15.4926C0.00349092 15.876 -0.0420303 16.2992 0.0393497 16.7083C0.120806 17.1177 0.325399 17.4932 0.625615 17.7879L0.627178 17.7894L7.95132 24.8831C8.15226 25.0791 8.39067 25.2342 8.65258 25.3399C8.91478 25.4457 9.19566 25.5 9.47911 25.5C9.76256 25.5 10.0434 25.4457 10.3056 25.3399C10.5674 25.2342 10.8058 25.0792 11.0066 24.8833C11.2089 24.6888 11.3701 24.4568 11.4803 24.2005C11.5907 23.9438 11.6478 23.6681 11.6478 23.3893C11.6478 23.1106 11.5907 22.8348 11.4803 22.5781C11.37 22.3216 11.2082 22.0891 11.0057 21.8944L7.37765 18.3958H27.7901C28.3592 18.3958 28.9069 18.177 29.3124 17.7843C29.7182 17.3913 29.9481 16.8559 29.9481 16.2954C29.9481 15.7349 29.7182 15.1996 29.3124 14.8066C28.9069 14.4139 28.3592 14.195 27.7901 14.195L2.1547 14.195ZM11.0066 24.8833L11.0076 24.8824L10.7792 24.6485L11.0057 24.8843L11.0066 24.8833Z"
        fill={currentTheme === "light" ? "#0077B5" : "#0077B5"}
      />
    </svg>
  );
};

export default TransferIcon;
