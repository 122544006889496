
import { useState } from 'react'
import CheckMarkIcon from '../../icons/CheckMarkIcon'
import classes from './CustodyProvider.module.css'

const providersData = [
    {
        provider: "Prime Custody",
        title: "Prime Custody regulated by the FSCA",
        details: [{label: "Works with", desc:"Retail/Funds and UHNW"}, {label:"Banking", desc:"First National Bank"}, {label: "Location" , desc: "South Africa"}, {label: "Leverage", desc: "Maximum of 1:100"}],
        footNote: "",
        index: 0
    },
    {
        provider: "CXM Custody",
        title: "CXM Prime regulated by the FCA",
        details: [{label: "Works with", desc: "Funds and UHNW"}, {label: "Banking" , desc: "Barclays London"}, {label: "Location", desc: "United Kingdom"}, {label: "Leverage", desc: "1:25, but it can go up to 1:50 on performance review"}],
        footNote: "Further compliance checks will be requested by the FCA",
        index: 1
    }
]

const CustodyProviders = ({onClick, selectedProvider, currectProvider}) => {

    const [ifChecked, setIfChecked] = useState()
    const [selectedData, setSelectedData] = useState()

    console.log("WHAT IS THERE", currectProvider)

    useState(() => {
        if(currectProvider !== undefined){
            setIfChecked(currectProvider.id)
        }
    },[])
    
    return(
        <div className={`${classes.wrapper} d-flex flex-column justify-content-between align-items-center gap-4 w-100`}>
            
            <h3>Custody Providers</h3>
            {providersData.map((provider, i) => (<div className={`${classes.selectorBox} ${ifChecked === provider.index && classes.activeBox} d-flex flex-column justify-content-center`} key={i}  onClick={() => {setIfChecked(provider.index); setSelectedData(provider)}} role='button'>
                <div className='d-flex justify-content-between align-items-center'><h4>{provider.title}</h4><div className={`${classes.circleCheckmark} ${ifChecked === provider.index && classes.circleChecked} d-flex justify-content-between align-items-center`}>{ifChecked === provider.index && <CheckMarkIcon />}</div></div>
                {provider.details.map((item, index) => (<p key={index}>{`${item.label}: ${item.desc}`}</p>))}
                {provider.footNote && <p className={classes.footNote}>{provider.footNote}</p>}
                
            </div>))}
            
            <button onClick={() => {onClick(); selectedProvider(selectedData)}} className={ifChecked === undefined  ? classes.disabledButton : undefined} disabled={ifChecked === undefined}>Continue</button>
        </div>
    )
} 

export default CustodyProviders