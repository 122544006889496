import classes from "./LinkContainer.module.css"

const LinkContainer = ({title, children, activeTab, onClick, newNotificatiion}) =>{
    return(
        <div className={`${classes.wrapper} ${activeTab ? classes.active : undefined} d-flex align-items-center w-100`} role="button" onClick={onClick}>
            
            {children}
            <h4>{title}</h4>
            {/* <div className="col">
            </div> */}
            {(newNotificatiion && !activeTab) &&  <div className={classes.blueDot}></div>}
        </div>
    )
}

export default LinkContainer