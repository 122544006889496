import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    allNotifications: [],
  },
  reducers: {

    getNotifications(state, action) {
      state.allNotifications = action.payload.allNotifications
    },
  }
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
