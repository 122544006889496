import { useEffect, useState } from "react";
import SetupButton from "../UI/SetupButton";
import UploadContainer from "../UI/UploadContainer";
import classes from "./PersonalDocumentsCard.module.css";

const PersonalDocumentsCard = ({
  onClick,
  getPersonalDocuments,
  isSending,
  backToPrev,
  show
}) => {
  const [directorIdentification, setDirectorIdentification] = useState([]);
  const [directorAddress, setDirectorAddress] = useState([]);
  useEffect(() => {
    getPersonalDocuments([directorIdentification[0], directorAddress[0]]);
  }, [directorIdentification, directorAddress]);

  return (
    <div style={{display: !show && "none"}} className="w-100">
      <div className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`}>
      <h2 className={classes.titleText}>
        Upload your personal
        <br /> verification documents
      </h2>
      <h6 className={classes.subText}>
        Regulations require us to collect documents to verify your identity.{" "}
      </h6>
      <UploadContainer
        title="Director Identification"
        fileSelected={(file) => setDirectorIdentification(file)}
        multipleFiles={true}
      />
      <UploadContainer
        title="Director Address Verification"
        fileSelected={(file) => setDirectorAddress(file)}
      />
      {!isSending && (
        <SetupButton
          text="Continue"
          onClick={onClick}
          disabled={
            directorIdentification.length === 0 || directorAddress.length === 0
          }
        />
      )}
      {isSending && (
        <div className="spinner-border" role="status">
          {/* <span class="sr-only">Loading...</span> */}
          
        </div>
      )}
      <div
            className={classes.backButton}
            role="button"
            onClick={backToPrev}
          >
            Back
          </div>
    </div>
    </div>
  );
};

export default PersonalDocumentsCard;
