import { useEffect, useState } from "react";
import { env } from "../../constants/env";
import DownloadIcon from "../../icons/DownloadIcon";
import { getCookie } from "../../utilities/functions";
import classes from "./Summaries.module.css"
import { useSelector } from "react-redux";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import FileDownload from "js-file-download";
import axios from "axios";
import { Helmet } from "react-helmet";
import financialSummaryTemplate from "../../template/financial_summary_template.xlsx"

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
} 

const tableData = [
    {
        dates: "Jun 1, 2023 - Jun 30, 2023",
        deliveryType: "Scheduled",
    },
    {
      dates: "May 1, 2023 - May 31, 2023",
      deliveryType: "Scheduled",
    },
    {
      dates: "Apr 1, 2023 - Apr 30, 2023",
      deliveryType: "Scheduled",
    },
    {
      date: "Mar 1, 2023 - Mar 31, 2023",
      deliveryType: "Scheduled",
    },
    {
      date: "Feb 1, 2023 - Feb 28, 2023",
      deliveryType: "Scheduled",
    },
    {
      date: "Jan 1, 2023 - Jan 31, 2023",
      deliveryType: "Scheduled",
    }
]


const MemberHeader = () => {
    return (
      <>
        <tr style={{color: "#0077B5"}}>
          <th>Date Range</th>
          <th>Delivery Type</th>
          <th>PDF</th>
          <th>CSV</th>
        </tr>
      </>
    );
  };


const MemberRow = ({
    item,
    themeMode
  }) => {

    const companyName = useSelector(state => state.company.companyName)
    const companyDirector = useSelector(state => state.company.companyDirector)
    const [isSending, setIsSending] = useState(false)
    const [fileKey, setFileKey] = useState()


    const dateToYMD = date => {
      const formatedDate = date.split('T')[0]
      console.log("DATE", formatedDate)
      var newDate = new Date(formatedDate)
      var today = new Date()
      var strArray = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      var d = newDate.getDate()
      var dayName = days[newDate.getDay()]
      var m = strArray[newDate.getMonth()]
      var y = newDate.getFullYear()
  
      
      return {
        dashDate: '' + m + ' ' + (d <= 9 ? '0' + d : d) + ', ' + y,
        invoiceDate: formatedDate
      }
    }

    const capitalizeFirstLetter = (word) => { 
      return word[0].toUpperCase() + word.substring(1); 
    }


    const deleteFile = async (key) => {
      let dataContent = {key}
      const sendRequest = async () => {
        const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf/delete-file`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent)
        });
  
        if (!response.ok) {
          const result = await response.json();
  
        console.log("Error", result);
          throw new Error("sending plays failed.");
        }
  
        const result = await response.json();
  
        console.log("Reply from plays data", result);
  
        return result;
      };
  
      try {
        await sendRequest();
      } catch (error) {
        console.log(error);
      }
    };
  
  
    const handleDownload = (url, filename) => {
      axios.get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        FileDownload(res.data, filename)
      }).then(() => {
        deleteFile(fileKey)
      })
    }
  
  
  
  
  
    const  uploadDocumentHandler = async (file) => {
      const sendRequest = async () => {
        setIsSending(true)
        console.log("REACHED HERE")
        const formData = new FormData()
        formData.append('files', file)
    
        const response = await fetch(`${env.primeBridgeUrl}/api/docxtopdf`, { 
          method: 'POST',
          body: formData
        });
  
        if (!response.ok) {
          const result = await response.json();
          setIsSending(false)
          console.log(result.error)
          throw new Error("sending plays failed.");
        }
  
        const result = await response.json();
  
        setIsSending(false)
  
        console.log("File", result.file);
        handleDownload(result.file, 'prime-bridge_invoice.pdf');
        return result;
      };
  
      try {
        const data = await sendRequest();
        // deleteFile(data.key)
        setFileKey(data.key)
      } catch (error) {
        setIsSending(false)
        console.log(error);
      }
    };
  
    

    

    const generateDocument = () => {
      loadFile(
        financialSummaryTemplate,
        function (error, content) {
          if (error) {
            throw error;
          }
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          doc.setData({
            company_name: companyName,
            invoice_date: dateToYMD(item?.date).invoiceDate,
            net_profit: -10,
            pb_profit_share: 20,
            internal_withdrawal: -10,
            fiat_deposit: 5,
            margin_deposit: 20,
            profit_withdrawal: -1234,
            fiat_deposit_amount: 50000,
            internal_withdrawal_amount: -3000,
            service_fee: -1000,
            alloaction_fee: -4000,
            margin_deposit: 50000
          });
          try {
            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render();
          } catch (error) {
            // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
            function replaceErrors(key, value) {
              if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function (
                  error,
                  key
                ) {
                  error[key] = value[key];
                  return error;
                },
                {});
              }
              return value;
            }
            console.log(JSON.stringify({ error: error }, replaceErrors));
  
            if (error.properties && error.properties.errors instanceof Array) {
              const errorMessages = error.properties.errors
                .map(function (error) {
                  return error.properties.explanation;
                })
                .join('\n');
              console.log('errorMessages', errorMessages);
              // errorMessages is a humanly readable message looking like this :
              // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
          }
          var out = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          }); //Output the document using Data-URI

          // uploadDocumentHandler(out)
          saveAs(out, 'financial_summary.xlsx');
        }
      );
    };

    return (
      <tr>
        <td>
            <div className="d-flex align-items-center">
          {item?.dates}
          </div>
        </td>
        <td><span className={classes.deliveryTypeText}>Scheduled</span></td>
        <td><div className={classes.requestText} role="button">Request</div></td>
        <td><div role="button" onClick={generateDocument} className={classes.requestText}>  Download</div></td>
      </tr>
    );
  };

const Summaries = ({themeMode}) => {

  const [feesList, setFeesList] = useState([])
  const companyId = getCookie("companyId");

  useEffect(() => {
    getAllInvoices()
  },[])

  const getAllInvoices = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/service-fee/company/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Invoices data", result);
      setFeesList(result)
      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

    return(
        <div className={`${classes.wrapper} container`}>
          <Helmet>
              <meta charSet="utf-8" />
              <title>Summaries | Prime Bridge Portal</title>
              <link rel="canonical" href="https://portal.primebridge.io/dashboard/transactions/services-fees" />
          </Helmet>
          <div className={classes.headingText}>
            <h3>Monthly Financial Summaries</h3>
            <p>Monthly consolidated view of withdrawals, deposits and fees in your account</p>
          </div>
            <div className={`${classes.topSection} d-flex justify-content-between align-items-center mb-4`}>
                <h3>History</h3>
            </div>
            <div className={classes.tableDiv}>
        <table>
                <MemberHeader />
                {tableData.map((item, i) => (
                    <MemberRow item={item} themeMode={themeMode} key={i}/>
                ))}
                </table>

            </div>
        </div>
    )
}

export default Summaries