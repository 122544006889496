import { createSlice } from '@reduxjs/toolkit';

const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    allAccounts: [],
    allOverviewAccounts: [],
    allManagementAccounts: [],
    lpGroups: []
  },
  reducers: {

    getAccounts (state, action) {
      state.allAccounts = action.payload.allAccounts
    },
    getOverviewAccounts (state, action) {
      state.allOverviewAccounts = action.payload.allOverviewAccounts
    },
    getManagementAccounts (state, action) {
      state.allManagementAccounts = action.payload.allManagementAccounts
    },
    getLPGroups (state, action) {
      state.lpGroups = action.payload.lpGroups
    },
  }
});

export const accountsActions = accountsSlice.actions;

export default accountsSlice;
