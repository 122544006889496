import React from 'react'
import BankDetails from './bank/BankDetails'
import BankForm from './bank/BankForm'
import CashDetails from './cash/CashDetails'
import CryptoDetails from './crypto/CryptoDetails'
import CryptoInput from './crypto/CryptoInput'
import useSendFundsStore from '../store'

const MethodDetails = () => {
    const page = useSendFundsStore(s => s.page)
    const isBankMethod = useSendFundsStore(s => s.isBankMethod)
    const isCryptoMethod = useSendFundsStore(s => s.isCryptoMethod)
    const isCashMethod = useSendFundsStore(s => s.isCashMethod)
    return (
        <>
            {(isBankMethod() && page === 2) && <BankForm />}
            {(isBankMethod() && page === 3) && <BankDetails />}
            {(isCryptoMethod() && page === 2) && <CryptoInput />}
            {(isCryptoMethod() && page === 3) && <CryptoDetails />}
            {isCashMethod() && <CashDetails />}
        </>
    )
}

export default MethodDetails