import classes from "./SetupButton.module.css";

const SetupButton = ({ text, onClick, disabled }) => {
  
  return (
    <button
      className={`${classes.acceptButton} ${disabled ? classes.disabledButton : undefined}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      
    </button>
  );
};

export default SetupButton;
