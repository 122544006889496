import { createContext, useContext, useState } from 'react'

export const exampleSavedCrypto = [
    {
        name: "",
        coin: "BTC",
        cryptoAddress: "1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71"
    },
    {
        name: "",
        coin: "USDT",
        cryptoAddress: "1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71"
    },
    {
        name: "",
        coin: "USDT",
        cryptoAddress: "1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71"
    },
]

export const exampleSavedAddresses = [
    {
        bankName: "TD Bank",
        accountName: "Trading Solutions Pro",
        accountNumber: "1234500",
        address: "700 W Georgia St, Vancouver, BC V7Y 1A2, Canada",
        sortCode: "101",
        swift: "20982093i2"
    },
    {
        bankName: "Scotiabank",
        accountName: "Scotiabank Pro",
        accountNumber: "4509849",
        address: "700 W Georgia St, Vancouver, BC V7Y 1A2, Canada",
        sortCode: "101",
        swift: "20982093i2"
    },
    {
        bankName: "Bank ofAmerica",
        accountName: "Scotiabank Pro",
        accountNumber: "3847892",
        address: "700 W Georgia St, Vancouver, BC V7Y 1A2, Canada",
        sortCode: "101",
        swift: "20982093i2"
    }
]

const SendfundsContext = createContext({
    page: 0,
    setPage: (p) => { },
    pageNumber: 0,
    setPageNumber: (p) => { },
    source: "",
    setSource: (s) => { },
    destination: "",
    setDestination: (s) => { },
    amount: "",
    setAmount: (s) => { },
    code: "",
    setCode: (c) => { },
    isBankMethod: false,
    isCryptoMethod: false,
    isCashMethod: false,
    bankName: "",
    accountName: "",
    accountNumber: "",
    address: "",
    sortCode: "",
    swift: "",
    cryptoAddress: "",
    coin: "",
    showBankSaved: false,
    showCryptoSaved: false,
    savedCrypto: exampleSavedCrypto,
    savedAddresses: exampleSavedAddresses,
    confirmSavedBank: (i) => { },
    confirmSavedCrypto: (i) => { },
    setSavedCrypto: (s) => { },
    setSavedAddresses: (s) => { },
    setShowBankSaved: (s) => { },
    setShowCryptoSaved: (s) => { },
    setCryptoAddress: (s) => { },
    setBankName: (s) => { },
    setAccountName: (s) => { },
    setAccountNumber: (s) => { },
    setAddress: (s) => { },
    setSortCode: (s) => { },
    setSwift: (s) => { },
    setCoin: (s) => { }
})

export const exampleAccs = [
    "ABC Trading - 123456",
    "XYZ Trading - 098765",
    "Cash Account"
]

export const Coins = [
    "BTC",
    "ETH",
    "USDT",
    "USDC"
]

export const exampleDests = [
    "External Bank Account",
    "Crypto Wallet",
    "Cash Account"
]



const SendfundsContextProvider = ({ children }) => {
    const [page, setPage] = useState(0)
    const [savedCrypto, setSavedCrypto] = useState(exampleSavedCrypto)
    const [savedAddresses, setSavedAddresses] = useState(exampleSavedAddresses)
    const [showBankSaved, setShowBankSaved] = useState(false)
    const [showCryptoSaved, setShowCryptoSaved] = useState(false)
    const [pageNumber, setPageNumber] = useState(0)
    const [source, setSource] = useState("")
    const [amount, setAmount] = useState("")
    const [code, setCode] = useState("")
    const [destination, setDestination] = useState("")
    const [bankName, setBankName] = useState("")
    const [accountName, setAccountName] = useState("")
    const [accountNumber, setAccountNumber] = useState("")
    const [address, setAddress] = useState("")
    const [sortCode, setSortCode] = useState("")
    const [swift, setSwift] = useState("")
    const [coin, setCoin] = useState("")
    const [cryptoAddress, setCryptoAddress] = useState("")

    const isBankMethod = destination === exampleDests[0]
    const isCryptoMethod = destination === exampleDests[1]
    const isCashMethod = destination === exampleDests[2]

    const updateSavedCrypto = () => {

    }

    const confirmSavedBank = (i) => {
        setBankName(savedAddresses[i]?.bankName)
        setAccountName(savedAddresses[i]?.accountName)
        setAddress(savedAddresses[i]?.address)
        setAccountNumber(savedAddresses[i]?.accountNumber)
        setSortCode(savedAddresses[i]?.sortCode)
        setSwift(savedAddresses[i]?.swift)
    }

    const confirmSavedCrypto = (i) => {
        setCoin(savedCrypto[i]?.coin)
        setCryptoAddress(savedCrypto[i]?.cryptoAddress)
    }

    return (
        <SendfundsContext.Provider value={{
            confirmSavedBank, confirmSavedCrypto,
            savedCrypto, setSavedCrypto,
            savedAddresses, setSavedAddresses,
            cryptoAddress, setCryptoAddress,
            showBankSaved, setShowBankSaved,
            showCryptoSaved, setShowCryptoSaved,
            coin, setCoin,
            bankName,
            setBankName,
            accountName,
            setAccountName,
            accountNumber,
            setAccountNumber,
            address,
            setAddress,
            sortCode,
            setSortCode,
            swift,
            setSwift,
            page,
            setPage,
            amount,
            setAmount,
            pageNumber,
            setPageNumber,
            source,
            setSource,
            destination,
            setDestination,
            code,
            setCode,
            isBankMethod,
            isCryptoMethod,
            isCashMethod,
        }}>
            {children}
        </SendfundsContext.Provider>
    )
}

export const SendfundsContextCustomer = ({ children }) => {
    return (
        <SendfundsContext.Consumer>
            {children}
        </SendfundsContext.Consumer>
    )
}

export const useSendfundsContext = () => {
    const context = useContext(SendfundsContext)
    if (context === undefined) {
        throw new Error('useSendfundsContext must be used within a SendfundsContextProvider')
    }
    return context
}

export default SendfundsContextProvider