const BankIcon = () =>{
    return(
        <svg width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 37.6C2.3402 37.6 1.4 36.6598 1.4 35.5C1.4 34.3402 2.3402 33.4 3.5 33.4L30.5 33.4C31.6598 33.4 32.6 34.3402 32.6 35.5C32.6 36.6598 31.6598 37.6 30.5 37.6L3.5 37.6Z" stroke="#0077B5" stroke-width="2.8"/>
        <rect x="5.4" y="16.4" width="4.2" height="13.2" rx="2.1" stroke="#0077B5" stroke-width="2.8"/>
        <rect x="14.4" y="16.4" width="4.2" height="13.2" rx="2.1" stroke="#0077B5" stroke-width="2.8"/>
        <rect x="23.4" y="16.4" width="4.2" height="13.2" rx="2.1" stroke="#0077B5" stroke-width="2.8"/>
        <mask id="path-5-inside-1_56_218" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.133 0.351914C17.4505 -0.117307 16.5494 -0.117308 15.8669 0.351913L6.30627 6.92485C6.27081 6.94923 6.23651 6.9743 6.20334 7H3.5C1.567 7 0 8.567 0 10.5C0 12.433 1.567 14 3.5 14H30.5C32.433 14 34 12.433 34 10.5C34 8.567 32.433 7 30.5 7H27.7966C27.7634 6.9743 27.7291 6.94923 27.6937 6.92485L18.133 0.351914Z"/>
        </mask>
        <path d="M15.8669 0.351913L17.4532 2.65923L17.4532 2.65923L15.8669 0.351913ZM18.133 0.351914L19.7193 -1.9554L19.7193 -1.9554L18.133 0.351914ZM6.30627 6.92485L7.89255 9.23217L6.30627 6.92485ZM6.20334 7V9.8H7.16137L7.9186 9.21312L6.20334 7ZM27.7966 7L26.0814 9.21313L26.8386 9.8H27.7966V7ZM27.6937 6.92485L26.1074 9.23217L27.6937 6.92485ZM17.4532 2.65923C17.1802 2.84692 16.8197 2.84692 16.5467 2.65923L19.7193 -1.9554C18.0813 -3.08153 15.9186 -3.08154 14.2806 -1.9554L17.4532 2.65923ZM7.89255 9.23217L17.4532 2.65923L14.2806 -1.9554L4.71999 4.61754L7.89255 9.23217ZM7.9186 9.21312C7.90934 9.2203 7.90062 9.22663 7.89255 9.23217L4.71999 4.61754C4.64101 4.67184 4.56368 4.7283 4.48808 4.78688L7.9186 9.21312ZM3.5 9.8H6.20334V4.2H3.5V9.8ZM2.8 10.5C2.8 10.1134 3.1134 9.8 3.5 9.8V4.2C0.0206063 4.2 -2.8 7.02061 -2.8 10.5H2.8ZM3.5 11.2C3.1134 11.2 2.8 10.8866 2.8 10.5H-2.8C-2.8 13.9794 0.0206049 16.8 3.5 16.8V11.2ZM30.5 11.2H3.5V16.8H30.5V11.2ZM31.2 10.5C31.2 10.8866 30.8866 11.2 30.5 11.2V16.8C33.9794 16.8 36.8 13.9794 36.8 10.5H31.2ZM30.5 9.8C30.8866 9.8 31.2 10.1134 31.2 10.5H36.8C36.8 7.02061 33.9794 4.2 30.5 4.2V9.8ZM27.7966 9.8H30.5V4.2H27.7966V9.8ZM26.1074 9.23217C26.0993 9.22661 26.0906 9.22028 26.0814 9.21313L29.5118 4.78687C29.4363 4.72831 29.359 4.67186 29.2799 4.61754L26.1074 9.23217ZM16.5467 2.65923L26.1074 9.23217L29.2799 4.61754L19.7193 -1.9554L16.5467 2.65923Z" fill="#0077B5" mask="url(#path-5-inside-1_56_218)"/>
        </svg>
    )
} 
export default BankIcon