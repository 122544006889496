import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../hooks/getWindowDimensions";
import classes from "./HelpCenterBreadCrumbs.module.css";

const HelpCenterBreadCrumbs = ({
  activeLink,
  link,
  root,
  rootText,
  search,
  mainText,
  onClick,
  onChange,
  submit
}) => {
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  return (
    <div
      className={`d-flex justify-content-between align-items-center"
      }   ${classes.wrapper}`}
    >
      <div className="d-flex flex-column justify-content-center">
      <div className="d-flex align-items-center">
        <div className={classes.prevLink} onClick={() => navigate(root)} role="button">{rootText}</div>
        {activeLink && <div
          className={`${classes.prevLink}`}
          role="button"
          onClick={() => navigate(link)}
        >
          <span className="mx-1">/</span>
          {activeLink}

        </div>}
      </div>
          <h3 onClick={onClick} role="button">{mainText}</h3>
        </div>
      {search && <div className={`input-group ${classes.searchContainer}`}>
        <input
          type="text"
          className="form-control"
          placeholder="Type your question"
          aria-label="Type your question"
          onChange={onChange}
        />
        <div className="input-group-append">
          <button className={`btn ${classes.searchButton}`} type="button" onClick={submit}>
            Search
          </button>
        </div>
      </div>}
    </div>
  );
};

// export default HelpCenterBreadCrumbs

export default HelpCenterBreadCrumbs;
