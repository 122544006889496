import React from 'react'

const UploadIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
            <path d="M19.2068 21.0719C19.2096 21.9651 18.4732 22.6914 17.562 22.6942C16.6507 22.697 15.9096 21.9752 15.9068 21.082L19.2068 21.0719Z" fill="#0077B5" />
            <path d="M13.7358 9.29409L15.8623 7.19662L15.9068 21.082L19.2068 21.0719L19.1623 7.18648L21.3023 9.27082C21.9486 9.90042 22.9934 9.89724 23.6357 9.26368C24.2781 8.63012 24.2748 7.60613 23.6284 6.97654L17.4925 1L11.3951 7.01411L13.7358 9.29409Z" fill="#0077B5" />
            <path d="M11.3951 7.01411C10.7527 7.64767 10.756 8.6717 11.4023 9.30129C12.0487 9.93089 13.0935 9.92765 13.7358 9.29409L11.3951 7.01411Z" fill="#0077B5" />
            <path d="M4.3 17.8274C4.3 16.041 5.77746 14.5928 7.6 14.5928C8.51127 14.5928 9.25 13.8688 9.25 12.9756C9.25 12.0824 8.51127 11.3583 7.6 11.3583C3.95492 11.3583 1 14.2546 1 17.8274V27.5309C1 31.1037 3.95492 34 7.6 34H25.75C30.3063 34 34 30.3796 34 25.9137V17.8274C34 14.2546 31.0451 11.3583 27.4 11.3583C26.4887 11.3583 25.75 12.0824 25.75 12.9756C25.75 13.8688 26.4887 14.5928 27.4 14.5928C29.2225 14.5928 30.7 16.041 30.7 17.8274V25.9137C30.7 28.5933 28.4838 30.7655 25.75 30.7655H7.6C5.77746 30.7655 4.3 29.3173 4.3 27.5309V17.8274Z" fill="#0077B5" />
            <path d="M19.2068 21.0719C19.2096 21.9651 18.4732 22.6914 17.5619 22.6942C16.6507 22.697 15.9096 21.9752 15.9068 21.082M19.2068 21.0719L15.9068 21.082M19.2068 21.0719L19.1623 7.18648L21.3023 9.27082M15.9068 21.082L15.8623 7.19662L13.7358 9.29409M13.7358 9.29409C13.0935 9.92765 12.0487 9.93089 11.4023 9.30129C10.756 8.6717 10.7527 7.64767 11.3951 7.01411M13.7358 9.29409L11.3951 7.01411M21.3023 9.27082C21.9486 9.90042 22.9934 9.89724 23.6357 9.26368C24.2781 8.63012 24.2748 7.60613 23.6284 6.97654M21.3023 9.27082L23.6284 6.97654M23.6284 6.97654L17.4925 1L11.3951 7.01411M7.6 14.5928C5.77746 14.5928 4.3 16.041 4.3 17.8274V27.5309C4.3 29.3173 5.77746 30.7655 7.6 30.7655H25.75C28.4838 30.7655 30.7 28.5932 30.7 25.9137V17.8274C30.7 16.041 29.2225 14.5928 27.4 14.5928C26.4887 14.5928 25.75 13.8688 25.75 12.9756C25.75 12.0824 26.4887 11.3583 27.4 11.3583C31.0451 11.3583 34 14.2546 34 17.8274V25.9137C34 30.3796 30.3063 34 25.75 34H7.6C3.95492 34 1 31.1037 1 27.5309V17.8274C1 14.2546 3.95492 11.3583 7.6 11.3583C8.51127 11.3583 9.25 12.0824 9.25 12.9756C9.25 13.8688 8.51127 14.5928 7.6 14.5928Z" stroke="#0077B5" stroke-width="0.6875" stroke-linecap="round" />
        </svg>
    )
}

export default UploadIcon