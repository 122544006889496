import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCookie } from "../../../../../utilities/functions";
import PBAPIClient from '../../../../../services/prime-client'
import useAddFundsStore from "../store";
import { usePageContext } from "../../../contexts/page.context";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import InfoIcon from '../../../../../icons/InfoIcon'
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import SuccessIcon from "../../../icons/Success.icon";
import { AxiosError } from "axios";
import { MethodOptions } from "../constants";
import RejectIcon from "../../../icons/Reject.icon";

const useAddFunds = () => {
    const queryClient = useQueryClient()
    const { lightMode } = usePageContext()
    const companyId = getCookie("companyId");
    const authToken = getCookie("authToken");
    const userId = getCookie("userId");
    const apiClient = new PBAPIClient('/transaction', authToken)
    const account = useAddFundsStore(s => s.firstDetails.account)
    const method = useAddFundsStore(s => s.firstDetails.method)
    const amount = useAddFundsStore(s => s.secondDetails.amount)
    const amountNum = parseFloat(amount.replace(/,/g, ''))
    const feePercent =
        amountNum >= Math.pow(10, 4) && amountNum < Math.pow(10, 5) ? 0.2 :
            amountNum >= Math.pow(10, 5) && amountNum < Math.pow(10, 6) ? 0.1 :
                amountNum >= Math.pow(10, 6) && amountNum < (Math.pow(10, 6) * 5) ? 0.05 :
                    amountNum >= (Math.pow(10, 6) * 5) && amountNum < (Math.pow(10, 6) * 10) ? 0.025 : amountNum >= (Math.pow(10, 6) * 10) ? 0.0125 : 0
    const depositFee = feePercent * amountNum
    const totalWithdrawal = method === MethodOptions[2] ? amountNum : amountNum + depositFee

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")
            toast.error(error.response?.status === 500 ?
                "Network Error" : error.response?.data, {
                position: "top-right",
                icon: <RejectIcon />,

                style: {

                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        } else
            throw error
    }

    return useMutation({
        mutationFn: () => apiClient.post({
            paymentMethod: method === MethodOptions[2] ? "cash-account" : method === MethodOptions[1] ? "crypto" : "wire-transfer",
            transactionType: "add-funds",
            company: companyId,
            account: account?._id,
            currency: "usd",
            withdrawalFee: depositFee,
            netPayout: amountNum,
            totalWithdrawal: totalWithdrawal,
            user: userId,
        }),
        onMutate() {
            toast.loading("Sending request.", {
                icon: <InfoIcon />,
                id: "loading",
                duration: 1000,
                position: "top-right",
                style: {
                    boxShadow: "none",
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onSuccess() {
            toast.success("Your deposit request has been submitted!", {
                icon: <SuccessIcon />,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    boxShadow: "none",
                    color: lightMode ? "black" : "white",
                    borderRadius: "8px",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
            queryClient.refetchQueries(["history"])
        },
        onError(error) {
            showError(error)
        }
    })
}

export default useAddFunds