import React from 'react'

const CashIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
            <path d="M17 1C8.17733 1 1 8.17733 1 17C1 25.8227 8.17733 33 17 33C25.8227 33 33 25.8227 33 17C33 8.17733 25.8227 1 17 1ZM17 30.3333C9.648 30.3333 3.66667 24.352 3.66667 17C3.66667 9.648 9.648 3.66667 17 3.66667C24.352 3.66667 30.3333 9.648 30.3333 17C30.3333 24.352 24.352 30.3333 17 30.3333ZM22.3333 19.6667C22.3333 21.872 20.5387 23.6667 18.3333 23.6667V25C18.3333 25.7373 17.7373 26.3333 17 26.3333C16.2627 26.3333 15.6667 25.7373 15.6667 25V23.6667H15.3093C13.8867 23.6667 12.5587 22.9013 11.8453 21.668C11.476 21.0293 11.6947 20.2147 12.3307 19.8467C12.9693 19.4747 13.7853 19.696 14.152 20.332C14.3907 20.7453 14.8333 21 15.308 21H18.332C19.068 21 19.6653 20.4027 19.6653 19.6667C19.6653 19.1627 19.304 18.736 18.8067 18.6533L14.752 17.9773C12.9627 17.68 11.6653 16.1467 11.6653 14.3333C11.6653 12.128 13.46 10.3333 15.6653 10.3333V9C15.6653 8.264 16.2613 7.66667 16.9987 7.66667C17.736 7.66667 18.332 8.264 18.332 9V10.3333H18.6893C20.112 10.3333 21.44 11.1 22.1533 12.3333C22.5227 12.9707 22.304 13.7853 21.668 14.1547C21.028 14.524 20.2133 14.3053 19.8467 13.668C19.608 13.256 19.1653 13.0013 18.6907 13.0013H15.6667C14.9307 13.0013 14.3333 13.6 14.3333 14.3347C14.3333 14.8387 14.6947 15.2653 15.192 15.348L19.2467 16.024C21.036 16.3213 22.3333 17.8547 22.3333 19.668V19.6667Z" fill="#0077B5" stroke="#0077B5" stroke-width="0.5" />
        </svg>
    )
}

export default CashIcon