import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import React from 'react'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'

const useWalletAccounts = () => {
    const authToken = getCookie("authToken")
    const companyId = getCookie("companyId")

    const apiClient = new PBAPIClient(`/account/wallet-accounts/${companyId}`, authToken)

    return useQuery({
        queryKey: ["wallet-accounts"],
        queryFn: () => apiClient.get(),
        staleTime: ms("1h"),
        enabled: !!companyId
    })

}

export default useWalletAccounts