import classes from "./LogoContainer.module.css";

const LogoContainer = () => {
  return (
    <div className={`d-flex align-items-center  ${classes.wrapper}`}>
      <img
        src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/prime-bridge-logo-white.svg"
        alt=""
      />
      <h4>Prime Bridge</h4>
    </div>
  );
};

export default LogoContainer;
