import React, { useEffect, useState } from 'react';
import classes from './DetailsRow.module.css'
import { env } from '../../../constants/env';
import { nFormatter } from '../../../utilities/functions';




const DetailsRow = ({ data, setIsLoading }) => {

  const [balance, setBalance] = useState(0)

  // useEffect(() => {
  //   getProfits(data.credentials.apiId)
  // }, [data])


  const getProfits = async (id) => {
    try {
      const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();


      setIsLoading(false)

      const newBalance = nFormatter(result.balance)

      setBalance(newBalance)

      return result;

    
    } catch (error) {
      console.log(error);
    }
  };
  
    return (
      <>
      <tr >
        <td>{data?.accountName}</td>
        <td>{data?.accountNumber}</td>
        <td>{data?.balance ? nFormatter(data?.balance, 2) : 0}</td>
        <td><span className={data.status === "Failed" ? classes.failedRed : classes.activeGreen}>Active</span></td>
      </tr>
      
        </>
  
    );
  };

  export default DetailsRow