import { HStack, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import React, { useEffect } from "react";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../../utilities/functions";
import { usePageContext } from "../../../../contexts/page.context";
import InfoIcon from "../../../../components/Info.icon";
import { Text } from "../../../../components/text";
import { useSendfundsContext } from "../../sendfunds.context";
import Tip from "./../tip/NormalTip";
import { PRIMARY_COLOR } from "../../../../../../constants/colors";
import SuccessIcon from "../../../../icons/Success.icon";
import { toast } from "react-hot-toast";
import useSendFundsStore from "../../store";
import useTradingAccounts from "../../../accounts/hooks/useTradingAccounts";
import { useSelector } from "react-redux";
import useSaveAddress from "../../hooks/useSaveAddress";
import useWalletStore from "../../../../store";
import useSendFunds from "../../hooks/useSendFunds";

const CryptoDetails = () => {
  const { lightMode } = usePageContext();
  const closeSection = useWalletStore((s) => s.closeSection);
  const completePage = useSendFundsStore((s) => s.completePage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const coin = useSendFundsStore((s) => s.cryptoDetails.coin);
  const walletAddress = useSendFundsStore((s) => s.cryptoDetails.walletAddress);
  const amount = useSendFundsStore((s) => s.secondDetails.amount);
  const source = useSendFundsStore((s) => s.firstDetails.source);
  const saveAddress = useSendFundsStore((s) => s.cryptoDetails.saveAddress);
  const { mutateAsync: sendFunds } = useSendFunds();
  const { mutateAsync: saveCryptoAddress } = useSaveAddress();
  const resetPage = useSendFundsStore((s) => s.resetPage);
  const inputSource = useWalletStore((s) => s.inputSource);
  const pbShareSettings = source?.pbShareSettings;
  const withdrawalSettings = useSelector(
    (state) => state.company.withdrawalSettings
  );

  useEffect(() => {
    completePage();
    return () => {
      inCompletePage();
    };
  }, []);

  const handleSubmit = () => {
    sendFunds().then(async () => {
      if (saveAddress) {
        await saveCryptoAddress();
      }
      resetPage();
      inputSource("");
      closeSection();
    });
  };

  return (
    <HStack
      mt={"8px"}
      justifyContent={"space-between"}
      bg={lightMode ? "#f5f5f5" : "#01293E"}
      w={"100%"}
      borderRadius={"15px"}
      alignItems={"flex-start"}
      px={"37px"}
      py={"23px"}
    >
      <HStack gap={"68px"} alignItems={"flex-start"}>
        <VStack gap={"14px"} alignItems={"flex-start"}>
          <Text mb={"8px"} fontWeight={"600"} fontSize={"14px"}>
            Withdrawal Details
          </Text>
          <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Total Withdrawal: US${" "}
              {numberWithCommas(parseFloat(amount.replace(/,/g, "")))}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Amount requested for withdrawal
            </Tip>
          </HStack>
          <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              PB Share: US${" "}
              {numberWithCommas(
                Math.round(
                  parseFloat(amount.replace(/,/g, "")) * +pbShareSettings * 100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Prime Bridge's portion of the withdrawal
            </Tip>
          </HStack>
          <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Withdrawal Fee: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")) -
                    parseFloat(amount.replace(/,/g, "")) * +pbShareSettings) *
                    +withdrawalSettings *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Fee paid to the regulated custodian
            </Tip>
          </HStack>
          <HStack>
            <Text fontWeight={"500"} fontSize={"14px"}>
              Net Payout: US${" "}
              {numberWithCommas(
                Math.round(
                  (parseFloat(amount.replace(/,/g, "")) -
                    parseFloat(amount.replace(/,/g, "")) * +pbShareSettings -
                    (parseFloat(amount.replace(/,/g, "")) -
                      parseFloat(amount.replace(/,/g, "")) * +pbShareSettings) *
                      +withdrawalSettings) *
                    100
                ) / 100
              )}
            </Text>
            <Tip icon={<InfoIcon size={"12"} />}>
              Final amount you receive after deductions
            </Tip>
          </HStack>
        </VStack>
        <VStack
          flexWrap={"wrap"}
          mt={"42px"}
          gap={"14px"}
          alignItems={"flex-start"}
        >
          <Text fontWeight={"500"} fontSize={"14px"}>
            Source: {source.accountName} - {source.credentials.accountNumber}
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Destination: Crypto ({coin})
          </Text>
          <Text fontWeight={"500"} fontSize={"14px"}>
            Crypto Wallet Address: <br />
            {walletAddress}
          </Text>
        </VStack>
      </HStack>
      <Button
        width={"150px"}
        gap={"10px"}
        borderRadius={"31px"}
        bg={PRIMARY_COLOR}
        _hover={{
          bg: PRIMARY_COLOR,
          color: "white",
        }}
        fontWeight={"700"}
        color={"white"}
        height={"48px"}
        onClick={handleSubmit}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        fontSize={"13px"}
      >
        Confirm
      </Button>
    </HStack>
  );
};

export default CryptoDetails;
