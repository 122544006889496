import { env } from "../constants/env";
import { accountsActions } from "./accounts-slice";

export const fetchAllAccounts =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      console.log("Results get accounts redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(accountsActions.getAccounts({ allAccounts: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllOverviewAccounts =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/overview/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(accountsActions.getOverviewAccounts({ allOverviewAccounts: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchAllManagementAccounts =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/management/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(accountsActions.getManagementAccounts({ allManagementAccounts: data }));
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchAllLPGroups =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/lp-groups/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(accountsActions.getLPGroups({ lpGroups: data }));
    } catch (error) {
      console.log(error);
    }
  };
};
