const ShareArrowIcon = ({ themeColor }) => {
  return (
    <svg
      width="13"
      height="18"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.34 5.25079C15.2894 5.12721 15.2146 5.015 15.12 4.92079L10.49 0.290793C10.3026 0.104542 10.0492 0 9.785 0C9.52081 0 9.26736 0.104542 9.08 0.290793C8.98627 0.383756 8.91188 0.494357 8.86111 0.616216C8.81034 0.738075 8.7842 0.868781 8.7842 1.00079C8.7842 1.1328 8.81034 1.26351 8.86111 1.38537C8.91188 1.50723 8.98627 1.61783 9.08 1.71079L12 4.63079H4C2.93913 4.63079 1.92172 5.05222 1.17157 5.80237C0.421427 6.55251 0 7.56993 0 8.63079V19.0008C0 19.266 0.105357 19.5204 0.292893 19.7079C0.48043 19.8954 0.734784 20.0008 1 20.0008C1.26522 20.0008 1.51957 19.8954 1.70711 19.7079C1.89464 19.5204 2 19.266 2 19.0008V8.63079C2 8.10036 2.21071 7.59165 2.58579 7.21658C2.96086 6.84151 3.46957 6.63079 4 6.63079H12L9.08 9.55079C8.93897 9.69066 8.84279 9.86936 8.80372 10.0641C8.76465 10.2589 8.78446 10.4608 8.86063 10.6443C8.9368 10.8277 9.06587 10.9843 9.23139 11.0941C9.39691 11.2039 9.59138 11.2619 9.79 11.2608C10.0538 11.257 10.3054 11.1492 10.49 10.9608L15.12 6.34079C15.2122 6.24086 15.2866 6.12586 15.34 6.00079C15.4373 5.76026 15.4373 5.49133 15.34 5.25079Z"
        fill={themeColor}
      />
    </svg>
  );
};
export default ShareArrowIcon;
