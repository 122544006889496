import { Route, Routes } from "react-router-dom"
import Article from "./Article"
import Categories from "./Categories"
import Category from "./Category"
import ContactUs from "./ContactUs"
import HelpCenter from "./HelpCenter"
import OpenTickets from "./OpenTickets"

const Help = ({ themeMode }) => {
    return (
        <div className="main-divs">
            <Routes>
                <Route path="/" element={<HelpCenter themeMode={themeMode} />} />
                <Route path="/contact-us" element={<ContactUs themeMode={themeMode} />} />
                <Route path="/open-tickets" element={<OpenTickets themeMode={themeMode} />} />
                <Route path="/categories" element={<Categories />}>
                    <Route path="/categories/category/:id" element={<Category />} />
                </Route>
            </Routes>
        </div>
    )
}
export default Help