import { createSlice } from '@reduxjs/toolkit';

const tradesSlice = createSlice({
  name: 'trades',
  initialState: {
    closedTrades: [],
    totalClosedTrades: 0,
    openTrades: [],
    totalOpenTrades: 0,
    monthlyGain: [],
    statistics: [],
  },
  reducers: {

    getClosedTrades (state, action) {
      state.closedTrades = action.payload.closedTrades
    },

    getTotalClosedTrades (state, action) {
      state.totalClosedTrades = action.payload.totalClosedTrades
    },
    getOpenTrades (state, action) {
      state.openTrades = action.payload.openTrades
    },

    getTotalOpenTrades (state, action) {
      state.totalOpenTrades = action.payload.totalOpenTrades
    },

    getMonthlyGain (state, action) {
      state.monthlyGain = action.payload.monthlyGain
    },
    getStatistics (state, action) {
      state.statistics = action.payload.statistics
    },
  }
});

export const tradesActions = tradesSlice.actions;

export default tradesSlice;
