import { useNavigate } from "react-router-dom";
import classes from "./AuthCard.module.css"

const AuthCard = ({children, height}) => {
    
    return(<div className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`} style={{height: height}}>
        {children}
    </div>)
}

export default AuthCard