import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import styles from "./GainChartComponent.module.css"
import ChartCustomTooltip from '../chartComponent/components/ChartCustomTooltip';
import { Button } from '../chartComponent/Chart';
import GainChartCustomTooltip from './components/GainChartCustomTooltip';

const data = [
  {
    date: 'Page A',
    // uv: 4000,
    profit: 2400,
    // amt: 2400,
  },
  {
    date: 'Page B',
    // uv: 3000,
    profit: 1398,
    // amt: 2210,
  },
  {
    date: 'Page C',
    // uv: 2000,
    profit: 9800,
    // amt: 2290,
  },
  {
    date: 'Page D',
    // uv: 2780,
    profit: 3908,
    // amt: 2000,
  },
  {
    date: 'Page E',
    // uv: 1890,
    profit: 4800,
    // amt: 2181,
  },
  {
    date: 'Page F',
    // uv: 2390,
    profit: 3800,
    // amt: 2500,
  },
  {
    date: 'Page G',
    // uv: 3490,
    profit: 4300,
    // amt: 2100,
  },
];

export default class GainChartComponents extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  constructor(props) {
    super(props);
    this.state = {
      percentageActive: false,
      oneDayActive: false,
      fiveDaysActive: false,
      oneMonthActive: false,
      oneYearActive: false,
      yearToDateActive: false,
      allActive: true,
    };
  }

  render() {
    const {currentBalance, maxBalance, data, query, selectedOption, themeMode} = this.props
    return (
    <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" tick={false}/>
          <YAxis tickCount={3} unit={'%'} tick={{fill: themeMode === "dark" ? "#FFF" : undefined}}/>

          <Tooltip content={<GainChartCustomTooltip selectedOption={selectedOption}/>} cursor={{ fill: "transparent" }} />
          <Line dataKey="value" stroke="#81CEF5"  strokeWidth={3} dot={false}/>
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
      <div className={`${styles.chartBtns} d-flex justify-content-center align-items-center gap-4`}>
            <Button
              active={this.state.oneDayActive}
              onClick={() => {
                query(1)
                this.setState({
                  oneDayActive: true,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1D
            </Button>
            <Button
              active={this.state.fiveDaysActive}
              onClick={() =>{
                query(7)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: true,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1W
            </Button>
            <Button
              active={this.state.oneMonthActive}
              onClick={() =>{
                query(30)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: true,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              1M
            </Button>
            <Button
              active={this.state.oneYearActive}
              onClick={() => {
                query(90)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: true,
                  yearToDateActive: false,
                  allActive: false,
                })
              }
              }
            >
              3M
            </Button>
            <Button
              active={this.state.yearToDateActive}
              onClick={() =>{
                query(365)
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: true,
                  allActive: false,
                })
              }
              }
            >
              Y
            </Button>
            <Button
              active={this.state.allActive}
              onClick={() =>{
                query('all')
                this.setState({
                  oneDayActive: false,
                  fiveDaysActive: false,
                  oneMonthActive: false,
                  oneYearActive: false,
                  yearToDateActive: false,
                  allActive: true,
                })
              }
              }
            >
              ALL
            </Button>
        </div>
      </div>
    );
  }
}