import { Icon } from "@chakra-ui/icons";
import { Box, Center, HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "../../../../../constants/colors";
import { usePageContext } from "../../../contexts/page.context";
import { Progress } from "../../../components/progress";
import { Text } from "../../../components/text";
import TransferIcon from "../../../components/Transfer.icon";
import useWalletStore from "../../../store";
import SECTIONS from "../../actions/data/sectionTitle";
import CloseIcon from "../../addfunds/icons/Close.icon";
import AmountInput from "./AmountInput";
import InitialStep from "./InitialStep";
import TransferDetails from "./TransferDetails";
import useTransferFundsStore from "../store";

const TransferFunds = () => {
  const { lightMode } = usePageContext();
  const resetPage = useTransferFundsStore((s) => s.resetPage);
  const closeSection = useWalletStore((s) => s.closeSection);
  const currentSection = useWalletStore((s) => s.section);

  const page = useTransferFundsStore((s) => s.page);
  const pageNumber = useTransferFundsStore((s) => s.pageNumber);
  const inputSource = useWalletStore((s) => s.inputSource);

  useEffect(() => {
    return () => {
      resetPage();
      inputSource();
    };
  }, []);

  return currentSection === SECTIONS[3] ? (
    <VStack
      border={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
      borderRadius={"20px"}
      w={"100%"}
      px={"39px"}
      py={"28px"}
    >
      <HStack width={"100%"} alignItems={"flex-start"} gap={"24px"}>
        <Center
          flexShrink={0}
          borderRadius={"50%"}
          w={"60px"}
          h={"60px"}
          backgroundColor={lightMode ? SECONDARY_COLOR : "#01293E"}
        >
          <Icon width={"24px"} height={"24px"} as={TransferIcon} />
        </Center>
        <VStack flexGrow={1}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontWeight={"700"}>
              Transfer Funds
            </Text>
            {/* {page === 0 && (
              <HStack
                bg={lightMode ? "#E5F1F7" : "#01293E"}
                color={lightMode ? "#000" : "#fff"}
                px={"16px"}
                py={"10px"}
                borderRadius={"10px"}
              >
                <Text lineHeight={"14px"} fontSize={"14px"}>
                  Note trades need to be closed before the transfer can be made
                </Text>
              </HStack>
            )} */}

            <HStack gap={"23px"}>
              <HStack gap={"13px"}>
                <Progress value={pageNumber / 3} />
                <Text
                  fontSize={"17px"}
                  color={pageNumber === 3 ? PRIMARY_COLOR : "#7f7f7f"}
                >
                  {pageNumber}/3
                </Text>
              </HStack>
              <Box
                cursor={"pointer"}
                onClick={() => {
                  resetPage();
                  closeSection();
                  inputSource("");
                }}
              >
                <CloseIcon />
              </Box>
            </HStack>
          </HStack>

          {page === 0 && <InitialStep />}
          {page === 1 && <AmountInput />}
          {page === 2 && <TransferDetails onClose={closeSection} />}
        </VStack>
      </HStack>
    </VStack>
  ) : null;
};

export default TransferFunds;
