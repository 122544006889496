const MailIcon = () =>{
    return(
        <svg width="71" height="76" viewBox="0 0 81 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M77.0785 37.7305C75.7668 37.7305 76.5014 38.0715 64.8797 28.1016C63.7778 27.1571 63.6466 25.5041 64.5911 24.4022C65.5355 23.3003 67.1882 23.1691 68.2901 24.1136L78.7837 33.1128C80.6201 34.687 79.4921 37.7305 77.0785 37.7305Z" fill="white" stroke="white" stroke-width="2"/>
        <path d="M3.62242 37.7305C1.18264 37.7305 0.080825 34.687 1.91722 33.1128L12.4109 24.1136C13.5127 23.1691 15.1654 23.3003 16.1099 24.4022C17.0543 25.5041 16.9231 27.1571 15.8213 28.1016C4.64553 37.678 4.96036 37.7305 3.62242 37.7305Z" fill="white" stroke="white" stroke-width="2"/>
        <path d="M66.5839 45.6024C65.141 45.6024 63.9605 44.4217 63.9605 42.9787V8.87101C63.9605 7.42799 62.78 6.24734 61.3371 6.24734H19.3624C17.9196 6.24734 16.739 7.42799 16.739 8.87101V42.9787C16.739 44.4217 15.5585 45.6024 14.1156 45.6024C12.6727 45.6024 11.4922 44.4217 11.4922 42.9787V8.87101C11.4922 4.54195 15.0338 1 19.3624 1H61.3371C65.6657 1 69.2073 4.54195 69.2073 8.87101V42.9787C69.2073 44.4217 68.0268 45.6024 66.5839 45.6024Z" fill="white" stroke="white" stroke-width="2"/>
        <path d="M79.7018 35.1057C79.7018 32.928 77.2095 31.7211 75.5043 33.0067L49.139 52.7892L45.7285 49.6146C42.7116 46.8335 38.0157 46.8335 35.025 49.6146L31.6145 52.7892L5.24922 33.0067C3.51776 31.6949 1.05178 32.9543 1.05178 35.1057C1.05178 86.1623 0.815674 82.699 1.55023 83.7747C2.62583 85.3489 2.59959 84.9554 19.4157 84.9554C20.8586 84.9554 22.0391 83.7747 22.0391 82.3317C22.0391 80.8887 20.8586 79.708 19.4157 79.708H10.3649L38.6191 53.4713C39.616 52.5531 41.19 52.5531 42.1869 53.4713L70.4411 79.708H29.9093C28.4665 79.708 27.2859 80.8887 27.2859 82.3317C27.2859 83.7747 28.4665 84.9554 29.9093 84.9554C80.5675 84.9554 77.3144 85.0341 78.049 84.7717C78.5737 84.5881 79.1508 84.1158 79.4657 83.4861C79.8854 82.6466 79.7542 86.0311 79.7542 35.1057H79.7018ZM27.6794 56.4099L6.24613 76.2973V40.3268L27.6794 56.3836V56.4099ZM53.0478 56.4099L74.4811 40.353V76.3235L53.0478 56.4361V56.4099Z" fill="white" stroke="white" stroke-width="2"/>
        </svg>
    )
} 
export default MailIcon