const LoadingSpinnerIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "22"}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.86667 1.00004C8.42634 0.995986 7.00205 1.30885 5.69063 1.91736C4.37921 2.52586 3.21142 3.41575 2.26667 4.5265V4.23532C2.26667 4.06371 2.19994 3.89913 2.08117 3.77778C1.96239 3.65644 1.8013 3.58826 1.63333 3.58826C1.46536 3.58826 1.30427 3.65644 1.1855 3.77778C1.06673 3.89913 1 4.06371 1 4.23532V6.17649C1 6.3481 1.06673 6.51268 1.1855 6.63403C1.30427 6.75538 1.46536 6.82355 1.63333 6.82355H3.53333C3.7013 6.82355 3.86239 6.75538 3.98117 6.63403C4.09994 6.51268 4.16667 6.3481 4.16667 6.17649C4.16667 6.00488 4.09994 5.8403 3.98117 5.71895C3.86239 5.59761 3.7013 5.52943 3.53333 5.52943H3.09633C3.92137 4.51332 4.95625 3.69629 6.12677 3.13695C7.29729 2.5776 8.57445 2.28979 9.86667 2.29415C12.2183 2.29415 14.4735 3.24856 16.1363 4.94741C17.7992 6.64626 18.7333 8.9504 18.7333 11.3529C18.7333 11.5246 18.8001 11.6891 18.9188 11.8105C19.0376 11.9318 19.1987 12 19.3667 12C19.5346 12 19.6957 11.9318 19.8145 11.8105C19.9333 11.6891 20 11.5246 20 11.3529C20 8.60718 18.9324 5.97388 17.032 4.03233C15.1316 2.09079 12.5542 1.00004 9.86667 1.00004Z"
        fill={color ? color : "black"}
        stroke={color ? color : "black"}
        stroke-width="0.6"
      />
      <path
        d="M17.3929 15.3182H15.5717C15.4107 15.3182 15.2563 15.3848 15.1425 15.5033C15.0286 15.6219 14.9647 15.7826 14.9647 15.9503C14.9647 16.1179 15.0286 16.2787 15.1425 16.3972C15.2563 16.5157 15.4107 16.5823 15.5717 16.5823H15.9906C15.112 17.6682 13.9953 18.518 12.7348 19.06C11.4744 19.602 10.1071 19.8202 8.74863 19.6963C7.39011 19.5723 6.08019 19.1098 4.92949 18.3478C3.77879 17.5858 2.82111 16.5467 2.13731 15.3182C2.05681 15.1724 1.92396 15.0658 1.76799 15.0219C1.61202 14.9781 1.4457 15.0005 1.30563 15.0843C1.16555 15.1682 1.0632 15.3065 1.02107 15.4689C0.978948 15.6313 1.00051 15.8044 1.08101 15.9503C1.85313 17.3402 2.93202 18.5187 4.22844 19.3884C5.52486 20.258 7.00153 20.7937 8.53627 20.9512C10.071 21.1087 11.6197 20.8835 13.0543 20.2941C14.4888 19.7048 15.768 18.7682 16.7859 17.562V17.8464C16.7859 18.0141 16.8498 18.1748 16.9637 18.2934C17.0775 18.4119 17.2319 18.4785 17.3929 18.4785C17.5539 18.4785 17.7083 18.4119 17.8222 18.2934C17.936 18.1748 18 18.0141 18 17.8464V15.9503C18 15.7826 17.936 15.6219 17.8222 15.5033C17.7083 15.3848 17.5539 15.3182 17.3929 15.3182Z"
        fill={color ? color : "black"}
        stroke={color ? color : "black"}
        stroke-width="0.6"
      />
    </svg>
  );
};
export default LoadingSpinnerIcon;
