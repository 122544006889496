import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AssetIcon from "../../../icons/AssetIcon";
import ChatIcon from "../../../icons/ChatIcon";
import TeamIcon from "../../../icons/TeamIcon";
import TransactionIcon from "../../../icons/TransactionIcon";
import { deleteCookie, getCookie } from "../../../utilities/functions";
import LinkContainer from "./components/LinkContainer";
import classes from "./SideBar.module.css";
import NotificationBellIcon from "../../../icons/NotificationBell";
import { io } from "socket.io-client";
import { env } from "../../../constants/env";
import { notificationsActions } from "../../../Redux/notifications-slice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllNotifications } from "../../../Redux/notifications-actions";
import OverviewIcon from "../../../icons/OverviewIcon";
import { fetchAllOverviewAccounts } from "../../../Redux/accounts-actions";
import BulbIcon from "../../../icons/BulbIcon";
import BulbOnIcon from "../../../icons/BulbOnIcon";
import LogoutIcon from "../../../icons/LogoutIcon";
import MenuIcon from "../../../icons/MenuIcon";

const SideBar = ({ isBannerOn, themeMode, singleUser, handleThemeChange, showLogout, setShowLogout }) => {
  const companyName = useSelector(state => state.company.companyName)
  const companyId = getCookie("companyId");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [showTransactionsSubMenu, setShowTransactionsSubMenu] = useState(false)
  const allOverviewAccounts = useSelector((state) => state.accounts.allOverviewAccounts);

  const [notifications, setNotifications] = useState([])
  const [overview, setOverview] = useState()

  const isAdmin = getCookie('isAdmin')
  const userId = getCookie('userId')

  useEffect(() => {
    const nsocket = io(env.notificationsUrl, {
      query: 'user=' + userId
    })
    nsocket.on('new-notification', notificationListener)

    getAllNotifications()
    return () => {
      nsocket.off('new-notification', notificationListener)
    }
  }, [userId])

  useEffect(() => {
    dispatch(fetchAllOverviewAccounts(companyId))
    fetchSingleCompany(companyId)
  }, [])


  // useEffect(() => {
  //   if(!overview){
  //     if(window.location.pathname === "/dashboard/overview"){
  //       window.location = '/dashboard';
  //       return
  //     }
  //   }
  // }, [overview])

  const notificationListener = async () => {
    await getAllNotifications()
    dispatch(fetchAllNotifications(userId))
    if (window.location.pathname === "/dashboard/notifications") {
      console.log("REACHED HERE", notifications)
      for (let i = 0; i < notifications.length; i++) {
        updateStatus(notifications[i]._id)
      }
    }
  }

  const markAsRead = () => {
    for (let i = 0; i < notifications.length; i++) {
      updateStatus(notifications[i]._id)
    }
    // getAllNotifications();
  }

  const getAllNotifications = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.notificationsUrl}/notifications/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      var newArray = result.docs.filter(function (el) {
        return el.status === "unread"
      });

      setNotifications(newArray);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.notificationsUrl}/notifications/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("Is it reaching here?", result)

      getAllNotifications();

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSingleCompany = async (companyId) => {
    try {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("SINGLE COMPANY", result)
      setOverview(result.overview)

      return result;

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className={` d-flex flex-column justify-content-between ${isBannerOn ? classes.reduceHeight : classes.wrapper}`}>

      <div
        className={`${classes.menuWrapper} d-flex flex-column w-100`}
      >
        {(((singleUser?.isAdmin && overview) || singleUser?.accessLevel?.includes('overview')) && overview) && <LinkContainer title="Overview" activeTab={window.location.pathname === "/dashboard/overview"} onClick={() => { navigate("/dashboard/overview"); setShowTransactionsSubMenu(false) }}>
          <OverviewIcon active={window.location.pathname === "/dashboard/overview"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>}
        {(singleUser?.isAdmin || singleUser?.accessLevel?.includes('asset-dashboard')) && <LinkContainer title="My Assets" activeTab={window.location.pathname === "/dashboard"} onClick={() => { navigate("/dashboard"); setShowTransactionsSubMenu(false) }}>
          <AssetIcon active={window.location.pathname === "/dashboard"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>}
        {(singleUser?.isAdmin || singleUser?.accessLevel?.includes('transactions')) && <><LinkContainer title="Wallet" activeTab={window.location.pathname === "/dashboard/transactions" || window.location.pathname === "/dashboard/transactions/funds" || window.location.pathname === "/dashboard/transactions/services-fees"} onClick={() => { navigate("/dashboard/transactions"); setShowTransactionsSubMenu(!showTransactionsSubMenu) }}>
          <TransactionIcon active={window.location.pathname === "/dashboard/transactions" || window.location.pathname === "/dashboard/transactions/funds" || window.location.pathname === "/dashboard/transactions/services-fees"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>
          {showTransactionsSubMenu && <div className={classes.subMenuLinks}>
            <h6 className={window.location.pathname === "/dashboard/transactions/funds" ? classes.activeSubMenu : undefined} onClick={() => navigate("/dashboard/transactions/funds")} role="button">Funds</h6>
            <h6 className={window.location.pathname === "/dashboard/transactions/services-fees" ? classes.activeSubMenu : undefined} onClick={() => navigate("/dashboard/transactions/services-fees")} role="button">Service Fees</h6>
            {/* <h6 className={ window.location.pathname === "/dashboard/transactions/summaries" ? classes.activeSubMenu : undefined} onClick={() => navigate("/dashboard/transactions/summaries")} role="button">Summaries</h6> */}
          </div>}</>}
        {(singleUser?.isAdmin || singleUser?.accessLevel?.includes('team')) && <LinkContainer title="Team" activeTab={window.location.pathname === "/dashboard/team" || window.location.pathname === "/dashboard/team/add-member"} onClick={() => { navigate("/dashboard/team"); setShowTransactionsSubMenu(false) }}>
          <TeamIcon active={window.location.pathname === "/dashboard/team" || window.location.pathname === "/dashboard/team/add-member"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>}
        <LinkContainer title="Notifications" activeTab={window.location.pathname === "/dashboard/notifications"} onClick={() => { navigate("/dashboard/notifications"); setShowTransactionsSubMenu(false); markAsRead() }} newNotificatiion={notifications.length > 0}>
          <NotificationBellIcon active={window.location.pathname === "/dashboard/notifications"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>
        {(singleUser?.isAdmin || singleUser?.accessLevel?.includes('help-center')) && <LinkContainer title="Help Center" activeTab={window.location.pathname === "/dashboard/help" || window.location.pathname === "/dashboard/help/contact-us" || window.location.pathname === "/dashboard/help/categories" || window.location.pathname === "/dashboard/help/categories/category/assets" || window.location.pathname === "/dashboard/help/categories/category/transactions" || window.location.pathname === "/dashboard/help/categories/category/help"} onClick={() => { navigate("/dashboard/help"); setShowTransactionsSubMenu(false) }}>
          <ChatIcon active={window.location.pathname === "/dashboard/help" || window.location.pathname === "/dashboard/help/contact-us" || window.location.pathname === "/dashboard/help/categories" || window.location.pathname === "/dashboard/help/categories/category/assets" || window.location.pathname === "/dashboard/help/categories/category/transactions" || window.location.pathname === "/dashboard/help/categories/category/help"} themeColor={themeMode === "dark" && "#FFFFFF"} />
        </LinkContainer>}

      </div>
      <div className={`${classes.avatarContainer} d-flex  align-items-center`}>
        {/* <div  className={classes.avatarImage}
                  style={{
                    background: `url(https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/avatar-image.png)`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                  }} onClick={() => setShowLogout(!showLogout)} role="button"></div> */}
        <div className={`d-flex  justify-content-between w-100`}>
          <div>
            <h6 className={`noselect ${classes.userNameText}`}>{singleUser ? `${singleUser?.firstName} ${singleUser?.lastName}` : ""}</h6>
            <h6 className={`noselect ${classes.companyNameText}`}>{companyName}</h6>
          </div>
          <div id="show-logout-card">
            <MenuIcon onClick={() => setShowLogout(!showLogout)} color={themeMode === 'light' ? 'black' : 'white'} />
          </div>

        </div>

        {showLogout && <div className={`${classes.logoutConatiner} d-flex flex-column justify-content-center`}>
          <div className={classes.point}></div>
          <div className={`d-flex  align-items-center noselect ${classes.darkMode}`} role="button" onClick={() => handleThemeChange(themeMode === "light" ? "dark" : "light")}> <div className={classes.iconBox} >{themeMode === "light" ? <BulbIcon /> : <BulbOnIcon />}</div>{themeMode === "light" ? "Dark Mode" : "Bright Mode"}</div>
          <div className={`d-flex  align-items-center noselect ${classes.logout}`} role="button" onClick={() => { deleteCookie('authToken', '/', env.domainUrl); window.location = '/' }}> <div className={classes.iconBox}><LogoutIcon /> </div>Log Out</div>
        </div>}
      </div>
    </div>
  );
};

export default SideBar;
