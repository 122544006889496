import { HStack } from "@chakra-ui/layout";
import React from "react";
import { usePageContext } from "../../contexts/page.context";
import { Text } from "../text";
import { useMenuContext } from "./menu.context";

const MenuItem = ({ children, disabled }) => {
  const { onChange, value, px, toggleMenu } = useMenuContext();
  const { lightMode } = usePageContext();

  return (
    <HStack
      opacity={disabled ? 0.5 : 1}
      onClick={() => {
        if (!disabled) {
          if (!!children?.length) onChange(children.toString());
          else onChange(children);
          toggleMenu();
        }
      }}
      cursor={"pointer"}
      width={"100%"}
      _hover={{
        bg: lightMode ? "#f0f0f0" : "rgba(255,255,255,0.25)",
      }}
      w={"100%"}
      px={px}
      py={"8px"}
    >
      <Text
        mb={0}
        fontSize={"14px"}
        fontWeight={value === children ? "700" : "500"}
      >
        {children}
      </Text>
    </HStack>
  );
};

export default MenuItem;
