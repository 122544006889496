import React from "react";
import { usePageContext } from "../contexts/page.context";

const TrashIcon = () => {
  const { lightMode } = usePageContext();
  const RED = lightMode ? "#BD3437" : "#FD6B6E";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
    >
      <path
        d="M1.66467 9.74617C1.57532 8.58458 2.49376 7.59277 3.65878 7.59277H13.3393C14.5043 7.59277 15.4227 8.58458 15.3334 9.74617L14.6411 18.7462C14.5609 19.7882 13.692 20.5928 12.647 20.5928H4.35109C3.30601 20.5928 2.43713 19.7882 2.35698 18.7462L1.66467 9.74617Z"
        stroke={RED}
        stroke-width="2"
      />
      <path
        d="M15.999 4.59277H0.999023"
        stroke={RED}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M6.49902 2.59277C6.49902 2.04049 6.94674 1.59277 7.49902 1.59277H9.49902C10.0513 1.59277 10.499 2.04049 10.499 2.59277V4.59277H6.49902V2.59277Z"
        stroke={RED}
        stroke-width="2"
      />
      <path
        d="M10.499 11.5928V16.5928"
        stroke={RED}
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M6.49902 11.5928V16.5928"
        stroke={RED}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default TrashIcon;
