import React from "react";

const RejectIcon = () => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4084 9.56138L16.8264 1.65455C15.8027 0.58841 14.4432 0 12.9959 0C11.5487 0 10.1881 0.58841 9.16543 1.65455L1.5835 9.56138C-0.527832 11.7625 -0.527832 15.3438 1.5835 17.545L9.16543 25.4518C10.1881 26.518 11.5487 27.1064 12.9959 27.1064C14.4432 27.1064 15.8027 26.518 16.8264 25.4518L24.4084 17.545C25.431 16.4789 25.9943 15.0615 25.9943 13.5538C25.9943 12.046 25.431 10.6275 24.4084 9.56138ZM11.9116 7.91247C11.9116 7.28792 12.3969 6.78309 12.9949 6.78309C13.5928 6.78309 14.0781 7.28792 14.0781 7.91247V14.1241C14.0781 14.7486 13.5928 15.2535 12.9949 15.2535C12.3969 15.2535 11.9116 14.7486 11.9116 14.1241V7.91247ZM12.9949 20.3357C12.0979 20.3357 11.3699 19.5768 11.3699 18.6416C11.3699 17.7065 12.0979 16.9476 12.9949 16.9476C13.8918 16.9476 14.6198 17.7065 14.6198 18.6416C14.6198 19.5768 13.8918 20.3357 12.9949 20.3357Z"
        fill="#BD3437"
      />
    </svg>
  );
};

export default RejectIcon;
