import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { env } from '../constants/env';

// export interface FetchResponse<T> {
//     data: T[],
// }

class PBAPIClient {
    endpoint;
    axiosInstance;

    constructor(endpoint, token) {
        this.endpoint = endpoint
        this.axiosInstance = axios.create({
            baseURL: env.pbTrackingUrl + "/api",
            headers: {
                'Content-Type': 'multipart/form-data',
                ...(!!token && { "x-api-key": token })
            }
        })
    }

    getAll = (config) => {
        return this.axiosInstance.get(this.endpoint, config)
            .then(res => res.data)
    }

    getWithConfig = (config) => {
        return this.axiosInstance.get(this.endpoint, config)
            .then(res => res.data)
    }

    getMany = (id) => {
        return this.axiosInstance.get(this.endpoint + '/' + id)
            .then(res => res.data)
    }

    get = (id) => {
        return this.axiosInstance.get(this.endpoint + '/' + (id ? id : ""))
            .then(res => res.data)
    }

    post = (data, id) => {
        return this.axiosInstance.post(this.endpoint + '/' + (id ? id : ""), data)
            .then(res => res.data)
    }

    delete = (id) => {
        return this.axiosInstance.delete(this.endpoint + '/' + (id ? id : ""))
            .then(res => res.data)
    }
}

export default PBAPIClient