import { configureStore } from '@reduxjs/toolkit'
import accountsSlice from './Redux/accounts-slice'
import companySlice from './Redux/company-slice'
import usersSlice from './Redux/users-slice'
import tradesSlice from './Redux/trades-slice'
import notificationsSlice from './Redux/notifications-slice'
import statisticsSlice from './Redux/statistics-slice'

export const store = configureStore({
  reducer: {
    users: usersSlice.reducer,
    company: companySlice.reducer,
    accounts: accountsSlice.reducer,
    trades: tradesSlice.reducer,
    notifications: notificationsSlice.reducer,
    statistics: statisticsSlice.reducer
  },
})