import { useEffect, useState } from "react";
import DropDownIcon from "../../icons/DropDownIcon";
import HelpCenterBreadCrumb from "./components/HelpCenterBreadCrumb";
import classes from "./OpenTickets.module.css";
import { env } from "../../constants/env";
import { getCookie } from "../../utilities/functions";
import InquiryCard from "./components/InquiryCard";
import { Helmet } from "react-helmet";

const inquiryMessages = [
  {
    date: "Jan 30, 02:04 PM PST",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin" +
    "non est sed enim viverra posuere. Phasellus convallis quam non" +
    "orci sagittis consequat at eu ante. Proin iaculis lacinia" +
    "sagittis. Nullam laoreet dui mattis, dapibus erat in," +
    "ullamcorper sem. Donec quis justo sed tortor auctor vestibulum" +
    "quis fermentum enim. Ut neque massa, tempor at purus quis," +
    "imperdiet convallis ipsum. Ut porttitor, ex vitae molestie."
  },
  {
    date: "Jan 30, 09:01 PM PST",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin non est sed enim viverra posuere. Phasellus convallis quam non orci sagittis consequat at eu ante. "
  }
];

const OpenTickets = ({ themeMode }) => {

  const [conversations, setConversatios] = useState([])

  const userId = getCookie("userId");

  useEffect(() => {
    getInquries()
  }, [])

  const  getInquries = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/conversation/user-conversations/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      
      setConversatios(result)

      console.log(`User data ${userId}}`, result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${classes.wrapper} container`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Open Tickets | Prime Bridge Portal</title>
        <link rel="canonical" href="https://portal.primebridge.io/dashboard/help/open-tickets" />
      </Helmet>
      <HelpCenterBreadCrumb
        mainText="Open Tickets"
        root="/dashboard/help"
        rootText="Help Center"
      />
      <div className={classes.helpText}>
        Continue chatting about a previously open inquiry
      </div>
      <div className={classes.cardsWrapper}>
      {conversations.sort(
          (a, b) =>
            new Date(b.createdAt) - new Date(a.createdAt)
        ).map((item, i) => (<InquiryCard key={i} item={item} conversations={conversations} solvedIssue={(result) => result && getInquries()}/>))}
      </div>
    </div>
  );
};

export default OpenTickets;
