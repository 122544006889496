import { Box, Center } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import { usePageContext } from "../../../contexts/page.context";
import InfoIcon from "../../../components/Info.icon";
import { Icon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from "uuid";

const Tip = () => {
  const [id] = useState(uuidv4());
  const [hover, setHover] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const { lightMode } = usePageContext();

  useEffect(() => {
    getTipDimensions();
    return () => {};
  }, []);

  const getTipDimensions = () => {
    // var top = $(`#${id}`).position().top;
    // var left = $(`#${id}`).position().left;
    var top = document.getElementById(id).clientTop;
    var left = document.getElementById(id).clientLeft;
    setTop(top - 13);
    setLeft(left - 68);
  };

  return (
    <Box
      id={id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position={"relative"}
    >
      <Box id="icon" cursor={"pointer"}>
        <Icon display={"inline-block"} as={InfoIcon} />
      </Box>

      <Center
        id="info"
        _before={{
          content: '""',
          borderStyle: "solid",
          borderWidth: "10px 7px 0px 7px",
          borderColor: `${
            lightMode ? "#f9f9f9" : "#343C40"
          } transparent  transparent transparent`,
          position: "absolute",
          left: "68px",
          bottom: "-9px",
        }}
        zIndex={"400"}
        fontSize={"12px"}
        lineHeight={"16px"}
        letterSpacing={"1.3px"}
        textAlign={"center"}
        position={"absolute"}
        py={"10px"}
        backgroundColor={lightMode ? "#f9f9f9" : "#343C40"}
        px={"14px"}
        borderRadius={"6px"}
        transform={"translate(0, -100%)"}
        top={`${top}px`}
        left={`${left}px`}
        color={lightMode ? "#000" : "#fff"}
        display={hover ? "block" : "none"}
        width={"380px"}
      >
        To confirm the transfer, insert the code that was sent to the admin’s
        email. Check Spam if you can't find it on the inbox.
      </Center>
    </Box>
  );
};

export default Tip;
