import { Box, Center } from "@chakra-ui/layout";
import React, { useEffect, useRef, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";

const ActionTip = ({ children, icon, type }) => {
  const [id] = useState(uuidv4());
  const [hover, setHover] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const { lightMode } = usePageContext();

  const getTipDimensions = () => {
    var top = $(`#${id}`).position().top;
    var height = $(`#${id} #icon`).height();
    var width = $(`#${id} #icon`).width();
    // var iconsLeft = $(`#${id}`).parents("#icons").position();
    var iconsLeft = document
      .getElementById(id)
      ?.parentElement.getBoundingClientRect().left;
    setTop(top + height + 13);
    if (type === "Transfer") {
      setLeft(iconsLeft + width / 2);
    } else if (type === "Send") {
      setLeft(iconsLeft + width / 2);
    }
  };

  return (
    <Box
      id={id}
      onMouseEnter={() => {
        getTipDimensions();
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      position={"relative"}
    >
      <Box id="icon" cursor={"pointer"}>
        {icon}
      </Box>

      <Center
        id="info"
        _before={{
          content: '""',
          borderStyle: "solid",
          borderWidth: "0px 7px 10px 7px",
          borderColor: `transparent transparent ${
            lightMode ? "#f9f9f9" : "#343C40"
          } transparent `,
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, 0)",
          top: "-10px",
        }}
        zIndex={"300"}
        fontSize={"14px"}
        lineHeight={"16px"}
        letterSpacing={"1.3px"}
        textAlign={"center"}
        position={"fixed"}
        py={"10px"}
        transform={"translate(-50%, 0%)"}
        backgroundColor={lightMode ? "#f9f9f9" : "#343C40"}
        px={"14px"}
        borderRadius={"6px"}
        top={`${top}px`}
        left={`${left}px`}
        color={lightMode ? "#000" : "#fff"}
        display={hover ? "block" : "none"}
        width={"fit-content"}
      >
        {children}
      </Center>
    </Box>
  );
};

export default ActionTip;
