import classes from "./Footer.module.css"

const textLinksData = [
    {
        name: "Legal & Privacy",
        link: "https://www.primebridge.io/privacy"
    },
    {
        name: "Risk Disclosure",
        link: "https://www.primebridge.io/disclosure"
    }
]

const Footer = () => {
    return (
        <div className="main-divs">
            <div className={`${classes.wrapper} d-flex align-items-center`}>
                {textLinksData.map((item, i) => (<div key={i} className={classes.linkText}><a href={item.link} alt="">{item.name}</a></div>))}
                <div className={classes.copyRight}>@ 2023 Prime Bridge</div>
            </div>
        </div>

    )
}

export default Footer