const ManageIcon = ({themeColor}) => {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.6552 14.25H22.1934C21.7209 14.25 21.2679 14.0623 20.9338 13.7283C20.5998 13.3942 20.4121 12.9412 20.4121 12.4687C20.4121 11.9963 20.5998 11.5433 20.9338 11.2092C21.2679 10.8752 21.7209 10.6875 22.1934 10.6875H51.6552C52.1276 10.6875 52.5807 10.4998 52.9148 10.1658C53.2488 9.83173 53.4365 9.37867 53.4365 8.90625C53.4365 8.43383 53.2488 7.98077 52.9148 7.64672C52.5807 7.31267 52.1276 7.125 51.6552 7.125H22.1934C21.7209 7.125 21.2679 6.93733 20.9338 6.60328C20.5998 6.26923 20.4121 5.81617 20.4121 5.34375C20.4121 4.87133 20.5998 4.41827 20.9338 4.08422C21.2679 3.75017 21.7209 3.5625 22.1934 3.5625H51.6552C53.0725 3.5625 54.4317 4.1255 55.4338 5.12765C56.436 6.1298 56.999 7.489 56.999 8.90625C56.999 10.3235 56.436 11.6827 55.4338 12.6849C54.4317 13.687 53.0725 14.25 51.6552 14.25Z"
        fill={themeColor}
      />
      <path
        d="M9.86812 14.25H5.34375C3.9265 14.25 2.5673 13.687 1.56515 12.6849C0.563001 11.6827 0 10.3235 0 8.90625C0 7.489 0.563001 6.1298 1.56515 5.12765C2.5673 4.1255 3.9265 3.5625 5.34375 3.5625H9.86812C10.3405 3.5625 10.7936 3.75017 11.1277 4.08422C11.4617 4.41827 11.6494 4.87133 11.6494 5.34375C11.6494 5.81617 11.4617 6.26923 11.1277 6.60328C10.7936 6.93733 10.3405 7.125 9.86812 7.125H5.34375C4.87133 7.125 4.41826 7.31267 4.08422 7.64672C3.75017 7.98077 3.5625 8.43383 3.5625 8.90625C3.5625 9.37867 3.75017 9.83173 4.08422 10.1658C4.41826 10.4998 4.87133 10.6875 5.34375 10.6875H9.86812C10.3405 10.6875 10.7936 10.8752 11.1277 11.2092C11.4617 11.5433 11.6494 11.9963 11.6494 12.4687C11.6494 12.9412 11.4617 13.3942 11.1277 13.7283C10.7936 14.0623 10.3405 14.25 9.86812 14.25Z"
        fill={themeColor}
      />
      <path
        d="M16.0313 17.8125C14.2698 17.8125 12.5478 17.2902 11.0832 16.3115C9.61858 15.3329 8.47704 13.9419 7.80295 12.3145C7.12886 10.6871 6.95249 8.89637 7.29614 7.16873C7.63978 5.44109 8.48802 3.85415 9.73358 2.60858C10.9791 1.36302 12.5661 0.514785 14.2937 0.171135C16.0214 -0.172514 17.8121 0.00385894 19.4395 0.677952C21.0669 1.35204 22.4579 2.49358 23.4365 3.95821C24.4152 5.42283 24.9375 7.14476 24.9375 8.90625C24.9375 10.0758 24.7071 11.234 24.2596 12.3145C23.812 13.3951 23.1559 14.3769 22.3289 15.2039C21.5019 16.0309 20.5201 16.687 19.4395 17.1346C18.359 17.5821 17.2008 17.8125 16.0313 17.8125ZM16.0313 3.5625C14.9744 3.5625 13.9412 3.87591 13.0624 4.46309C12.1836 5.05027 11.4987 5.88485 11.0943 6.86129C10.6898 7.83773 10.584 8.91218 10.7902 9.94877C10.9964 10.9854 11.5053 11.9375 12.2527 12.6849C13 13.4322 13.9522 13.9411 14.9887 14.1473C16.0253 14.3535 17.0998 14.2477 18.0762 13.8432C19.0527 13.4388 19.8872 12.7539 20.4744 11.8751C21.0616 10.9963 21.375 9.96315 21.375 8.90625C21.375 7.489 20.812 6.1298 19.8099 5.12765C18.8077 4.1255 17.4485 3.5625 16.0313 3.5625Z"
        fill="#D0D0D0"
      />
      <path
        d="M51.6552 53.4375H32.8809C32.4084 53.4375 31.9554 53.2498 31.6213 52.9158C31.2873 52.5817 31.0996 52.1287 31.0996 51.6562C31.0996 51.1838 31.2873 50.7308 31.6213 50.3967C31.9554 50.0627 32.4084 49.875 32.8809 49.875H51.6552C52.1276 49.875 52.5807 49.6873 52.9148 49.3533C53.2488 49.0192 53.4365 48.5662 53.4365 48.0937C53.4365 47.6213 53.2488 47.1683 52.9148 46.8342C52.5807 46.5002 52.1276 46.3125 51.6552 46.3125H32.8809C32.4084 46.3125 31.9554 46.1248 31.6213 45.7908C31.2873 45.4567 31.0996 45.0037 31.0996 44.5312C31.0996 44.0588 31.2873 43.6058 31.6213 43.2717C31.9554 42.9377 32.4084 42.75 32.8809 42.75H51.6552C53.0725 42.75 54.4317 43.313 55.4338 44.3151C56.436 45.3173 56.999 46.6765 56.999 48.0937C56.999 49.511 56.436 50.8702 55.4338 51.8724C54.4317 52.8745 53.0725 53.4375 51.6552 53.4375Z"
        fill={themeColor}
      />
      <path
        d="M20.5556 53.4375H5.34375C3.9265 53.4375 2.5673 52.8745 1.56515 51.8724C0.563001 50.8702 0 49.511 0 48.0937C0 46.6765 0.563001 45.3173 1.56515 44.3151C2.5673 43.313 3.9265 42.75 5.34375 42.75H20.5556C21.028 42.75 21.4811 42.9377 21.8152 43.2717C22.1492 43.6058 22.3369 44.0588 22.3369 44.5312C22.3369 45.0037 22.1492 45.4567 21.8152 45.7908C21.4811 46.1248 21.028 46.3125 20.5556 46.3125H5.34375C4.87133 46.3125 4.41826 46.5002 4.08422 46.8342C3.75017 47.1683 3.5625 47.6213 3.5625 48.0937C3.5625 48.5662 3.75017 49.0192 4.08422 49.3533C4.41826 49.6873 4.87133 49.875 5.34375 49.875H20.5556C21.028 49.875 21.4811 50.0627 21.8152 50.3967C22.1492 50.7308 22.3369 51.1838 22.3369 51.6562C22.3369 52.1287 22.1492 52.5817 21.8152 52.9158C21.4811 53.2498 21.028 53.4375 20.5556 53.4375Z"
        fill={themeColor}
      />
      <path
        d="M26.7188 57C24.9573 57 23.2353 56.4777 21.7707 55.499C20.3061 54.5204 19.1645 53.1294 18.4905 51.502C17.8164 49.8746 17.64 48.0839 17.9836 46.3562C18.3273 44.6286 19.1755 43.0416 20.4211 41.7961C21.6666 40.5505 23.2536 39.7023 24.9812 39.3586C26.7089 39.015 28.4996 39.1914 30.127 39.8655C31.7544 40.5395 33.1454 41.6811 34.124 43.1457C35.1027 44.6103 35.625 46.3323 35.625 48.0938C35.625 50.4558 34.6867 52.7212 33.0164 54.3914C31.3462 56.0617 29.0808 57 26.7188 57ZM26.7188 42.75C25.6619 42.75 24.6287 43.0634 23.7499 43.6506C22.8712 44.2378 22.1862 45.0723 21.7818 46.0488C21.3773 47.0252 21.2715 48.0997 21.4777 49.1363C21.6839 50.1729 22.1928 51.125 22.9402 51.8724C23.6875 52.6197 24.6397 53.1286 25.6762 53.3348C26.7128 53.541 27.7873 53.4352 28.7637 53.0307C29.7402 52.6263 30.5747 51.9414 31.1619 51.0626C31.7491 50.1838 32.0625 49.1506 32.0625 48.0938C32.0625 46.6765 31.4995 45.3173 30.4974 44.3152C29.4952 43.313 28.136 42.75 26.7188 42.75Z"
        fill="#D0D0D0"
      />
      <path
        d="M51.6552 33.8437H47.1309C46.6584 33.8437 46.2054 33.6561 45.8713 33.322C45.5373 32.988 45.3496 32.5349 45.3496 32.0625C45.3496 31.5901 45.5373 31.137 45.8713 30.803C46.2054 30.4689 46.6584 30.2812 47.1309 30.2812H51.6552C52.1277 30.2812 52.5807 30.0936 52.9148 29.7595C53.2488 29.4255 53.4365 28.9724 53.4365 28.5C53.4365 28.0276 53.2488 27.5745 52.9148 27.2405C52.5807 26.9064 52.1277 26.7187 51.6552 26.7187H47.1309C46.6584 26.7187 46.2054 26.5311 45.8713 26.197C45.5373 25.863 45.3496 25.4099 45.3496 24.9375C45.3496 24.4651 45.5373 24.012 45.8713 23.678C46.2054 23.3439 46.6584 23.1562 47.1309 23.1562H51.6552C53.0725 23.1562 54.4317 23.7192 55.4338 24.7214C56.436 25.7235 56.999 27.0827 56.999 28.5C56.999 29.9173 56.436 31.2765 55.4338 32.2786C54.4317 33.2807 53.0725 33.8437 51.6552 33.8437Z"
        fill={themeColor}
      />
      <path
        d="M26.7187 33.8437H5.34375C3.9265 33.8437 2.5673 33.2807 1.56515 32.2786C0.563001 31.2765 0 29.9173 0 28.5C0 27.0827 0.563001 25.7235 1.56515 24.7214C2.5673 23.7192 3.9265 23.1563 5.34375 23.1562H34.8056C35.278 23.1562 35.7311 23.3439 36.0652 23.678C36.3992 24.012 36.5869 24.4651 36.5869 24.9375C36.5869 25.4099 36.3992 25.863 36.0652 26.197C35.7311 26.5311 35.278 26.7187 34.8056 26.7187H5.34375C4.87133 26.7187 4.41826 26.9064 4.08422 27.2405C3.75017 27.5745 3.5625 28.0276 3.5625 28.5C3.5625 28.9724 3.75017 29.4255 4.08422 29.7595C4.41826 30.0936 4.87133 30.2812 5.34375 30.2812H26.7187C27.1912 30.2812 27.6442 30.4689 27.9783 30.803C28.3123 31.137 28.5 31.5901 28.5 32.0625C28.5 32.5349 28.3123 32.988 27.9783 33.322C27.6442 33.6561 27.1912 33.8437 26.7187 33.8437Z"
        fill={themeColor}
      />
      <path
        d="M40.9688 37.4063C39.2073 37.4063 37.4853 36.8839 36.0207 35.9053C34.5561 34.9267 33.4145 33.5357 32.7405 31.9083C32.0664 30.2809 31.89 28.4901 32.2336 26.7625C32.5773 25.0348 33.4255 23.4479 34.6711 22.2023C35.9166 20.9568 37.5036 20.1085 39.2312 19.7649C40.9589 19.4212 42.7496 19.5976 44.377 20.2717C46.0044 20.9458 47.3954 22.0873 48.374 23.552C49.3527 25.0166 49.875 26.7385 49.875 28.5C49.875 30.8621 48.9367 33.1274 47.2664 34.7977C45.5962 36.4679 43.3308 37.4063 40.9688 37.4063ZM40.9688 23.1563C39.9119 23.1563 38.8787 23.4697 37.9999 24.0568C37.1212 24.644 36.4362 25.4786 36.0318 26.455C35.6273 27.4315 35.5215 28.5059 35.7277 29.5425C35.9339 30.5791 36.4428 31.5313 37.1902 32.2786C37.9375 33.0259 38.8897 33.5349 39.9262 33.7411C40.9628 33.9473 42.0373 33.8414 43.0137 33.437C43.9902 33.0325 44.8247 32.3476 45.4119 31.4688C45.9991 30.5901 46.3125 29.5569 46.3125 28.5C46.3125 27.0828 45.7495 25.7235 44.7474 24.7214C43.7452 23.7193 42.386 23.1563 40.9688 23.1563Z"
        fill="#D0D0D0"
      />
    </svg>
  );
};
export default ManageIcon;
