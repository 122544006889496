import { Icon } from "@chakra-ui/icons";
import { Box, Center } from "@chakra-ui/layout";
import React, { useState } from "react";
import { usePageContext } from "../../../../contexts/page.context";
import InfoIcon from "../../../../components/Info.icon";

const Tip = () => {
  const [hover, setHover] = useState(false);
  const { lightMode } = usePageContext();
  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position={"relative"}
    >
      <Box id="info" cursor={"pointer"}>
        <Icon display={"inline-block"} as={InfoIcon} />
      </Box>

      <Center
        _before={{
          content: '""',
          borderStyle: "solid",
          borderWidth: "10px 7px 0px 7px",
          borderColor: `${
            lightMode ? "#f9f9f9" : "#343C40"
          } transparent  transparent transparent`,
          position: "absolute",
          left: "15px",
          bottom: "-9px",
        }}
        zIndex={"300"}
        fontSize={"12px"}
        lineHeight={"16px"}
        letterSpacing={"1.3px"}
        textAlign={"center"}
        position={"absolute"}
        py={"10px"}
        transform={"translate(0%, -100%)"}
        backgroundColor={lightMode ? "#f9f9f9" : "#343C40"}
        px={"14px"}
        borderRadius={"6px"}
        top={"calc(-100%)"}
        left={"-13px"}
        color={lightMode ? "#000" : "#fff"}
        display={hover ? "block" : "none"}
        width={"310px"}
      >
        10,000 - 99,000 = 20%
        <br />
        100,000 - 999,999 = 10%
        <br />
        1-5M = 5%
        <br />
        5-10M = 2.5%
        <br />
        {">"}10M = 1.25%
      </Center>
    </Box>
  );
};

export default Tip;
