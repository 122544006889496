import React from "react";
import { usePageContext } from "../contexts/page.context";

const CopyIcon = () => {
  const { lightMode } = usePageContext();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
    >
      <path
        d="M8.71362 2.87402H2.00652C1.47434 2.87492 0.964226 3.09875 0.588068 3.49642C0.21191 3.89409 0.00042371 4.43313 0 4.9953V14.3235C0.00042371 14.8857 0.21191 15.4247 0.588068 15.8224C0.964226 16.22 1.47434 16.4439 2.00652 16.4448H8.71362C9.24469 16.4421 9.75316 16.2175 10.1279 15.82C10.5027 15.4225 10.7133 14.8845 10.7137 14.3235V4.9953C10.7133 4.4343 10.5027 3.89628 10.1279 3.49881C9.75316 3.10133 9.24469 2.87671 8.71362 2.87402ZM9.22968 14.3235C9.22968 14.4681 9.17531 14.6067 9.07853 14.709C8.98175 14.8112 8.85049 14.8686 8.71362 14.8686H2.00652C1.86993 14.8682 1.73908 14.8105 1.64265 14.7084C1.54622 14.6062 1.49207 14.4678 1.49207 14.3235V4.9953C1.49207 4.85117 1.54627 4.71294 1.64275 4.61103C1.73923 4.50911 1.87008 4.45186 2.00652 4.45186H8.71362C8.85021 4.45186 8.98123 4.50906 9.07796 4.61092C9.1747 4.71279 9.22925 4.85101 9.22968 4.9953V14.3235Z"
        fill={lightMode ? "black" : "white"}
      />
      <path
        d="M12.9959 0.0166016H6.81086C6.33085 0.0170507 5.87062 0.219327 5.5312 0.579028C5.19178 0.938728 5.00091 1.42646 5.00049 1.93515C5.00937 2.13825 5.09176 2.3299 5.23052 2.4703C5.36929 2.61069 5.55376 2.68903 5.74561 2.68903C5.93746 2.68903 6.12193 2.61069 6.26069 2.4703C6.39946 2.3299 6.48184 2.13825 6.49073 1.93515C6.49073 1.84517 6.52446 1.75888 6.58449 1.69525C6.64453 1.63163 6.72596 1.59588 6.81086 1.59588H12.9959C13.1323 1.59633 13.263 1.65408 13.3593 1.75647C13.4557 1.85885 13.5097 1.99753 13.5097 2.1421V10.9528C13.5097 11.0428 13.476 11.1291 13.416 11.1927C13.3559 11.2564 13.2745 11.2921 13.1896 11.2921H12.8086C12.6108 11.2921 12.4211 11.3754 12.2812 11.5236C12.1413 11.6719 12.0627 11.8729 12.0627 12.0826C12.0627 12.2922 12.1413 12.4933 12.2812 12.6416C12.4211 12.7898 12.6108 12.8731 12.8086 12.8731H13.1912C13.6709 12.8722 14.1308 12.6697 14.4698 12.3101C14.8089 11.9504 14.9996 11.4629 15 10.9545V2.1421C14.9996 1.57882 14.7883 1.0387 14.4126 0.640241C14.0369 0.241778 13.5274 0.0174998 12.9959 0.0166016V0.0166016Z"
        fill={lightMode ? "black" : "white"}
      />
    </svg>
  );
};

export default CopyIcon;
