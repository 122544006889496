import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../../utilities/functions";
import AuthCard from "../../components/Auth/AuthCard";
import AuthWrapper from "../../components/Auth/AuthWrapper";
import LogoContainer from "../../components/Auth/LogoContainer";
import classes from "./AuthPage.module.css";
import { env } from "../../constants/env";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleUser } from "../../Redux/users-actions";
import { fetchSingleCompany } from "../../Redux/company-actions";
import { usersActions } from "../../Redux/users-slice";
import { companyActions } from "../../Redux/company-slice";

const AuthPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [password, setPassword] = useState()
  const [username, setUsername] = useState()
  const [isSending, setIsSending] = useState()
  const [loginError, setLoginError] = useState("")
  const [overview, setOverview] = useState(false)


  const sendLoginCode = async (user, company, route) => {
    let dataContent = { user, company}
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/two-factor`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("SEND CODE ERROR", error);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      setIsSending(false)
      navigate(route)

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };


  const fetchSingleCompany =  async(companyId) => {
    try {
        const response = await fetch(
          `${env.primeBridgeUrl}/api/company/${companyId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("fetching company failed.");
        }
  
        const result = await response.json();

        console.log("SINGLE COMPANY", result)
        setOverview(result.overview)
  
        return result;
  
      } catch (error) {
        console.log(error);
      }
  };

  const updateCompanyStatus = async(id) => {
    const dataContent = {verification: "verified"}
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/update-status/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        console.log("Error from Update company status: ",error?.error)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      if(overview){
        navigate("/dashboard/overview")
      }else{
        navigate("/dashboard")
      }

      

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  }

  const  getDocumentCount = async (id) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/count-verified/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const result = await response.json();
        console.log("COUNT ERRROR", result)
        throw new Error("sending plays failed.");
      }

      let documentCount = await response.json();
      // setDocumentCount(count)
      if(documentCount >= 6){
        updateCompanyStatus(id)
      }else{
        navigate("/verification")
      }

      console.log("COUNT", documentCount);

      return documentCount;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };
  

  const userloginHandler = async () => {
    let dataContent = { email: username?.trim(), password: password}
    const sendRequest = async () => {
      setIsSending(true)
      setLoginError("")
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/portal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        const error = await response.json();
        setLoginError(error?.error)
        setIsSending(false)
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      dispatch(usersActions.setToken({ token: result?.token }));
      setCookie("userId", result?.userId)
      setCookie("isAdmin", result?.isAdmin)

      if(result?.isAdmin){
        getUser(result?.userId, result?.token)
      }else{
        setCookie("companyId", result?.companyId)
        const status = await fetchSingleCompany(result?.companyId)

        console.log("TWO FACTOR STATUS MEMBER", status)

        if(result?.accessLevel?.includes('overview') && overview){
          if(status.twoFactor){
            sendLoginCode(result?.userId, result?.companyId, "two-factor?default-route=dashboard/overview")
          }else{
            setCookie("authToken", result?.token)
            setIsSending(false)
            navigate("/dashboard/overview")
          }
        }else if(result?.accessLevel?.includes('asset-dashboard')){
          if(status.twoFactor){
            sendLoginCode(result?.userId, result?.companyId,"two-factor?default-route=dashboard")
          }else{
            setCookie("authToken", result?.token)
            setIsSending(false)
            navigate("/dashboard")
          }
        }else if(result?.accessLevel?.includes('transactions')){
          if(status.twoFactor){
            sendLoginCode(result?.userId, result?.companyId, "two-factor?default-route=dashboard/transactions")
          }else{
            setCookie("authToken", result?.token)
            setIsSending(false)
            navigate("/dashboard/transactions")
          }
        }else if(result?.accessLevel?.includes('team')){
          if(status.twoFactor){
            sendLoginCode(result?.userId, result?.companyId, "two-factor?default-route=dashboard/team")
          }else{
            setCookie("authToken", result?.token)
            setIsSending(false)
            navigate("/dashboard/team")
          }
        }else if(result?.accessLevel?.includes('help-center')){
          if(status.twoFactor){
            sendLoginCode(result?.userId, result?.companyId, "two-factor?default-route=dashboard/help")
          }else{
            setCookie("authToken", result?.token)
            setIsSending(false)
            navigate("/dashboard/help")
          }
        }
        
      }
      

      console.log("Reply from plays data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false)
      console.log(error);
    }
  };

  const getUser = async (id, token) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/company/user/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json" 
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setCookie("companyId", result._id)

      const status = await fetchSingleCompany(result._id)

      console.log("CUSTODY STATUS ADMIN", status.custodyProvider)

      if(result.verification === "verified"){
        if(status.custodyProvider){
          if(status.twoFactor){
            if(result.overview){
              sendLoginCode(result?.user, result._id,"two-factor?default-route=dashboard/overview")
            }else{
              sendLoginCode(result?.user, result._id,"two-factor?default-route=dashboard")
            }
          }else{
            setCookie("authToken", token)
            if(result.overview){
              navigate("/dashboard/overview")
            }else{
              navigate("/dashboard")
            }
            
          }
        }else{
          setCookie("authToken", token)
          navigate("/custody-provider")
        }
      }else{
        setCookie("authToken", token)
        getDocumentCount(result._id)
      }

      setIsSending(false)
      console.log("company data", result);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };   
  return (
    <AuthWrapper>
      <h3>Login</h3>
        <input placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)}/>
        <input placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)}/>
        {!loginError && <h6>By logging in, you agree to the <a href="https://www.primebridge.io/terms-of-use">Terms of Use</a></h6>}
        {loginError && <h6 style={{color: "#BD3437"}}>{loginError}</h6>}
        {!isSending && (<button onClick={userloginHandler}>
            Enter
        </button>)}

        {isSending && (
          <div className="spinner-border" role="status">
            {/* <span class="sr-only">Loading...</span> */}
            
          </div>
        )}
        <div className={classes.forgotPasswordText} role="button" onClick={() => navigate("/forgot-password")}>Forgot Password?</div>
    </AuthWrapper>
  );
};
export default AuthPage;
