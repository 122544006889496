import React from "react";
import { usePageContext } from "../contexts/page.context";

const DownIcon = () => {
  const { currentTheme } = usePageContext();
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 11L6 15M6 15L1 11M6 15V1"
        stroke={currentTheme === "light" ? "black" : "white"}
        stroke-width="2"
        stroke-linecap="round"
      />
      <rect y="18" width="12" height="2" rx="1" fill="black" />
    </svg>
  );
};

export default DownIcon;
