import { useQuery } from "@tanstack/react-query"
import ms from "ms"
import { getCookie } from "../../../../../utilities/functions"
import PBAPIClient from '../../../../../services/prime-client'

const useSavedAddresses = (sendtype) => {
    const userId = getCookie("userId")
    const authToken = getCookie("authToken")
    const companyId = getCookie("companyId")
    const apiClient = new PBAPIClient(`/transaction/saved-addresses/${sendtype}/${companyId}/${userId}`, authToken)

    return useQuery({
        queryKey: ["saved addresses", sendtype],
        queryFn: () => apiClient.get(),
        retry: false,
        enabled: !!sendtype
    })
}

export default useSavedAddresses