import { HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import { Menu, MenuButton, MenuItem, MenuList } from "../../../components/menu";
import { Text } from "../../../components/text";
import { useAddFundsContext } from "./../addfunds.context";
import NextButton from "./../../../components/NextButton";
import useAddFundsStore from "../store";
import { MethodOptions } from "../constants";
import { getCookie } from "../../../../../utilities/functions";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import useWalletAccounts from "../../accounts/hooks/useWalletAccounts";

const InitialStep = () => {
  const { lightMode } = usePageContext();
  const [account, setAccount] = useState();
  const [method, setMethod] = useState("");
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const nextPage = useAddFundsStore((s) => s.nextPage);
  const saveFirstDetails = useAddFundsStore((s) => s.saveFirstDetails);
  const companyId = getCookie("companyId");
  const { data: tradingAccounts } = useTradingAccounts(companyId);
  const { data: walletAccounts } = useWalletAccounts();

  console.log("FOR ADD ACCOUTT", account)

  const handleSubmit = () => {
    saveFirstDetails({
      method,
      account,
    });
    nextPage();
  };

  useEffect(() => {
    if (!!account && !!method.length) completePage();
    else inCompletePage();
    return () => {};
  }, [account, method]);

  return (
    <HStack
      width={"100%"}
      justifyContent={"space-between"}
      gap={"53px"}
      align={"flex-end"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"600"}>
          Account
        </Text>
        <Menu
          value={
            !!account
              ? `${account?.accountName?.split(" - ")[0]} - ${
                  account?.accountNumber
                }`
              : ""
          }
          width={"100%"}
          px={"20px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          py={"12px"}
          onChange={(str) => {
            const acc = walletAccounts.accounts?.find(
              (x) =>
                x.accountName.split(" - ")[0] === str.split(" - ")[0] &&
                x.accountNumber == str.split(" - ")[1]
            );
            setAccount(acc);
          }}
        >
          <MenuButton placeholder={"Select account"} />
          <MenuList>
            {walletAccounts?.accounts?.map((x, i) => (
              <MenuItem key={i}>
                {`${x.accountName.split(" - ")[0].split(" -")[0]} - ${
                  x.accountNumber
                }`}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"600"}>
          Method
        </Text>
        <Menu
          width={"100%"}
          px={"20px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          py={"12px"}
          onChange={(str) => {
            setMethod(str);
          }}
          value={method}
        >
          <MenuButton placeholder={"Select method"} />
          <MenuList>
            {MethodOptions?.map((x, i) => (
              <MenuItem key={i}>{x}</MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
      <NextButton active={!!account && !!method} onSubmit={handleSubmit} />
    </HStack>
  );
};

export default InitialStep;
