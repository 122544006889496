const LogoutIcon = () =>{
    return(
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.317 1C7.95972 1 6.04874 2.90279 6.04874 5.25C6.04874 5.71944 6.43093 6.1 6.9024 6.1C7.37386 6.1 7.75606 5.71944 7.75606 5.25C7.75606 3.84167 8.90265 2.7 10.317 2.7H13.7317C15.1461 2.7 16.2927 3.84167 16.2927 5.25V13.75C16.2927 15.1583 15.1461 16.3 13.7317 16.3H10.317C8.90265 16.3 7.75606 15.1583 7.75606 13.75C7.75606 13.2806 7.37386 12.9 6.9024 12.9C6.43093 12.9 6.04874 13.2806 6.04874 13.75C6.04874 16.0972 7.95972 18 10.317 18H13.7317C16.089 18 18 16.0972 18 13.75V5.25C18 2.90279 16.089 1 13.7317 1H10.317Z" fill="#BD3437"/>
        <path d="M11.1707 8.65C11.6422 8.65 12.0244 9.03056 12.0244 9.5C12.0244 9.96944 11.6422 10.35 11.1707 10.35V8.65Z" fill="#BD3437"/>
        <path d="M4.10061 8.65C4.17661 8.5567 4.24942 8.46615 4.31829 8.37966C4.52786 8.11649 4.70528 7.88535 4.83056 7.71967C4.89326 7.63675 4.94305 7.57004 4.97743 7.52368L5.01718 7.46988L5.02784 7.45536L5.03161 7.45021C5.03164 7.45016 5.03204 7.44962 4.34141 6.95L5.03161 7.45021C5.30873 7.07042 5.22461 6.53827 4.84318 6.26233C4.46178 5.98642 3.92796 6.07059 3.65083 6.45032L3.64842 6.45362L3.63968 6.46552L3.60403 6.51377C3.57249 6.55629 3.52587 6.61878 3.46662 6.69713C3.34802 6.85397 3.17944 7.07361 2.98047 7.32346C2.57819 7.82864 2.06869 8.43589 1.60363 8.89896L1 9.5L1.60363 10.101C2.06869 10.5641 2.57819 11.1714 2.98047 11.6765C3.17944 11.9264 3.34802 12.146 3.46662 12.3029C3.52587 12.3812 3.57249 12.4437 3.60403 12.4862L3.63968 12.5345L3.64842 12.5464L3.65041 12.5491C3.92755 12.9288 4.46178 13.0136 4.84318 12.7377C5.22461 12.4617 5.30916 11.9302 5.03204 11.5504L4.34141 12.05C5.03204 11.5504 5.03208 11.5504 5.03204 11.5504L5.02784 11.5446L5.01718 11.5301L4.97743 11.4763C4.94305 11.43 4.89326 11.3632 4.83056 11.2803C4.70528 11.1146 4.52786 10.8835 4.31829 10.6203C4.24942 10.5338 4.17661 10.4433 4.10061 10.35H11.1707V8.65H4.10061Z" fill="#BD3437"/>
        <path d="M11.1707 8.65C11.6422 8.65 12.0244 9.03056 12.0244 9.5C12.0244 9.96944 11.6422 10.35 11.1707 10.35M11.1707 8.65V10.35M11.1707 8.65H4.10061C4.17661 8.5567 4.24942 8.46615 4.31829 8.37966C4.52786 8.11649 4.70528 7.88535 4.83057 7.71967C4.89326 7.63675 4.94305 7.57004 4.97743 7.52368L5.01718 7.46988L5.02784 7.45536L5.03161 7.45021M11.1707 10.35H4.10061C4.17661 10.4433 4.24942 10.5338 4.31829 10.6203C4.52786 10.8835 4.70528 11.1147 4.83057 11.2803C4.89326 11.3632 4.94305 11.43 4.97743 11.4763L5.01718 11.5301L5.02784 11.5446L5.03204 11.5504M5.03161 7.45021C5.03164 7.45016 5.03204 7.44962 4.34141 6.95L5.03161 7.45021ZM5.03161 7.45021C5.30873 7.07042 5.22461 6.53827 4.84318 6.26233C4.46178 5.98642 3.92796 6.07059 3.65083 6.45032L3.64842 6.45362L3.63968 6.46552L3.60403 6.51377C3.57249 6.55629 3.52587 6.61878 3.46662 6.69713C3.34802 6.85397 3.17944 7.07361 2.98047 7.32346C2.57819 7.82864 2.06869 8.43589 1.60363 8.89896L1 9.5L1.60363 10.101C2.06869 10.5641 2.57819 11.1714 2.98047 11.6765C3.17944 11.9264 3.34802 12.146 3.46662 12.3029C3.52587 12.3812 3.57249 12.4437 3.60403 12.4862L3.63968 12.5345L3.64842 12.5464L3.65041 12.5491C3.92755 12.9288 4.46178 13.0136 4.84318 12.7377C5.22461 12.4617 5.30916 11.9302 5.03204 11.5504M5.03204 11.5504L4.34141 12.05C5.03204 11.5504 5.03208 11.5504 5.03204 11.5504ZM6.04874 5.25C6.04874 2.90279 7.95972 1 10.317 1H13.7317C16.089 1 18 2.90279 18 5.25V13.75C18 16.0972 16.089 18 13.7317 18H10.317C7.95972 18 6.04874 16.0972 6.04874 13.75C6.04874 13.2806 6.43093 12.9 6.9024 12.9C7.37386 12.9 7.75606 13.2806 7.75606 13.75C7.75606 15.1583 8.90265 16.3 10.317 16.3H13.7317C15.1461 16.3 16.2927 15.1583 16.2927 13.75V5.25C16.2927 3.84167 15.1461 2.7 13.7317 2.7H10.317C8.90265 2.7 7.75606 3.84167 7.75606 5.25C7.75606 5.71944 7.37386 6.1 6.9024 6.1C6.43093 6.1 6.04874 5.71944 6.04874 5.25Z" stroke="#BD3437" stroke-width="0.5" stroke-linecap="round"/>
        </svg>
    )
}
export default LogoutIcon