import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React from 'react'
import { toast } from 'react-hot-toast'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'
import { usePageContext } from "../../../contexts/page.context";
import InfoIcon from '../../../components/Info.icon'
import { DestOptions } from '../constants'
import useSendFundsStore from '../store'

const useVerifyCode = () => {
    const { lightMode } = usePageContext()
    const companyId = getCookie("companyId");
    const authToken = getCookie("authToken");
    const destination = useSendFundsStore(s => s.firstDetails.destination)
    const apiClient = new PBAPIClient(`/transaction/verify-code/${companyId}`, authToken)

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")
            toast.error(error.response?.status === 500 ?
                "Network Error" : "Code is invalid or expired.", {
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        } else
            throw error
    }

    return useMutation({
        mutationFn: (code) => apiClient.post({
            verificationCode: code,
            paymentMethod: destination === DestOptions[1] ? "crypto" : "wire-transfer"
        }),
        onMutate() {
            toast.loading("Verifying code.", {
                icon: <InfoIcon />,
                id: "loading",
                duration: 1000,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onSuccess() {

        },
        onError(error) {
            showError(error)
        }
    })
}

export default useVerifyCode