import { useState } from "react";
import InfoIcon from "../../../../icons/InfoIcon";
import styles from "./StatsList.module.css";

const StatsList = ({ title, num, firstRow, style, underline, descText }) => {
  const [showFeePopup, setShowFeePopup] = useState(false)
  return (
    <div
      className={`d-flex justify-content-between ${styles.innerStats}  ${firstRow && "mt-0"}`}
    >
      {showFeePopup && <div className={`${styles.infoPopup}`} onMouseOver={() => setShowFeePopup(true)} onMouseOut={() => setShowFeePopup(false)}>{descText}</div>}

      <div className={`${styles.statsText} d-flex align-items-center`} ><span style={{ borderBottom: underline && `1px dashed #000000`, marginRight: '10px' }}>{title}</span> {underline && <InfoIcon size="16" onMouseOver={() => setShowFeePopup(true)} onMouseOut={() => setShowFeePopup(false)} />}</div>

      <div className={styles.numText}>{num}</div>
    </div>
  );
};

export default StatsList;
