import { Box } from "@chakra-ui/react";
import React from "react";
import { PRIMARY_COLOR } from "../../../../constants/colors";
import { usePageContext } from "../../contexts/page.context";

const Progress = ({ value }) => {
  const { lightMode } = usePageContext();
  return (
    <Box
      position={"relative"}
      w={"87px"}
      h={"7px"}
      borderRadius={"15px"}
      bg={lightMode ? "#f5f5f5" : "#01293E"}
    >
      <Box
        borderRadius={"15px"}
        w={value * 100 + "%"}
        height={"100%"}
        bg={PRIMARY_COLOR}
      ></Box>
    </Box>
  );
};

export default Progress;
