const ChatIcon = ({ active, themeColor }) => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 44 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{flex: "none"}}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 12.2082C4 8.12606 7.13514 4.97839 10.8079 4.97839H32.8276C36.5004 4.97839 39.6355 8.12606 39.6355 12.2082V24.3566C39.6355 28.4387 36.5004 31.5863 32.8276 31.5863H18.735H18.0149L17.46 32.0454L12.8079 35.8946V33.5863V31.5863H10.8079C9.60251 31.5863 8.45504 31.2473 7.4573 30.6474L4 22.0988V12.2082ZM4 22.1116V22.0988L0 12.2082C0 6.09534 4.75171 0.978394 10.8079 0.978394H32.8276C38.8838 0.978394 43.6355 6.09534 43.6355 12.2082V24.3566C43.6355 30.4694 38.8838 35.5863 32.8276 35.5863H19.4551L12.4395 41.391L11.193 39.8844L7.4573 30.6474C7.45303 30.6449 7.44876 30.6423 7.44449 30.6397L4 22.1116ZM7.44449 30.6397C5.40904 29.4099 4 27.0932 4 24.3566V22.1116L0 12.2082V24.3566C0 29.778 3.73756 34.416 8.80789 35.3959V39.669C8.80789 41.3645 10.8471 42.7085 12.4395 41.391L11.193 39.8844L11.1645 39.8501L7.44449 30.6397ZM15.2118 19.1317C15.2118 20.4061 14.226 21.4393 13.0098 21.4393C11.7937 21.4393 10.8079 20.4061 10.8079 19.1317C10.8079 17.8572 11.7937 16.824 13.0098 16.824C14.226 16.824 15.2118 17.8572 15.2118 19.1317ZM24.0197 19.1317C24.0197 20.4061 23.0338 21.4393 21.8177 21.4393C20.6016 21.4393 19.6158 20.4061 19.6158 19.1317C19.6158 17.8572 20.6016 16.824 21.8177 16.824C23.0338 16.824 24.0197 17.8572 24.0197 19.1317ZM30.6256 21.4393C31.8417 21.4393 32.8276 20.4061 32.8276 19.1317C32.8276 17.8572 31.8417 16.824 30.6256 16.824C29.4095 16.824 28.4236 17.8572 28.4236 19.1317C28.4236 20.4061 29.4095 21.4393 30.6256 21.4393Z"
        fill={active ? "#0077B5" : themeColor ? themeColor : "#000000"}
      />
    </svg>
  );
};
export default ChatIcon;
