import React from 'react'

const WithdrawalIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" viewBox="0 0 20 33" fill="none">
            <path d="M2 11L10 3M10 3L18 11M10 3L10 31" stroke="#0077B5" stroke-width="4" stroke-linecap="round" />
        </svg>
    )
}

export default WithdrawalIcon