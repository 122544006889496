import React from 'react';

const DetailsHeader = () => {
    return(
        <>
        <tr>
            <th>Account name</th>
            <th>Account number</th>
            <th>Balance</th>
            <th>Status</th>
        </tr>
        </>
    )
}

export default DetailsHeader