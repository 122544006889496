const IdentityIcon = () =>{
    return(
        <svg width="34" height="25" viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 8.6C9.06407 8.6 7.90001 9.76406 7.90001 11.2C7.90001 12.6359 9.06407 13.8 10.5 13.8C11.936 13.8 13.1 12.6359 13.1 11.2C13.1 9.76406 11.936 8.6 10.5 8.6ZM9.20001 11.2C9.20001 10.482 9.78204 9.9 10.5 9.9C11.218 9.9 11.8 10.482 11.8 11.2C11.8 11.918 11.218 12.5 10.5 12.5C9.78204 12.5 9.20001 11.918 9.20001 11.2Z" fill="#0077B5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2633 16.9229C14.0763 18.2006 12.3815 19 10.5 19C6.91015 19 4 16.0899 4 12.5C4 8.91015 6.91015 6 10.5 6C14.0899 6 17 8.91015 17 12.5C17 14.1663 16.373 15.6862 15.342 16.8366C15.3473 16.8578 15.2582 16.8516 15.2633 16.873V16.9229ZM5.3 12.5C5.3 9.62812 7.62812 7.3 10.5 7.3C13.3719 7.3 15.7 9.62812 15.7 12.5C15.7 13.6383 15.3343 14.6911 14.7139 15.5474C14.7112 15.5442 14.7084 15.5409 14.7057 15.5377C14.3933 15.171 14.0097 14.9281 13.5899 14.7686C12.7884 14.4642 11.7988 14.45 10.825 14.45H10.175C9.21021 14.45 8.28131 14.4616 7.54549 14.7796C7.06954 14.9853 6.70305 15.3013 6.42939 15.7361C5.72244 14.848 5.3 13.7233 5.3 12.5ZM7.40011 16.6754C8.26572 17.3191 9.33838 17.7 10.5 17.7C11.7586 17.7 12.9128 17.2528 13.8124 16.5087C13.7812 16.4618 13.749 16.4194 13.7161 16.3808C13.5681 16.2071 13.3788 16.079 13.1283 15.9839C12.5893 15.7792 11.8491 15.75 10.825 15.75H10.175C9.14198 15.75 8.50346 15.7818 8.06126 15.9729C7.80308 16.0845 7.57641 16.2679 7.40011 16.6754Z" fill="#0077B5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 8.6C9.06407 8.6 7.90001 9.76406 7.90001 11.2C7.90001 12.6359 9.06407 13.8 10.5 13.8C11.936 13.8 13.1 12.6359 13.1 11.2C13.1 9.76406 11.936 8.6 10.5 8.6ZM9.20001 11.2C9.20001 10.482 9.78204 9.9 10.5 9.9C11.218 9.9 11.8 10.482 11.8 11.2C11.8 11.918 11.218 12.5 10.5 12.5C9.78204 12.5 9.20001 11.918 9.20001 11.2Z" stroke="#0077B5" stroke-width="0.3"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2633 16.9229C14.0763 18.2006 12.3815 19 10.5 19C6.91015 19 4 16.0899 4 12.5C4 8.91015 6.91015 6 10.5 6C14.0899 6 17 8.91015 17 12.5C17 14.1663 16.373 15.6862 15.342 16.8366C15.3473 16.8578 15.2582 16.8516 15.2633 16.873V16.9229ZM5.3 12.5C5.3 9.62812 7.62812 7.3 10.5 7.3C13.3719 7.3 15.7 9.62812 15.7 12.5C15.7 13.6383 15.3343 14.6911 14.7139 15.5474C14.7112 15.5442 14.7084 15.5409 14.7057 15.5377C14.3933 15.171 14.0097 14.9281 13.5899 14.7686C12.7884 14.4642 11.7988 14.45 10.825 14.45H10.175C9.21021 14.45 8.28131 14.4616 7.54549 14.7796C7.06954 14.9853 6.70305 15.3013 6.42939 15.7361C5.72244 14.848 5.3 13.7233 5.3 12.5ZM7.40011 16.6754C8.26572 17.3191 9.33838 17.7 10.5 17.7C11.7586 17.7 12.9128 17.2528 13.8124 16.5087C13.7812 16.4618 13.749 16.4194 13.7161 16.3808C13.5681 16.2071 13.3788 16.079 13.1283 15.9839C12.5893 15.7792 11.8491 15.75 10.825 15.75H10.175C9.14198 15.75 8.50346 15.7818 8.06126 15.9729C7.80308 16.0845 7.57641 16.2679 7.40011 16.6754Z" stroke="#0077B5" stroke-width="0.3"/>
        <rect x="1.4" y="1.4" width="31.2" height="22.2" rx="2.6" stroke="#0077B5" stroke-width="2.8"/>
        <rect x="19" y="8" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="8" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="8" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="12" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="12" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="12" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="16" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="16" width="10" height="2" rx="1" fill="#0077B5"/>
        <rect x="19" y="16" width="10" height="2" rx="1" fill="#0077B5"/>
        </svg>
    )
}

export default IdentityIcon