import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { toast } from 'react-hot-toast'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'
import { usePageContext } from "../../../contexts/page.context";
import useTradingAccounts from '../../accounts/hooks/useTradingAccounts'
import InfoIcon from '../../../components/Info.icon';
import SuccessIcon from '../../../icons/Success.icon';
import { AxiosError } from 'axios'
import useSendFundsStore from '../store'
import RejectIcon from '../../../icons/Reject.icon'

const useTransfer = () => {
    const queryClient = useQueryClient()
    const { lightMode } = usePageContext()
    const userId = getCookie("userId")
    const authToken = getCookie("authToken");
    const companyId = getCookie("companyId")
    const { data: accounts } = useTradingAccounts(companyId)
    const apiClient = new PBAPIClient('/transaction', authToken)
    const code = useSendFundsStore(s => s.secondDetails.code)
    const amount = useSendFundsStore(s => s.secondDetails.amount)
    const source = useSendFundsStore(s => s.firstDetails.source)
    const cashAccount = accounts.find(x => x.cashAccount)
    const accountId = source?._id

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")

            toast.error(error.response?.status === 500 ?
                "Network Error" : error.response?.data?.error, {
                position: "top-right",
                icon: <RejectIcon />,
                style: {
                    boxShadow: "none",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        } else
            throw error
    }

    return useMutation({
        mutationFn: () => apiClient.post({
            paymentMethod: "cash-account",
            transactionType: "send-funds",
            company: companyId,
            account: accountId,
            currency: "usd",
            sourceAccount: {
                accountName: source?.accountName,
                accountNumber: source?.credentials?.accountNumber
            },
            destinationAccount: {
                accountName: cashAccount?.accountName,
                accountNumber: cashAccount?.credentials?.accountNumber
            },
            totalWithdrawal: parseFloat(amount),
            user: userId,
            verificationCode: code
        }),
        onMutate() {
            toast.loading("Sending request.", {
                icon: <InfoIcon />,
                id: "loading",
                duration: 1000,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onSuccess() {
            toast.success("Your transfer request has been submitted!", {
                icon: <SuccessIcon />,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    boxShadow: "none",
                    color: lightMode ? "black" : "white",
                    borderRadius: "8px",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
            queryClient.refetchQueries(["history"])
        },
        onError(error) {
            showError(error)
        }
    })
}

export default useTransfer