import classes from './PercentButton.module.css'

const PercentButton = ({number, active, onClick}) => {
    return(
        <div onClick={onClick} className={`${classes.wrapper} ${active && classes.active}`} role='button'>
            {number}
        </div>
    )
}

export default PercentButton