import React from "react";
import { usePageContext } from "../contexts/page.context";

const CheckIcon = () => {
  const { lightMode } = usePageContext();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 19 19"
      fill="none"
    >
      <g clipPath="url(#clip0_324_3330)">
        <path
          d="M6.13478 16.3584C5.59544 16.3586 5.0782 16.1442 4.69716 15.7626L0.350756 11.4178C-0.116919 10.95 -0.116919 10.1916 0.350756 9.72378C0.818581 9.25611 1.57693 9.25611 2.04475 9.72378L6.13478 13.8138L16.9553 2.99333C17.4231 2.52566 18.1814 2.52566 18.6492 2.99333C19.1169 3.46116 19.1169 4.2195 18.6492 4.68733L7.57239 15.7626C7.19135 16.1442 6.67411 16.3586 6.13478 16.3584Z"
          fill={lightMode ? "white" : "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_324_3330">
          <rect width="19" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
