import { Icon } from "@chakra-ui/icon";
import { Box, Center, HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect } from "react";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "./../../../../../constants/colors";
import { usePageContext } from "../../../contexts/page.context";
import DownloadIcon from "../../../components/Download.icon";
import { Progress } from "../../../components/progress";
import { Text } from "../../../components/text";
import SECTIONS from "../../actions/data/sectionTitle";
import useWalletStore from "../../../store";
import CloseIcon from "./../icons/Close.icon";
import DetailsInfo from "./DetailsInfo";
import InitialStep from "./InitialStep";
import MethodDetails from "./MethodDetails";
import MethodInput from "./MethodInput";
import useAddFundsStore from "../store";

const AddFunds = () => {
  const { lightMode } = usePageContext();
  const currentSection = useWalletStore((s) => s.section);
  const inputSource = useWalletStore((s) => s.inputSource);
  const closeSection = useWalletStore((s) => s.closeSection);
  const page = useAddFundsStore((s) => s.page);
  const pageNumber = useAddFundsStore((s) => s.pageNumber);
  const resetPage = useAddFundsStore((s) => s.resetPage);

  useEffect(() => {
    return () => {
      inputSource("");
      resetPage();
    };
  }, []);

  return currentSection === SECTIONS[1] ? (
    <VStack
      border={`1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`}
      borderRadius={"20px"}
      w={"100%"}
      px={"39px"}
      py={"28px"}
    >
      <HStack width={"100%"} alignItems={"flex-start"} gap={"24px"}>
        <Center
          borderRadius={"50%"}
          w={"60px"}
          h={"60px"}
          backgroundColor={lightMode ? SECONDARY_COLOR : "#01293E"}
        >
          <Icon width={"24px"} height={"24px"} as={DownloadIcon} />
        </Center>
        <VStack flexGrow={1}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Text fontSize={"16px"} fontWeight={"700"}>
              Add Funds
            </Text>

            <HStack gap={"23px"}>
              <HStack gap={"13px"}>
                <Progress value={pageNumber / 3} />
                <Text
                  fontSize={"14px"}
                  color={pageNumber === 3 ? PRIMARY_COLOR : "#7f7f7f"}
                >
                  {pageNumber}/3
                </Text>
              </HStack>
              <Box
                cursor={"pointer"}
                onClick={() => {
                  inputSource("");
                  resetPage();
                  closeSection();
                }}
              >
                <CloseIcon />
              </Box>
            </HStack>
          </HStack>

          {page === 0 && <InitialStep />}
          {page === 1 && <MethodInput />}
          {page === 2 && <DetailsInfo />}
        </VStack>
      </HStack>
      <MethodDetails />
    </VStack>
  ) : null;
};

export default AddFunds;
