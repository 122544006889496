import { useEffect, useState } from "react";
import SearchIcon from "../../icons/SearchIcon";
import classes from "./Notifications.module.css";
import SuccessIcon from "../../icons/SuccessIcon";
import FailedIcon from "../../icons/FailedIcon";
import InvoiceIcon from "../../icons/InvoiceIcon";
import { getCookie } from "../../utilities/functions";
import { io } from "socket.io-client";
import { env } from "../../constants/env";
import { useSelector } from "react-redux";

const tabsData = ["Type", "Date"]
const notificationsdata = [
  {
    title: "Crypto Deposit Completed",
    desc: "The deposit requested on 2023-01-30 has been processed. The funds were added to your account.",
    amount: "6,341.20 USD"
  },
  {
    title: "Wire Transfer Deposit Completed",
    desc: "The deposit requested on 2023-01-30 has been processed. The funds were added to your account.",
    amount: "6,341.20 USD"
  },
  {
    title: "Withdrawal Request Accepted",
    desc: "Your withdrawal requested on 2023-01-30 has been processed. Funds will arrive within 3 business days.",
    amount: "6,341.20 USD"
  },
  {
    title: "Deposit Error",
    desc: "Something when wrong with your deposit requested on 2023-01-30. Details on the Transaction History.",
    amount: "6,341.20 USD"
  },
  {
    title: "Withdrawal Request Declined",
    desc: "Something when wrong with your withdrawal requested on 2023-01-30. Please make another request.",
    amount: "6,341.20 USD"
  },
  {
    title: "Service Fee Invoice Ready",
    desc: "Your latest service fee invoice was created. Go to the Services Fee tab under Transactions to view.",
    amount: "6,341.20 USD"
  }
]

const Notifications = () => {
  const [showFilterCard, setShowFilterCard] = useState();
  const [activeTab, setActiveTab] = useState("Type");
  const [notificationsByDate, setNotificationsByDate] = useState([])
  const [notifications, setNotifications] = useState([])
  const [category, setCategory] = useState("")
  const [searchValue, setSearchValue] = useState("")

  console.log("NOTIFY", notificationsByDate)

  const userId = getCookie('userId')

  const allNotifications = useSelector(state => state.notifications.allNotifications)


  useEffect(() => {
    setNotifications(allNotifications);
    const groupArrays = groupByDate(allNotifications)
    setNotificationsByDate(groupArrays)
  }, [allNotifications])

  useEffect(() => {
    getAllNotifications()
  }, [])

  useEffect(() => {
    var newArray = notifications.filter(function (el) {
      return el.category === category
    });
    const groupArrays = groupByDate(newArray)
    setNotificationsByDate(groupArrays)
  }, [category])


  useEffect(() => {
    var newArray = notifications.filter(function (el) {
      return el.title.toLowerCase().includes(searchValue.toLowerCase())
    });
    const groupArrays = groupByDate(newArray)
    setNotificationsByDate(groupArrays)
  }, [searchValue])


  const groupByDate = (data) => {
    // this gives an object with dates as keys
    const groups = data.reduce((groups, notification) => {
      const date = notification.createdAt.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(notification);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        notifications: groups[date]
      };
    });

    return groupArrays

  }

  const checkDate = (date) => {
    var inputDate = new Date(date);
    var todaysDate = new Date();
    var yesterdaysDate = todaysDate.getDate() - 1;

    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return "Today"
    } else if (inputDate.getDate() === yesterdaysDate) {
      return "Yesterday"
    } else {
      const yyyy = inputDate.getFullYear();
      let mm = inputDate.getMonth() + 1; // Months start at 0!
      let dd = inputDate.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedToday = yyyy + '-' + mm + '-' + dd;
      return formattedToday
    }
  }

  const getAllNotifications = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.notificationsUrl}/notifications/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      setNotifications(result.docs);

      const groupArrays = groupByDate(result.docs)
      console.log("WITH DATE", groupArrays)

      setNotificationsByDate(groupArrays);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-divs">


      <div className={`${classes.wrapper} `}>
        <div className="d-flex justify-content-between align-items-center">
          <h3>Notifications</h3>{" "}
          <div className="d-flex align-items-center gap-3 position-relative">
            <div className={`${classes.searchContainer} d-flex align-items-center `}>
              <SearchIcon />
              <input className="flex-1" placeholder="Search" onChange={(e) => {
                setSearchValue(e.target.value)
              }} />
            </div>{" "}
            <div className={classes.filterButton} role="button" onClick={() => setShowFilterCard(!showFilterCard)}>
              + Filter
            </div>
            {showFilterCard && <div className={classes.filterCard}>
              <div className={`${classes.header} d-flex justify-content-center align-items-center gap-5`}>
                {tabsData.map((item, i) => (<div className={`${classes.filterOption} ${activeTab === item ? classes.activeTab : undefined}`} key={i} onClick={() => setActiveTab(item)} role="button">{item}</div>))}
              </div>
              {activeTab === "Type" && <div>
                <div className={`d-flex align-items-center ${classes.typeText}`}><input
                  className={classes.checkInput}
                  type="checkbox"
                  name="asset"
                  value="deposit"
                  checked={category === "deposit"}
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                />Deposit</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  checked={category === "transfer"}
                  type="checkbox"
                  name="asset"
                  value="transfer"
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                />Transfer</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  checked={category === "withdrawal"}
                  type="checkbox"
                  name="asset"
                  value="withdrawal"
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                />Withdrawal</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  checked={category === "service-fee"}
                  type="checkbox"
                  name="asset"
                  value="service-fee"
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                />Service Fee</div>
              </div>}
              {activeTab === "Date" && <div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  type="checkbox"
                  name="asset"
                  value="asset"
                  onChange={(e) => {
                  }}
                />This Week</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  type="checkbox"
                  name="asset"
                  value="asset"
                  onChange={(e) => {
                  }}
                />This Month</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  type="checkbox"
                  name="asset"
                  value="asset"
                  onChange={(e) => {
                  }}
                />This Year</div>
                <div className={classes.typeText}><input
                  className={classes.checkInput}
                  type="checkbox"
                  name="asset"
                  value="asset"
                  onChange={(e) => {
                  }}
                />Previous</div>
              </div>}
            </div>}
          </div>
        </div>
        {notificationsByDate.map((item, index) => (<div className="mt-4" key={index}>
          <h4>{checkDate(item?.date)}</h4>
          {item?.notifications.map((notification, i) => (<div className={classes.notificationContainer} key={i}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-4">
                {/* <div>{(i === 3 || i === 4) ?  <FailedIcon /> : i === 5 ? <InvoiceIcon /> : <SuccessIcon />}</div> */}
                <div><img src={notification?.properties?.notifyIcon} /></div>
                <div>
                  <h5>{notification.title}</h5>
                  <p>{notification.message}</p>
                </div>
              </div>
              {notification.type === "weekly-report-issued" ? <a href={notification?.properties?.reportLink} target="_blank" className={classes.reportLink}>Download Report</a> : <div className={classes.amountText}>{`${notification?.properties?.notifyAmount} ${notification?.properties?.notifyCurrency === "euro" ? "EUR" : "USD"}`}</div>}
            </div>
          </div>))}
        </div>))}
      </div>
    </div>
  );
};

export default Notifications;
