import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import React from 'react'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'

const useTradingAccounts = (id) => {
    const authToken = getCookie("authToken")
    const apiClient = new PBAPIClient('/account/company', authToken)

    return useQuery({
        queryKey: ["accounts", id],
        queryFn: () => apiClient.get(id),
        staleTime: ms("1h"),
        enabled: !!id
    })
}

export default useTradingAccounts