import { useState } from "react";
import styles from "./EquityChartComponent.module.css";
import { numberWithCommas } from "../../../helpers/GetDuration";

const Bubble = ({ balance, descText }) => {
  const [showFeePopup, setShowFeePopup] = useState(false);
  return (
    <div className={styles.peakContainer}>
      <div
        className={`${styles.pill} d-flex flex-column justify-content-center align-items-center`}
        onMouseOver={() => setShowFeePopup(true)}
        onMouseOut={() => setShowFeePopup(false)}
        role="button"
      >
        ${numberWithCommas(balance)}
      </div>
      {showFeePopup && <div className={styles.peakText}>{descText}</div>}
    </div>
  );
};

export default Bubble;
