import { HStack, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { usePageContext } from "../../../contexts/page.context";
import { Menu, MenuButton, MenuItem, MenuList } from "../../../components/menu";
import { Text } from "../../../components/text";
import ErrorIcon from "../../../icons/Error.icon";
import NextButton from "./../../../components/NextButton";
import {
  exampleAccs,
  exampleDests,
  useSendfundsContext,
} from "../sendfunds.context";
import useSendFundsStore from "../store";
import { DestOptions } from "./../constants";
import { getCookie } from "../../../../../utilities/functions";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import useOpenTrades from "../hooks/useOpenTrades";
import useWalletStore from "../../../store";

const InitialStep = () => {
  const { lightMode } = usePageContext();
  const [source, setSource] = useState();
  const [destination, setDestination] = useState("");
  const nextPage = useSendFundsStore((s) => s.nextPage);
  const saveFirstDetails = useSendFundsStore((s) => s.saveFirstDetails);
  const completePage = useSendFundsStore((s) => s.completePage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const sourceSet = useWalletStore((s) => s.source);
  const companyId = getCookie("companyId");
  const balance = useWalletStore((s) => s.balance);
  const shouldAddFunds = useWalletStore((s) => s.shouldAddFunds);
  const { data: tradingAccounts } = useTradingAccounts(companyId);
  const { data: openTrades } = useOpenTrades(source?.credentials?.apiId);

  const handleSubmit = () => {
    // if (openTrades?.length) {
    //   handleError();
    // } else {
    saveFirstDetails({
      source: source,
      destination,
    });
    nextPage();
    // }
  };

  useEffect(() => {
    if (shouldAddFunds) {
      setDestination(DestOptions[2]);
    }
    if (!!sourceSet) {
      if (!shouldAddFunds) {
        const src = tradingAccounts?.find((x) => x._id === sourceSet);
        setSource(src);
      }
    }
    return () => {};
  }, [sourceSet]);

  useEffect(() => {
    if (!!source && !!destination) completePage();
    else inCompletePage();
    return () => {};
  }, [source, destination]);

  const handleError = () => {
    toast.error(
      "Please close open trades to complete the withdrawal process.",
      {
        icon: <ErrorIcon />,
        position: "top-right",
        style: {
          width: "573px",
          fontSize: "14px",
          boxShadow: "none",
          color: lightMode ? "black" : "white",
          borderRadius: "8px",
          border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
          backgroundColor: lightMode ? "#f9f9f9" : "#01293E",
        },
      }
    );
  };

  return (
    <HStack
      width={"100%"}
      justifyContent={"space-between"}
      gap={"53px"}
      align={"flex-end"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
        <HStack>
          <Text fontSize={"14px"} fontWeight={"500"}>
            Source Account
          </Text>
        </HStack>
        
        <Menu
          value={
            !!source
              ? source?.cashAccount
                ? "Cash Account"
                : `${source?.accountName.split(" - ")[0]} - ${
                    source?.credentials?.accountNumber
                  }`
              : ""
          }
          width={"100%"}
          px={"20px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          py={"12px"}
          onChange={(str) => {
            if (str === "Cash Account") {
              const acc = tradingAccounts?.find((x) => x.cashAccount);
              setSource(acc);
            } else {
              const acc = tradingAccounts?.find(
                (x) =>
                  x.accountName.split(" - ")[0] === str.split(" - ")[0] &&
                  x.credentials.accountNumber === str.split(" - ")[1]
              );
              setSource(acc);
            }
          }}
        >
          <MenuButton
            disabled={!!sourceSet && !shouldAddFunds}
            placeholder={"Select source"}
            fontWeight={"400"}
          />
          <MenuList>
            {tradingAccounts
              ?.filter((x) => !(shouldAddFunds && x.cashAccount))
              .map((x, i) => (
                <MenuItem key={i}>
                  {x.cashAccount
                    ? `Cash Account`
                    : `${x.accountName.split(" - ")[0]} - ${
                        x.credentials?.accountNumber
                      }`}
                </MenuItem>
              ))}
          </MenuList>
        </Menu>
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Destination
        </Text>
        <Menu
          width={"100%"}
          px={"20px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          py={"12px"}
          onChange={(str) => {
            setDestination(str);
          }}
          value={destination}
        >
          <MenuButton
            disabled={shouldAddFunds}
            placeholder={"Select destination"}
          />
          <MenuList>
            {DestOptions?.filter((x) =>
              source?.cashAccount ? (x === "Cash Account" ? false : true) : true
            ).map((x, i) => (
              <MenuItem key={i}>{x}</MenuItem>
            ))}
          </MenuList>
        </Menu>
      </VStack>
      <NextButton active={!!source && !!destination} onSubmit={handleSubmit} />
    </HStack>
  );
};

export default InitialStep;
