import classes from './CustodyProvider.module.css'

const PickYourCustody = ({onClick}) => {
    return(
        <div className={`${classes.wrapper} d-flex flex-column justify-content-between align-items-center gap-5 w-100`}>
            <img src='https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/lock-icon.svg' alt='Lock icon'/>
            <div>
            <h3>Pick your Custody Provider</h3>
            <p>Before accessing the portal, we require you to choose the custody provider.</p>
            <p>Note this only needs to be done once, upon your first login. To change it later, you will need to contact your sales representative.</p>
            <p>Click continue to see the options.</p>
            </div>
            <button onClick={onClick}>Continue</button>
        </div>
    )
} 

export default PickYourCustody