import { create } from 'zustand'
import $ from 'jquery'
import { useShallow } from 'zustand/react/shallow'
import { MethodOptions } from './constants'

const useAddFundsStore = create((set, get) => ({
    page: 0,
    pageNumber: 0,
    showDetails: false,
    firstDetails: {
        account: null,
        method: ""
    },
    secondDetails: {
        coin: "",
        amount: "",
    },
    toggleDetails: () => {
        $("#toggleDetails").slideToggle()
        $("#toggleHow").slideToggle()
        var $elem = $("#toggleDetailsBtn").find("svg");
        $({ deg: get().showDetails ? 180 : 0 }).animate({ deg: get().showDetails ? 0 : 180 }, {
            duration: 200,
            step: function (now) {
                $elem.css({
                    transform: 'rotate(' + now + 'deg)'
                });
            }
        });
        return set(() => ({
            showDetails: !get().showDetails
        }))
    },
    saveFirstDetails: ({ method, account }) => set(() => ({
        firstDetails: {
            method, account
        }
    })),
    saveCryptoDetails: ({ coin, amount }) => set(() => ({
        secondDetails: {
            amount, coin
        }
    })),
    saveBankDetails: ({ amount }) => set(() => ({
        secondDetails: {
            amount
        }
    })),
    resetPage: () => set(() => ({
        page: 0,
        pageNumber: 0,
        firstDetails: {
            account: null,
            method: ""
        },
        secondDetails: {
            coin: "",
            amount: "",
        }
    })),
    isBankMethod: () => get().firstDetails.method === MethodOptions[0],
    isCryptoMethod: () => get().firstDetails.method === MethodOptions[1],
    isCashMethod: () => get().firstDetails.method === MethodOptions[2],
    nextPage: () => set((state) => ({
        page: state.page + 1
    })),
    completePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page + 1 ? state.pageNumber : state.page + 1
    })),
    inCompletePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page ? state.pageNumber - 1 : state.page
    }))
}))

export default useAddFundsStore;