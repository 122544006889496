import React, { Fragment } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Logo from './Logo';
import { PRIMARY_COLOR } from '../../../../../constants/colors';
import { numberWithCommas } from '../../../../../utilities/functions';
import useTradingAccounts from '../../accounts/hooks/useTradingAccounts';
import months from '../../../../../constants/months';
import moment from 'moment';
const primaryColor = "#04486A"
// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#ffffff'
    },
    header: {
        paddingVertical: 30,
        paddingHorizontal: 55,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: primaryColor
    },
    logo: {
        alignItems: "center",
        gap: 8,
        flexDirection: "row"
    },
    date: {
        fontSize: 12,
        color: "white",
        fontWeight: "normal"
    },
    logoText: {
        fontSize: 16,
        color: "#fff"
    },
    headerText: {
        fontSize: 20,
        color: "white",
        fontWeight: "600"
    },
    headerCol: {
        gap: 4,
        flexDirection: "column",
        alignItems: "flex-start"
    },
    body: {
        paddingVertical: 30,
        paddingHorizontal: 55,
    },
    thead: {
        display: "flex",
        width: "100%",
        gap: 8,
        flexDirection: "row",
        // backgroundColor: "#f3f3f3",
        paddingBottom: 6,
        paddingHorizontal: 8
    },
    th: {
        flexGrow: 1,
        width: "25%",
        fontSize: 10,
        color: primaryColor
    },
    row: {
        paddingVertical: 8,
        paddingHorizontal: 8,
        gap: 4,
        display: "flex",
        width: "100%",
        flexDirection: "row"
    },
    td: {
        width: "25%",
        fontSize: 10,
        flexGrow: 1,
        color: "black"
    }
});

// Create Document Component
const DownloadPage = ({ companyName, history, accounts }) => {
    const filterDate = (date) => {
        const day = moment(date).date() < 10 ? "0" + moment(date).date() : moment(date).date()
        return day + " " + months[moment(date).month()] + "/" + moment(date).year()
    }
    const dateRange = !!history?.length ? `${moment(history[history?.length - 1].createdAt).format("DD/MM/YY")} - ${moment(history[0].createdAt).format("DD/MM/YY")}` : ""

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
                <View style={styles.headerCol}>
                    <Text style={styles.headerText}>
                        Transaction History
                    </Text>
                    <Text style={styles.date}>
                        {companyName} | {dateRange}
                    </Text>
                </View>
                <View style={styles.logo}>
                    <Logo />
                    <Text style={styles.logoText}>
                        Prime Bridge
                    </Text>
                </View>
            </View>
            <View style={styles.body}>
                <View style={styles.thead}>
                    <Text style={styles.th}>
                        Date
                    </Text>
                    <Text style={styles.th}>
                        Type and Method{"\n"}
                        Source Account
                    </Text>
                    <Text style={styles.th}>
                        Destination
                    </Text>
                    <Text style={[styles.th, {
                        textAlign: "right"
                    }]}>
                        Total Amount
                    </Text>
                </View>
                {history.map((item, i) => {
                    const method = item.paymentMethod === "wire-transfer" ? "Bank Transfer" :
                        item.paymentMethod === "crypto" ? "Crypto" :
                            "Cash Account"

                    const type = item.transactionType === "add-funds" ? `Deposit (${method})` : item.transactionType === "send-funds" ? item.paymentMethod === "internal-transfer" ? "Transfer" : "Withdrawal" : ""
                    const acc = accounts?.find(x => item.account === x._id)

                    const source = item.transactionType === "add-funds" ? "" :
                        item.transactionType === "send-funds" ? item.paymentMethod === "internal-transfer" ? `(${item?.sourceAccount?.accountName} - ${item?.sourceAccount?.accountNumber})` :
                            `(${acc?.accountName} - ${acc?.credentials?.accountNumber})`
                            : ""

                    const destination = item.transactionType === "add-funds" ?
                        `${acc?.accountName} - ${acc?.credentials?.accountNumber}`
                        : item.transactionType === "send-funds" ? item.paymentMethod === "internal-transfer" ? item?.destinationAccount?.accountName + " - " + item?.destinationAccount?.accountNumber :
                            method : ""

                    return <Fragment key={i}>
                        <View style={[styles.row, {
                            backgroundColor: "#f3f3f3"
                        }]}>
                            <Text style={[styles.td, {
                                fontWeight: 600
                            }]}>
                                {filterDate(item.createdAt)}
                            </Text>
                            <Text style={styles.td}>
                                {type}
                            </Text>
                            <Text style={styles.td}>
                                {destination}
                            </Text>
                            <Text style={[styles.td, {
                                textAlign: "right"
                            }]}>
                                US${numberWithCommas(parseFloat(item.totalWithdrawal))}
                            </Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={[styles.td, {
                                fontWeight: 600
                            }]}>
                            </Text>
                            <Text style={styles.td}>
                                {source}
                            </Text>
                            <Text style={styles.td}>

                            </Text>
                            <View style={{
                                textAlign: "right",
                                width: "auto",
                                minWidth: "25%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end"
                                // paddingRight: 16
                            }}>
                                <Text style={{
                                    fontSize: 10
                                }}>
                                    {item.transactionType === "add-funds" ? `Deposited Amount: US$${numberWithCommas(parseFloat(item.netPayout))}` : item.transactionType === `send-funds` ? item.paymentMethod === "internal-transfer" ? "" : `Net Payout: US$${numberWithCommas(parseFloat(item.netPayout))}` : ""}
                                </Text>
                            </View>
                        </View>
                    </Fragment>
                })}
            </View>
        </Page>
    </Document>
};

export default DownloadPage