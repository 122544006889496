const MenuIcon = ({ onClick, color }) => {
  return (
    <svg
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      role="button"
    >
      <line
        x1="1.5"
        y1="1.5"
        x2="24"
        y2="1.5"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="1.5"
        y1="10"
        x2="24"
        y2="10"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="1.5"
        y1="18.5"
        x2="24"
        y2="18.5"
        stroke={color}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default MenuIcon;
