import { Heading, VStack } from "@chakra-ui/layout";
import React from "react";
import { usePageContext } from "../../../contexts/page.context";

import { AddFunds } from "../../addfunds";
import { SendFunds } from "../../sendfunds";
import useWalletStore from "../../../store";
import { TransferFunds } from "../../transferfunds";
import WalletActions from "./WalletActions";

const QuickActions = () => {
  const { currentTheme } = usePageContext();
  const closeSection = useWalletStore((s) => s.closeSection);

  return (
    <VStack w={"100%"} pb={"48px"} alignItems={"flex-start"} gap={"16px"}>
      <Heading
        fontSize={"24px"}
        color={currentTheme === "light" ? "black" : "white"}
        lineHeight={"normal"}
        fontWeight={"bold"}
      >
        Quick actions
      </Heading>
      <AddFunds onClose={closeSection} />
      <SendFunds onClose={closeSection} />
      <TransferFunds onClose={closeSection} />
      <WalletActions />
    </VStack>
  );
};

export default QuickActions;
