import { Route, Routes } from "react-router-dom"
import Article from "./Article"
import CategoriesMain from "./CategoriesMain"
import Category from "./Category"


const Categories = () => {
    return (
        <Routes>
        <Route path="/" element={<CategoriesMain />} />
        <Route path="/category/:id" element={<Category />} />
    </Routes>  
    )
}

export default Categories