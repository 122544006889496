import { env } from "../constants/env";
import { notificationsActions } from "./notifications-slice";


export const fetchAllNotifications =  (userId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.notificationsUrl}/notifications/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("NEW NOTIFICATIONS", result.docs);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(notificationsActions.getNotifications({ allNotifications: data.docs }));
    } catch (error) {
      console.log(error);
    }
  };
};
