"use client";
import { createContext, useContext } from "react";

const PageContext = createContext({
  currentTheme: "",
  lightMode: true,
});

const PageContextProvider = ({ children, themeMode }) => {
  return (
    <PageContext.Provider
      value={{
        currentTheme: themeMode,
        lightMode: themeMode === "light",
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const PageContextCustomer = ({ children }) => {
  return <PageContext.Consumer>{children}</PageContext.Consumer>;
};

export const usePageContext = () => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error("usePageContext must be used within a PageContextProvider");
  }
  return context;
};

export default PageContextProvider;
