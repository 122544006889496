import { create } from 'zustand'
import SECTIONS from './features/actions/data/sectionTitle';

const useWalletStore = create(set => ({
    section: SECTIONS[0],
    source: "",
    balance: 0,
    shouldAddFunds: false,
    cashAccount: "",
    setAddFunds: () => set(() => ({
        shouldAddFunds: true
    })),
    inputBalance: (bal) => set(() => ({
        balance: bal
    })),
    setCashAccount: (acc) => set(() => ({
        cashAccount: acc
    })),
    closeSection: () => set(() => ({
        section: SECTIONS[0],
        shouldAddFunds: false
    })),
    inputSource: (src) => set(() => ({
        source: src
    })),
    changeSection: (newSection) => set(() => ({
        section: newSection
    }))
}))

export default useWalletStore;