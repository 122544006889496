import { VStack } from "@chakra-ui/layout";
import React, { Children, Fragment, useEffect } from "react";
import { usePageContext } from "../../contexts/page.context";
import { useMenuContext } from "./menu.context";

const MenuList = ({ children }) => {
  const { border, bg, width, id, setList, value } = useMenuContext();
  const { currentTheme } = usePageContext();
  const background = !!bg ? bg : currentTheme === "light" ? "white" : "black";
  useEffect(() => {
    setList(Children.toArray(children));
    return () => {};
  }, []);

  return (
    <VStack
      id={id}
      display={"none"}
      w={width || "162px"}
      top={"50%"}
      position={"absolute"}
      bg={background}
      borderRight={border}
      zIndex={"1000"}
      borderLeft={border}
      borderBottom={border}
      overflowX={"hidden"}
      borderRadius={"0px 0px 20px 20px"}
      pt={"20px"}
    >
      <VStack gap={0} overflowY={"auto"} w={"100%"} maxH={"200px"}>
        {Children.toArray(children)
          .filter((x) => x != value)
          .map((child, index) => (
            <Fragment key={index}>{child}</Fragment>
          ))}
      </VStack>
    </VStack>
  );
};

export default MenuList;
