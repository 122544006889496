import { env } from "../constants/env";
import { companyActions } from "./company-slice";

export const fetchSingleCompany =  (companyId) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.primeBridgeUrl}/api/company/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("fetching company failed.");
      }

      const result = await response.json();

      console.log("Results get COMPANYYYYYYYYYYYYY redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(companyActions.getCompany({ companyName: data?.companyName }));
      dispatch(companyActions.getRefNumber({ refNumber: data?.refNumber }));
      dispatch(companyActions.getTwoFactorStatus({ twoFactor: data?.twoFactor }));
      dispatch(companyActions.getCustodyProviderStatus({ custodyProvider: data?.custodyProvider }));
      dispatch(companyActions.getDirector({ companyDirector: `${data?.firstName} ${data?.lastName}` }));
      dispatch(companyActions.getDepositSettings({depositSettings: data?.depositSettings}));
      dispatch(companyActions.getWithdrawalSettings({withdrawalSettings: data?.withdrawalSettings}));
      dispatch(companyActions.getPBShareSettings({pbShareSettings: data?.pbShareSettings}));

    } catch (error) {
      console.log(error);
    }
  };
};
