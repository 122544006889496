import React from 'react'
import { getCookie } from '../../../../../utilities/functions';
import PBAPIClient from '../../../../../services/prime-client'
import { usePageContext } from "../../../contexts/page.context";
import useTradingAccounts from '../../accounts/hooks/useTradingAccounts';
import useSendFundsStore from '../store';
import { useSelector } from 'react-redux';
import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { Coins, DestOptions } from '../constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import InfoIcon from '../../../components/Info.icon';
import SuccessIcon from '../../../icons/Success.icon';
import RejectIcon from '../../../icons/Reject.icon';

const useSendFunds = () => {
    const queryClient = useQueryClient()
    const { lightMode } = usePageContext()
    const companyId = getCookie("companyId");
    const authToken = getCookie("authToken");
    const userId = getCookie("userId");
    const apiClient = new PBAPIClient('/transaction', authToken)
    const source = useSendFundsStore(s => s.firstDetails.source)
    const destination = useSendFundsStore(s => s.firstDetails.destination)
    const amount = useSendFundsStore(s => s.secondDetails.amount)
    const code = useSendFundsStore(s => s.secondDetails.code)
    const bankName = useSendFundsStore(s => s.bankDetails.bankName)
    const accountNumber = useSendFundsStore(s => s.bankDetails.accountNumber)
    const accountName = useSendFundsStore(s => s.bankDetails.accountName)
    const address = useSendFundsStore(s => s.bankDetails.address)
    const sortCode = useSendFundsStore(s => s.bankDetails.sortCode)
    const swift = useSendFundsStore(s => s.bankDetails.swift)
    const coin = useSendFundsStore(s => s.cryptoDetails.coin)
    const coinAlias = Coins.find(s => s.title === coin)?.alias
    const walletAddress = useSendFundsStore(s => s.cryptoDetails.walletAddress)
    const pbShareSettings = source?.pbShareSettings;
    const withdrawalSettings = useSelector((state) => state.company.withdrawalSettings);

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")

            toast.error(error.response?.status === 500 ?
                "Network Error" : error.response?.data?.error, {
                position: "top-right",
                icon: <RejectIcon />,
                style: {
                    boxShadow: "none",
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        } else
            throw error
    }

    return useMutation({
        mutationFn: () => apiClient.post({
            paymentMethod: destination === DestOptions[2] ? "cash-account" : destination === DestOptions[1] ? "crypto" : "wire-transfer",
            transactionType: "send-funds",
            company: companyId,
            account: source?._id,
            currency: destination === DestOptions[1] ? coinAlias : "usd",
            withdrawalFee: Math.round(((parseFloat(amount) - (parseFloat(amount) * + pbShareSettings)) * +withdrawalSettings) * 100) / 100,
            pbShare: Math.round((parseFloat(amount) * +pbShareSettings) * 100) / 100,
            netPayout: Math.round((parseFloat(amount) - (parseFloat(amount) * +pbShareSettings) - ((parseFloat(amount) - (parseFloat(amount) * +pbShareSettings)) * +withdrawalSettings)) * 100) / 100,
            totalWithdrawal: parseFloat(amount),
            address: destination === DestOptions[1] ? walletAddress : address,
            user: userId,
            bankName: bankName,
            accountName: accountName,
            accountNumber: accountNumber,
            branch: sortCode,
            swift: swift,
            verificationCode: code,
        }),
        onMutate() {
            toast.loading("Sending request.", {
                icon: <InfoIcon />,
                id: "loading",
                duration: 1000,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onSuccess() {
            toast.success("Your withdrawal request has been submitted!", {
                icon: <SuccessIcon />,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    boxShadow: "none",
                    color: lightMode ? "black" : "white",
                    borderRadius: "8px",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
            queryClient.refetchQueries(["history"])
        },
        onError(error) {
            showError(error)
        }
    })
}

export default useSendFunds