import styles from "./TradesCard.module.css";
import CardContainer from "../cardContainer/CardContainer";

import StatsList from "../statsList/StatsList";
const TradesCard = ({ title, list, loss, summary }) => {
  return (
    <CardContainer>
      <div className={styles.textWrapper}>
        <h1 className={styles.summaryText}>{title}</h1>
        <div
          className={`${loss && "isLoser"} ${styles.underlineText}`}
          style={{ background: loss && "#BD3437", display: summary && "none" }}
        ></div>
      </div>

      {list.map((item, i) => (
        <StatsList
          title={item.title}
          num={item.num}
          firstColumn={true}
          firstRow={i == 0 && true}
        />
      ))}
    </CardContainer>
  );
};

export default TradesCard;
