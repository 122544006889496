import React, { useEffect, useState } from 'react';
import classes from './GroupDetailsRow.module.css'
import { env } from '../../../../../constants/env';
import { nFormatter } from '../../../../../utilities/functions';


const token = "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIzNWM4YmJmZTE4YWFiNjRiZWNiZjFmNjJkNzcxNmUwMCIsInBlcm1pc3Npb25zIjpbXSwiYWNjZXNzUnVsZXMiOlt7ImlkIjoibWV0YWFwaS1yZXN0LWFwaSIsIm1ldGhvZHMiOlsibWV0YWFwaS1hcGk6cmVzdDpwdWJsaWM6KjoqIl0sInJvbGVzIjpbInJlYWRlciIsIndyaXRlciJdLCJyZXNvdXJjZXMiOlsiKjokVVNFUl9JRCQ6KiJdfSx7ImlkIjoibWV0YXN0YXRzLWFwaSIsIm1ldGhvZHMiOlsibWV0YXN0YXRzLWFwaTpyZXN0OnB1YmxpYzoqOioiXSwicm9sZXMiOlsicmVhZGVyIl0sInJlc291cmNlcyI6WyIqOiRVU0VSX0lEJDoqIl19LHsiaWQiOiJyaXNrLW1hbmFnZW1lbnQtYXBpIiwibWV0aG9kcyI6WyJyaXNrLW1hbmFnZW1lbnQtYXBpOnJlc3Q6cHVibGljOio6KiJdLCJyb2xlcyI6WyJyZWFkZXIiLCJ3cml0ZXIiXSwicmVzb3VyY2VzIjpbIio6JFVTRVJfSUQkOioiXX1dLCJ0b2tlbklkIjoiMjAyMTAyMTMiLCJpbXBlcnNvbmF0ZWQiOmZhbHNlLCJyZWFsVXNlcklkIjoiMzVjOGJiZmUxOGFhYjY0YmVjYmYxZjYyZDc3MTZlMDAiLCJpYXQiOjE2OTMyNzg1ODV9.Kp78fWt9e5kKDx2Vs4ETaua8zUAdcpqfHNI4vR2aVjNQKqA6XoF56c3H0dmnqy7zMO2RQ1ssjaQOFhT3n2APrBtw75swiTtV-7p4jX_qBWbhCy7LADZ9QoUngqYlOIZ4F2QdPysT2n1gcJYv6LE5w6b068NygKd2tMKRyprwXe6rzlZp8BzX7w8kGIsy_OSTehQipD-xRc9YdobZSJOv-lb1x-qT1M-L5WU-GoHWHaIrqVOGbpbSgGRmpzKDWfv98mQJ0wU_pU38ankwZnXZuYdSVl5Gi1KXHVed4aax82IyuozTm0LFPVAsviupm1ejmlJyVuZ77mtX9cRxiNgb1SQpVyeJtx5T4TzgU4PY_JLE62BTCDIDkmkVXtPDz6mAvBxgbzThYEUVq_q9f9bvo2gL4dhR-P1FW2ax3ijiEoTVuB79uutzH5RzzR8HR4BQ0SwAeHr8xNwKqorjim736NrvFTd1BssTh6Vc6ZYAYWRZkbb_1aYNorZ9Dkx9HmoXoTdWw4UHydIJ4NHJAjPOKNs8LDlPqnj6sLCQraimuuFe83ZpFFhzWHZ8y1imEOXsyuqKD7CS-ucK5aS8ipffZPnfksrEvT-c8oOM6xe3DI-R95NfXS0rSpaNlCtGv2ZWjZdpLZAcNyKmNNsVBCX9scsBX_d_1SJtDLtH4zXiIAM"


const GroupDetailsRow = ({ data, nopLimit, totalMt5, totalNOP, setIsLoading }) => {

  const [nop, setNop] = useState(0)
  const [account, setAccount] = useState()

  console.log("WHAT IS IN THE DATA", data)

  // useEffect(() => {
  //   getProfits(data?.credentials?.apiId)
  //   fetchOpenTrades(data?.credentials?.apiId)
  // }, [data, totalMt5, totalNOP])


  // const getProfits = async (id) => {
  //   try {
  //     const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();

  //     const newBalance = nFormatter(result.balance)

  //     setBalance(newBalance)

  //     return result;

    
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


 

  // useEffect(() => {
  //   getAccount(data?._id)
  // },[data])


  

  const fetchOpenTrades = async(id) => {
    try{
        const response = await fetch(
          `${env.pbTrackingUrl}/api/statistics/open-trades/${id}`,
          {
            method: "GET",
            headers: {
              "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
            },
          }
        );
  
        if (!response.ok) {
          throw new Error("Fetching Open Trades failed.");
        }
  
        const result = await response.json();
        const newVolume = result.reduce((n, {volume}) => n + volume, 0)
        // const newVolume = result.reduce((n, {volume}) => n + volume, 0)
  
        console.log("OPEN TRADES FROM THE API OVERVIEW  GROUP FROM ROW", totalMt5)

        const lotsPercentage = (newVolume / totalMt5 )

      console.log("PROFIT APIIIIII MT5 GROUP", lotsPercentage );
    
      const newNop = lotsPercentage * totalNOP

      setNop(newNop)

      if(!isNaN(newNop)){
        console.log("THIS IS TRUEEEEEEEEEEEEE")
        setIsLoading(false)
      }

        // console.log("OPEN TRADES FROM THE API OVERVIEW VOLUME  GROUP", newVolume)
  
        return {volume: newVolume, allData: result};

      } catch (error) {
        console.log(error);
      }
  };

  const getNetOpenPosition = async (pair, lots, nopLimit, sell, buy) => {
    try {
      const response = await fetch(`https://mt-client-api-v1.new-york.agiliumtrade.ai/users/current/accounts/80102068-bf41-4e79-88aa-eeb8fa042763/symbols/${(pair === "AUDJPY" || pair === "AUDNZD" || pair === "AUDCAD" ) ? "AUDUSD" : (pair === "CADCHF" || pair === "CADJPY") ? "USDCAD" : pair === "CHFJPY" ? "USDCHF" :  (pair === "EURAUD" || pair === "EURCAD" || pair === "EURCHF" || pair === "EURGBP" || pair === "EURJPY" || pair === "EURNZD") ? "EURUSD" : (pair === "NZDJPY" || pair === "NZDCAD" || pair === "NZDCHF") ? "NZDUSD" : (pair === "GBPAUD" || pair === "GBPCAD" || pair === "GBPCHF"  || pair === "GBPJPY" || pair === "GBPNZD") ? "GBPUSD" : pair}/current-price`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();
      let nop, lotsLimit

      var str = pair.substring(0,3);
      var str1 = pair.substring(0,6);
   
      const volume = Math.abs(buy - sell)

      console.log("BUY", buy)
      console.log("=========================")
      console.log("SELL", sell)
      console.log("=========================")
      console.log("VOLUME", volume)
      console.log("=========================")
      console.log("PAIRS", pair)

      if(str === "USD"){
       nop = volume
      }else if(str1 === "CADCHF" || str1 === "CADJPY" || str1 === "CHFJPY"){
        nop = volume  * (1 / result.bid)
      }else {
        nop = volume * result.bid
      }

      if(str === "USD"){
        lotsLimit = nopLimit / 100000
       }else if(pair === "XAUUSD"){
        lotsLimit = nopLimit / (100 * result.bid)
       }else{
        lotsLimit = nopLimit / (100000 * result.bid)
       }

       console.log("FROM NOP", result)

      return {nop, lotsLimit, result};

    
    } catch (error) {
      console.log(error);
    }
  };


  const getProfits = async (id) => {
    try {
      const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      
      // const lotsPercentage = ((result ? result.lots : 0) / totalMt5 )

      // console.log("PROFIT APIIIIII MT5 GROUP", lotsPercentage * totalNOP);

      // setNop(lotsPercentage * totalNOP)
      return result;

    
    } catch (error) {
      console.log(error);
    }
  };



  
    return (
      <>
      <tr >
        <td>{data?.accountName}</td>
        <td>{data?.accountNumber}</td>
        <td>{data?.accountNop ? nFormatter(data?.accountNop, 2) : 0}</td>
      </tr>
      
        </>
  
    );
  };

  export default GroupDetailsRow