import { Icon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/layout";
import React from "react";
import { usePageContext } from "../../contexts/page.context";
import RightIcon from "../Right.icon";
import { useMenuContext } from "./menu.context";

const MenuButton = ({ placeholder, fontWeight, disabled }) => {
  const { value, toggleMenu, border, px, py, bg, width } = useMenuContext();
  const { currentTheme } = usePageContext();
  const color = !value
    ? currentTheme === "light"
      ? "#7f7f7f"
      : "#d0d0d0"
    : currentTheme === "light"
    ? "black"
    : "white";

  return (
    <HStack
      onClick={() => {
        if (!disabled) toggleMenu();
      }}
      w={width || "182px"}
      bg={bg}
      cursor={"pointer"}
      gap={"13px"}
      px={px}
      py={py}
      border={border || "none"}
      borderRadius={"50px"}
    >
      <HStack
        zIndex={"10001"}
        w={"100%"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Text
          color={color}
          mb={0}
          fontSize={"14px"}
          fontWeight={fontWeight || "400"}
        >
          {!!value ? value : placeholder}
        </Text>
        {!disabled && <Icon width={"4.5px"} height={"auto"} as={RightIcon} />}
      </HStack>
    </HStack>
  );
};

export default MenuButton;
