import styles from "./StatsMobileCard.module.css";
import CardContainer from "../cardContainer/CardContainer";

import StatsList from "../statsList/StatsList";
const StatsMobileCard = ({ title, list, loss, summary }) => {
  return (
    <CardContainer>
      <div className={styles.textWrapper}>
        <h1 className={styles.summaryText}>{title}</h1>
      </div>

      {list.map((item, i) => (
        <StatsList
          title={item.title}
          num={item.num}
          firstColumn={true}
          firstRow={i == 0 && true}
        />
      ))}
    </CardContainer>
  );
};

export default StatsMobileCard;
