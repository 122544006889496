import { useQuery } from '@tanstack/react-query'
import ms from 'ms'
import React from 'react'
import PBAPIClient from '../../../../../services/pbapi-client'

const useOpenTrades = (id) => {
    const apiClient = new PBAPIClient('/statistics/open-trades')

    return useQuery({
        queryKey: ["open trades", id],
        queryFn: () => apiClient.get(id),
        cacheTime: ms("1h"),
        retry: false,
        enabled: !!id
    })
}

export default useOpenTrades