import React from "react";
import { usePageContext } from "../contexts/page.context";

const RightIcon = () => {
  const { currentTheme } = usePageContext();
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6641 2L6.68637 5.97769C6.49111 6.17295 6.17453 6.17295 5.97927 5.97769L2.00158 2"
        stroke={currentTheme === "light" ? "black" : "white"}
        stroke-width="2.4"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default RightIcon;
