import { useEffect, useState } from "react";
import SetupButton from "../UI/SetupButton";
import UploadContainer from "../UI/UploadContainer";
import classes from "./CompanyDocumentsCard.module.css";

const CompanyDocumentsCard = ({ onClick, getDocuments, backToPrev, show }) => {
  const [boardResolution, setBoardResolution] = useState([]);
  const [certificateOfIncorporation, setCertificateOfIncorporation] = useState(
    []
  );
  const [corporateAddress, setCorporateAddress] = useState([]);
  const [articlesOfAssociation, setArticlesOfAssociation] = useState([]);

  useEffect(() => {
    getDocuments([
      boardResolution[0],
      certificateOfIncorporation[0],
      corporateAddress[0],
      articlesOfAssociation[0],
    ]);
  }, [
    boardResolution,
    certificateOfIncorporation,
    corporateAddress,
    articlesOfAssociation,
  ]);

  return (
    <div style={{ display: !show && "none" }} className="w-100">
      <div
        className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`}
      >
        <h2 className={classes.titleText}>
          Upload company
          <br /> verification documents
        </h2>
        <h6 className={classes.subText}>
          Regulations require us to collect documents to verify your company.
        </h6>
        <UploadContainer
          title="Board Resolution to Open Account"
          fileSelected={(file) => {
            setBoardResolution(file);
            console.log("FILESSSS", file);
          }}
        />
        <UploadContainer
          title="Certificate of Incorporation"
          fileSelected={(file) => setCertificateOfIncorporation(file)}
        />
        <UploadContainer
          title="Corporate Address Verification"
          fileSelected={(file) => setCorporateAddress(file)}
        />
        <UploadContainer
          title="Memorandum & Articles of Association"
          fileSelected={(file) => setArticlesOfAssociation(file)}
        />
        <SetupButton
          text="Continue"
          onClick={() => {
            onClick();
          }}
          disabled={
            boardResolution.length === 0 ||
            certificateOfIncorporation.length === 0 ||
            corporateAddress.length === 0 ||
            articlesOfAssociation.length === 0
          }
        />
        <div className={classes.backButton} role="button" onClick={backToPrev}>
          Back
        </div>
      </div>
    </div>
  );
};

export default CompanyDocumentsCard;
