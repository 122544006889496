import CloseIcon from "../../icons/CloseIcon";
import classes from "./NewFeatures.module.css";

const NewFeatures = ({ themeColor, onClick, icon, title, description, subTitle, link}) => {
  return (
    <div className={`${classes.wrapper}`}>
      <div className={`d-flex justify-content-end feature-icon-close-row`}>
        <CloseIcon themeColor={themeColor} onClick={onClick}/>
      </div>
      <div className={`d-flex flex-column justify-content-center align-items-center ${classes.contentArea}`}>
        <div className={`d-flex justify-content-center align-items-center ${classes.iconWrapper}`}>
            <img src={icon} alt=""/>
        </div>
        <h4>{title}</h4>
        <h6>{subTitle}</h6>
        <p dangerouslySetInnerHTML={{__html: description}}></p>
        <button>Learn more</button>
      </div>
    </div>
  );
};

export default NewFeatures;
