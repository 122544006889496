import { Text } from "@chakra-ui/layout";
import React from "react";
import { usePageContext } from "../../contexts/page.context";

const AppText = (props) => {
  const { lightMode } = usePageContext();
  return (
    <Text color={lightMode ? "black" : "white"} {...props}>
      {props.children}
    </Text>
  );
};

export default AppText;
