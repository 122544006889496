const RestrictionIcon = () => {
    return(
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 8.5C17.9389 8.5 18.7 9.26112 18.7 10.2V18.7C18.7 19.6389 17.9389 20.4 17 20.4C16.0611 20.4 15.3 19.6389 15.3 18.7V10.2C15.3 9.26112 16.0611 8.5 17 8.5Z" fill="#0077B5"/>
        <path d="M17 25.5C17.9389 25.5 18.7 24.7389 18.7 23.8C18.7 22.8611 17.9389 22.1 17 22.1C16.0611 22.1 15.3 22.8611 15.3 23.8C15.3 24.7389 16.0611 25.5 17 25.5Z" fill="#0077B5"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0ZM3.4 17C3.4 9.48893 9.48893 3.4 17 3.4C24.5111 3.4 30.6 9.48893 30.6 17C30.6 24.5111 24.5111 30.6 17 30.6C9.48893 30.6 3.4 24.5111 3.4 17Z" fill="#0077B5"/>
        </svg>
    )
}

export default RestrictionIcon