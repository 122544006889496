import React from 'react'
import AmountInput from './AmountInput'
import CryptoInput from './crypto/CryptoInput'
import useSendFundsStore from '../store'
// import CryptoInput from '../../addfunds/ui/crypto/CryptoInput'

const MethodInput = () => {
    const isBankMethod = useSendFundsStore(s => s.isBankMethod)
    const isCryptoMethod = useSendFundsStore(s => s.isCryptoMethod)
    const isCashMethod = useSendFundsStore(s => s.isCashMethod)

    return (
        <>
            {isBankMethod() && <AmountInput />}
            {isCashMethod() && <AmountInput />}
            {isCryptoMethod() && <AmountInput />}
        </>
    )
}

export default MethodInput