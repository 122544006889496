import $ from 'jquery'
import { createContext, useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
const MenuContext = createContext({
    isOpen: false,
    id: "",
    value: "",
    border: undefined,
    px: undefined,
    py: undefined,
    bg: undefined,
    width: undefined,
    list: [],
    setList: (arr) => { },
    toggleMenu: () => { },
    onChange: (str) => { }
})

const MenuContextProvider = ({ children, value, onChange, border, px, py, bg, width, }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [list, setList] = useState([])
    const [id] = useState(uuidv4())

    const toggleMenu = () => {
        $("#" + id).toggle()
        var $elem = $("#" + id).parent().find("svg");
        $({ deg: isOpen ? 180 : 0 }).animate({ deg: isOpen ? 0 : 180 }, {
            duration: 200,
            step: function (now) {
                $elem.css({
                    transform: 'rotate(' + now + 'deg)'
                });
            }
        });
        setIsOpen(!isOpen)
    }
    return (
        <MenuContext.Provider value={{
            isOpen, id, toggleMenu, list, onChange, setList,
            value, border, px, py, bg, width
        }}>
            {children}
        </MenuContext.Provider>
    )
}

export const MenuContextCustomer = ({ children }) => {
    return (
        <MenuContext.Consumer>
            {children}
        </MenuContext.Consumer>
    )
}

export const useMenuContext = () => {
    const context = useContext(MenuContext)
    if (context === undefined) {
        throw new Error('useMenuContext must be used within a MenuContextProvider')
    }
    return context
}

export default MenuContextProvider