import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React from 'react'
import { toast } from 'react-hot-toast'
import PBAPIClient from '../../../../../services/prime-client'
import { getCookie } from '../../../../../utilities/functions'
import { usePageContext } from "../../../contexts/page.context";
import InfoIcon from '../../../components/Info.icon'
import SuccessGreyIcon from '../../../icons/SuccessGrey.icon'
import { DestOptions } from '../constants'
import useSendFundsStore from '../store'

const useSendCode = () => {
    const { lightMode } = usePageContext()
    const userId = getCookie("userId");
    const companyId = getCookie("companyId");
    const authToken = getCookie("authToken");
    const apiClient = new PBAPIClient('/transaction/send-request-code', authToken)

    const showError = (error) => {
        if (error instanceof AxiosError) {
            toast.remove("loading")
            toast.error(error.response?.status === 500 ?
                "Network Error" : error.response?.data, {
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        } else
            throw error
    }

    return useMutation({
        mutationFn: () => apiClient.post({
            company: companyId,
            user: userId,
            method: "internal-transfer"
        }),
        onMutate() {
            toast.loading("Requesting code.", {
                icon: <InfoIcon />,
                id: "loading",
                duration: 1000,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    color: lightMode ? "black" : "white",
                    borderRadius: "4px",
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onSuccess() {
            toast.success("The code has been sent to the admin's email!", {
                icon: <SuccessGreyIcon />,
                position: "top-right",
                style: {
                    fontSize: "14px",
                    width: "583px",
                    boxShadow: "none",
                    color: lightMode ? "black" : "white",
                    borderRadius: "8px",
                    border: `1px solid ${lightMode ? "#e1e1e1" : "#343C40"}`,
                    backgroundColor: lightMode ? "#f9f9f9" : "#01293E"
                }
            })
        },
        onError(error) {
            showError(error)
        }
    })
}

export default useSendCode