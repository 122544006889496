import AuthCard from "./AuthCard";
import LogoContainer from "./LogoContainer";
import classes from "./AuthWrapper.module.css";
import { env } from "../../constants/env";

const AuthWrapper = ({children, cardHeight}) => {
  return (
    
    <div className={`${classes.wrapper}`}>
      
      <div className={`container`}>
        <LogoContainer />
        <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <AuthCard height={{cardHeight}}>
              {children}
            </AuthCard>
            <button className={classes.employeeLoginButton} onClick={() => window.location.assign(env.adminUrl)}>Prime Bridge Employee Login</button>
        </div>
      </div>
      <video className={classes.videoTag} autoPlay loop muted>
    <source src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/skyscrapers-91744.mp4" type='video/mp4' />
</video>
    
    </div>
    
  );
};
export default AuthWrapper;
