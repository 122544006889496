import { Navigate, Route, Routes } from "react-router-dom"
import Funds from "../Wallet/Funds"

import ServicesFees from "./ServicesFees"
import Summaries from "./Summaries"

const Transactions = ({ themeMode }) => {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={"funds"} replace />} />
            <Route path="/funds" element={<Funds themeMode={themeMode} />} />
            <Route path="/services-fees" element={<ServicesFees themeMode={themeMode} />} />
            <Route path="/summaries" element={<Summaries themeMode={themeMode} />} />
        </Routes>
    )
}

export default Transactions