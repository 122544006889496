import styles from "./MonthlyGain.module.css";

const MonthlyGain = ({month, num, isNegative, data, index}) => {
  return (
    <div
    >
      <div className={styles.monthText}>{month}</div>
      {data.map((item, i)=> (<div className={`${styles.percentageText} ${item.data[index]?.percentage < 0 && 'isNegativeNumber'}`} style={{color: item.data[index]?.percentage < 0 && "#BD3437" }}>{item.data[index]?.percentage && (item.data[index]?.monthNum === index) ? (item.data[index]?.percentage < 0 ? item.data[index]?.percentage + "%" : "+" + item.data[index]?.percentage + "%") : <div style={{height: '16px'}}></div>}</div>))}
    </div>
  );
};

export default MonthlyGain;
