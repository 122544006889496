const LockIcon = ({ onClick, themeColor }) => {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      onClick={onClick}
    >
      <path
        d="M9.66113 5.86521H9.26552L9.26396 3.89367C9.26228 1.74583 7.51498 -0.00142915 5.36715 8.77163e-07C3.21943 0.00156498 1.47205 1.74898 1.47348 3.89681L1.47672 5.86847H1.33573C0.597381 5.87004 -0.00168387 6.46908 3.55608e-06 7.20743V13.0774C3.55608e-06 13.8157 0.600613 14.4148 1.33896 14.4148L9.66427 14.41C10.4026 14.41 11.0017 13.811 11 13.0726V7.2012C11 6.46285 10.3994 5.86535 9.66104 5.86535L9.66113 5.86521ZM2.91199 5.86845L2.90875 3.89679C2.90875 2.54016 4.01064 1.4367 5.36727 1.43515C6.72391 1.43515 7.82737 2.53873 7.82737 3.89367L7.83061 5.86533L2.91199 5.86845Z"
        fill={themeColor}
      />
    </svg>
  );
};

export default LockIcon;
