import React, { useEffect, useState } from "react"
import classes from "./DetailsModal.module.css";
import CloseIcon from "../../../icons/CloseIcon";
import DetailsHeader from "../DetailsTable/DetailsHeader";
import DetailsRow from "../DetailsTable/DetailsRow";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { env } from "../../../constants/env";
import { getCookie } from "../../../utilities/functions";

const tableDate = [
  {
    accountName: "Thoendl Investments AG",
    accountNumber: "32800",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "BTP Strategy Solutions",
    accountNumber: "32531",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "BTP Strategy Solutions",
    accountNumber: "32413",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "Adele Kerlin",
    accountNumber: "32700",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "Martin Rowley",
    accountNumber: "100003",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "Martin Rowley #2",
    accountNumber: "400011",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "Xnumia Fx",
    accountNumber: "32810",
    balance: "$10M",
    status: "Active",
  }
]

const DetailsModal = ({closeModal, themeMode }) => {

  const [isLoading, setIsLoading] = useState(true)
  const [accountsData, setAccountsData] = useState([])
  const companyId = getCookie("companyId");


useEffect(() => {
  fetchAllManagementAccounts(companyId)
},[])
  


  const fetchAllManagementAccounts =  async(companyId) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/company/management-accounts/${companyId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsLoading(false)

        const error = await response.json();

        console.log("WHAT IS THE ERROR", error)
        console.log("WHAT IS MY COMPANY ID", companyId)

        throw new Error("Getting accounts failed.");
      }

      const result = await response.json();

      console.log("RESULTS I WANTED", result)

      setAccountsData(result.details)

      setIsLoading(false)

      return result;

    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  };
    return(
       <div
            className={`d-flex justify-content-center align-items-center ${classes.upgradeModal}`}
          >
            <div className={classes.actionCard}>
              <div
                className={`${classes.cardHeader} d-flex justify-content-between align-items-center`}
              >
                <div></div>
                <CloseIcon onClick={closeModal} themeColor={themeMode === "light" ? "#141414" : "#fff"}/>
              </div>
              <div
                className={`d-flex flex-column justify-content-center align-items-center gap-2 ${classes.cardContent}`}
              >
                {isLoading && <div className={`${classes.loadingWrapper} d-flex flex-column align-items-center `}>
      <div className="position-relative" style={{height: "250px"}}><Loader /></div>
      <p>Optimizing assets, please standby</p>
    </div>}
    {!isLoading && <>
                 <div className={classes.tableDiv}>
        <table>
          <DetailsHeader />
          
          {accountsData.map((item, i) => (<DetailsRow data={item} key={i} setIsLoading={(result) => setIsLoading(result)}/>))}
        </table>
      </div>
      </>}
              </div>
             
              <div></div>
              
            </div>
          </div>
    )
}

export default DetailsModal