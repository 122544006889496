export const DestOptions = [
    "External Bank Account",
    "Crypto Wallet",
    "Cash Account"
]

export const Coins = [
    {
        title: "BTC",
        alias: "btc"
    },
    {
        title: "ETH",
        alias: "eth"
    },
    {
        title: "USDT",
        alias: "usdt-eth"
    },
    {
        title: "USDC",
        alias: "usdt-trx"
    },
]