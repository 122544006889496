import { START_DATE, useDatepicker } from '@datepicker-react/hooks'
import React from 'react'

import { createContext, useContext, useEffect, useState } from 'react'

const CalendarContext = createContext({})
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]
const CalendarContextProvider = ({ children }) => {
    const [dateState, setDateState] = useState({
        startDate: null,
        endDate: null,
        focusedInput: START_DATE
    })
    const {
        firstDayOfWeek,
        activeMonths,
        isDateSelected,
        isDateHovered,
        isFirstOrLastSelectedDate,
        isDateBlocked,
        isDateFocused,
        focusedDate,
        onDateHover,
        onDateSelect,
        onDateFocus,
        goToPreviousMonths,
        goToNextMonths
    } = useDatepicker({
        numberOfMonths: 1,
        startDate: dateState?.startDate,
        endDate: dateState?.endDate,
        focusedInput: dateState?.focusedInput,
        onDatesChange: handleDateChange
    })
    const [availabilityShow, setAvailabilityShow] = useState(false)

    function handleDateChange(data) {
        if (!data.focusedInput) {
            setDateState({ ...data, focusedInput: START_DATE })
        } else {
            setDateState(data)
        }
    }

    return (
        <CalendarContext.Provider
            value={{
                availabilityShow,
                setAvailabilityShow,
                activeMonths,
                dateState,
                setDateState,
                handleDateChange,
                firstDayOfWeek,
                focusedDate,
                isDateFocused,
                isDateSelected,
                isDateHovered,
                isDateBlocked,
                isFirstOrLastSelectedDate,
                onDateSelect,
                onDateFocus,
                onDateHover,
                goToPreviousMonths,
                goToNextMonths
            }}
        >
            {children}
        </CalendarContext.Provider>
    )
}

export const useCalendarContext = () => {
    const context = useContext(CalendarContext)
    if (context === undefined) {
        throw new Error(
            'useCalendarContext must be used within a CalendarContextProvider'
        )
    }
    return context
}

export const CalendarContextConsumer = ({ children }) => {
    return (
        <CalendarContext.Consumer>
            {context => {
                if (context === undefined) {
                    throw new Error(
                        'CalendarContextConsumer must be used within a CalendarContextProvider'
                    )
                }
                return children(context)
            }}
        </CalendarContext.Consumer>
    )
}

export default CalendarContextProvider
