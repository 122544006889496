import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { env } from "../../constants/env";
import DropDownIcon from "../../icons/DropDownIcon";
import { getCookie } from "../../utilities/functions";
import SetupButton from "../UI/SetupButton";
import classes from "./YourInfoCard.module.css";
import { countryListAllIsoData } from "../../constants/countrylist";

const useOptions = ["Trading", "Investing", "Capital Growth"];
const sourceOptions = [
  "Occupation",
  "Investments",
  "Savings",
  "Inheritance",
  "Credit/Loan",
  "Family or friends",
  "Others",
];

const YourInfoCard = ({ onClick, formSubmition, show }) => {
  const [countryCode, setCountryCode] = useState("CA");
  const [countryNumberCode, setCountryNumberCode] = useState("");
  const [bridgeUseShow, setBridgeUseShow] = useState(false);
  const [selectedBridgeUse, setSelectedBridgeUse] = useState("");
  const [showSourceOptions, setShowSourceOptions] = useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvice] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  const authToken = getCookie('authToken')
  const userId = getCookie('userId')


  useEffect(() => {
    getUser();
  }, [])

  useEffect(() => {
    var result = countryListAllIsoData.filter(obj => {
      return obj.iso === countryCode
    })

    setCountryNumberCode(result[0].code)
  },[countryCode])


  const getUser = async () => {
    const sendRequest = async () => {
      const response = await fetch(`${env.primeBridgeUrl}/api/auth/user/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}` 
        },
      });

      if (!response.ok) {
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("company data", result);
      setFirstName(result?.firstName)
      setLastName(result?.lastName)
      setEmail(result?.email)


      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      console.log(error);
    }
  };

  

  useEffect(() => {
    formSubmition({
      firstName,
      lastName,
      dateOfBirth,
      companyName,
      email,
      countryNumberCode,
      phoneNumber,
      address,
      city,
      province,
      postalCode,
      country,
      selectedBridgeUse,
      selectedSource,
    });
  }, [
    firstName,
    lastName,
    dateOfBirth,
    companyName,
    email,
    countryNumberCode,
    phoneNumber,
    address,
    city,
    province,
    postalCode,
    country,
    selectedBridgeUse,
    selectedSource,
  ]);

  return (
    <div style={{display: !show && "none"}} className="w-100">
      <div className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`}>
      <h2 className={classes.titleText}>Your Information</h2>
      <div className={classes.formWrapper}>
        <div className={`row`}>
          <div className="col-6">
            <input
              value={firstName}
              placeholder="First name"
              onChange={(e) => setFirstName(e.target.value)}
              style={{color: "rgba(0,0,0,0.5)"}}
            />
          </div>
          <div className="col-6">
            <input
              value={lastName}
              placeholder="Last name"
              onChange={(e) => setLastName(e.target.value)}
              style={{color: "rgba(0,0,0,0.5)"}}
            />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-6">
            <input
              placeholder="Date of Birth (YYYY-MM-DD)"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
          </div>
          <div className="col-6">
            <input
              placeholder="Company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </div>
        <div className={`row`}>
           <div className="col-3">
            <ReactFlagsSelect
              selected={countryCode}
              onSelect={(code) => setCountryCode(code)}
              showSelectedLabel={false}
              fullWidth={true}
              selectedSize={35}
              selectButtonClassName={classes.menu_flags_button}
              className={classes.menu_flags}
              rfsKey="app-lang"
            />
          </div>
          {/* <div className="col-6">
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}
          <div className="col-9">
            <input
              placeholder="Phone number"
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>
        <input placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)}/>
        <div className={`row`}>
          <div className="col-6">
            <input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)}/>
          </div>
          <div className="col-6">
            <input
              placeholder="Province/State"
              value={province}
              onChange={(e) => setProvice(e.target.value)}
            />
          </div>
        </div>
        <div className={`row`}>
          <div className="col-6">
            <input
              placeholder="Postal Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
          <div className="col-6">
            <input
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
        </div>
        <div className={`${classes.optionsWrapper} mb-4`}>
          <div
            className={`${classes.dropDownField} ${
              bridgeUseShow ? classes.bridgeDropDown : undefined
            }`}
            onClick={() => setBridgeUseShow(!bridgeUseShow)}
          >
            <div
              className={`${classes.selectedOption} d-flex justify-content-between align-items-center`}
            >
              {selectedBridgeUse
                ? selectedBridgeUse
                : "What will you use Prime Bridge for?"}{" "}
              <DropDownIcon dropDown={bridgeUseShow} />
            </div>
            {bridgeUseShow &&
              useOptions.map(
                (item) =>
                  item !== selectedBridgeUse && (
                    <div
                      className={classes.dropDownOption}
                      onClick={() => setSelectedBridgeUse(item)}
                    >
                      {item}
                    </div>
                  )
              )}
          </div>
        </div>
        <div
          className={`${classes.optionsWrapper} ${
            showSourceOptions ? classes.sourceDropDown : undefined
          }`}
          onClick={() => setShowSourceOptions(!showSourceOptions)}
        >
          <div
            className={`${classes.dropDownField} ${
              showSourceOptions ? classes.sourceDropDown : undefined
            }`}
          >
            <div
              className={`${classes.selectedOption} d-flex justify-content-between align-items-center`}
            >
              {selectedSource
                ? selectedSource
                : "What is your source of funds?"}{" "}
              <DropDownIcon dropDown={showSourceOptions} />
            </div>
            {showSourceOptions &&
              sourceOptions.map(
                (item) =>
                  item !== selectedSource && (
                    <div
                      className={classes.dropDownOption}
                      onClick={() => setSelectedSource(item)}
                    >
                      {item}
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
      <SetupButton
        text="Continue"
        onClick={onClick}
        disabled={
          dateOfBirth.length === 0 ||
          companyName.length === 0 ||
          phoneNumber.length === 0 ||
          address.length === 0 ||
          city.length === 0 ||
          country.length === 0 ||
          selectedBridgeUse.length === 0 ||
          selectedSource.length === 0
        }
      />
    </div>
    </div>
  );
};
export default YourInfoCard;
