import React, { useEffect, useState } from "react"
import classes from "./GroupDetailsModal.module.css";
import CloseIcon from "../../../icons/CloseIcon";
import DetailsHeader from "../DetailsTable/DetailsHeader";
import DetailsRow from "../DetailsTable/DetailsRow";
import { useDispatch, useSelector } from "react-redux";
import DropDownIcon from "../../../icons/DropDownIcon";
import GroupDetailsHeader from "./components/GroupDetailsTable/GroupDetailsHeader";
import GroupDetailsRow from "./components/GroupDetailsTable/GroupDetailsRow";
import { getCookie, nFormatter } from "../../../utilities/functions";
import { env } from "../../../constants/env";
import { fetchAllLPGroups, fetchAllManagementAccounts, fetchAllOverviewAccounts } from "../../../Redux/accounts-actions";
import Loader from "../../Loader/Loader";

const tableDate = [
  {
    accountName: "Thoendl Investments AG",
    accountNumber: "32800",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "BTP Strategy Solutions",
    accountNumber: "32531",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "BTP Strategy Solutions",
    accountNumber: "32413",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "Adele Kerlin",
    accountNumber: "32700",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "Martin Rowley",
    accountNumber: "100003",
    balance: "$10M",
    status: "Active",
  },
  {
    accountName: "Martin Rowley #2",
    accountNumber: "400011",
    balance: "$10M",
    status: "Failed",
  },
  {
    accountName: "Xnumia Fx",
    accountNumber: "32810",
    balance: "$10M",
    status: "Active",
  }
]

const token = "eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiIzNWM4YmJmZTE4YWFiNjRiZWNiZjFmNjJkNzcxNmUwMCIsInBlcm1pc3Npb25zIjpbXSwiYWNjZXNzUnVsZXMiOlt7ImlkIjoibWV0YWFwaS1yZXN0LWFwaSIsIm1ldGhvZHMiOlsibWV0YWFwaS1hcGk6cmVzdDpwdWJsaWM6KjoqIl0sInJvbGVzIjpbInJlYWRlciIsIndyaXRlciJdLCJyZXNvdXJjZXMiOlsiKjokVVNFUl9JRCQ6KiJdfSx7ImlkIjoibWV0YXN0YXRzLWFwaSIsIm1ldGhvZHMiOlsibWV0YXN0YXRzLWFwaTpyZXN0OnB1YmxpYzoqOioiXSwicm9sZXMiOlsicmVhZGVyIl0sInJlc291cmNlcyI6WyIqOiRVU0VSX0lEJDoqIl19LHsiaWQiOiJyaXNrLW1hbmFnZW1lbnQtYXBpIiwibWV0aG9kcyI6WyJyaXNrLW1hbmFnZW1lbnQtYXBpOnJlc3Q6cHVibGljOio6KiJdLCJyb2xlcyI6WyJyZWFkZXIiLCJ3cml0ZXIiXSwicmVzb3VyY2VzIjpbIio6JFVTRVJfSUQkOioiXX1dLCJ0b2tlbklkIjoiMjAyMTAyMTMiLCJpbXBlcnNvbmF0ZWQiOmZhbHNlLCJyZWFsVXNlcklkIjoiMzVjOGJiZmUxOGFhYjY0YmVjYmYxZjYyZDc3MTZlMDAiLCJpYXQiOjE2OTMyNzg1ODV9.Kp78fWt9e5kKDx2Vs4ETaua8zUAdcpqfHNI4vR2aVjNQKqA6XoF56c3H0dmnqy7zMO2RQ1ssjaQOFhT3n2APrBtw75swiTtV-7p4jX_qBWbhCy7LADZ9QoUngqYlOIZ4F2QdPysT2n1gcJYv6LE5w6b068NygKd2tMKRyprwXe6rzlZp8BzX7w8kGIsy_OSTehQipD-xRc9YdobZSJOv-lb1x-qT1M-L5WU-GoHWHaIrqVOGbpbSgGRmpzKDWfv98mQJ0wU_pU38ankwZnXZuYdSVl5Gi1KXHVed4aax82IyuozTm0LFPVAsviupm1ejmlJyVuZ77mtX9cRxiNgb1SQpVyeJtx5T4TzgU4PY_JLE62BTCDIDkmkVXtPDz6mAvBxgbzThYEUVq_q9f9bvo2gL4dhR-P1FW2ax3ijiEoTVuB79uutzH5RzzR8HR4BQ0SwAeHr8xNwKqorjim736NrvFTd1BssTh6Vc6ZYAYWRZkbb_1aYNorZ9Dkx9HmoXoTdWw4UHydIJ4NHJAjPOKNs8LDlPqnj6sLCQraimuuFe83ZpFFhzWHZ8y1imEOXsyuqKD7CS-ucK5aS8ipffZPnfksrEvT-c8oOM6xe3DI-R95NfXS0rSpaNlCtGv2ZWjZdpLZAcNyKmNNsVBCX9scsBX_d_1SJtDLtH4zXiIAM"

const GroupDetailsModal = ({closeModal, themeMode }) => {

  const dispatch = useDispatch()

  const [selectedOption, setSelectedOption] = useState()
  const [showDropDwon, setShowDropDwon] = useState(false)

  const companyId = getCookie("companyId");


  const [totalNOP, setTotalNOP] = useState(0)
  const [nopCapacity, setNopCapacity] = useState(0)
  const [groupAccounts, setGroupAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const lpGroups = useSelector(state => state.accounts.lpGroups)

  

  useEffect(() => {
    setSelectedOption(lpGroups[0])
  },[])

  useEffect(() => {
    getGroupData(selectedOption?._id)
  },[selectedOption])



  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsTimedOut(true)
  //   }, 120000);
  // },[])

  // useEffect(() => {
    
  //   async function fetchData(){
  //     let groupAccountsArr = []
  //     for (let i = 0; i < selectedOption?.lpAccounts?.length; i++) {
  //       const result = await getAccount(selectedOption?.lpAccounts[i]?._id)
  //       if(result.length > 0){
  //         for (let j = 0; j < result.length; j++) {
  //           groupAccountsArr.push({accountName: result[j].accountName, credentials: result[j].credentials, lpAccount: selectedOption?.lpAccounts[i]})
  //         }
  //       }else{
  //         groupAccountsArr.push({accountName: "", accountNumber: "", lpAccount: selectedOption?.lpAccounts[i]})
  //       }
  //     }

  //     // console.log("GRPUP ACCCCOUNT", groupAccountsArr)

  //     // setGroupAccounts(groupAccountsArr)
  //     getSum(selectedOption?.nopLimit, groupAccountsArr)
  //     getSum2(selectedOption?.nopLimit)

  //   }

  //   fetchData();
  // },[selectedOption])


  



  // const getAccount = async(id) => {
  //   try {
  //     const response = await fetch(`${env.primeBridgeUrl}/api/account/lp-account/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Getting account failed.");
  //     }

  //     const result = await response.json();

  //     console.log("ACCOUNT RESULTS :)", result)

  //     // setAccount(result)
      

  //     // await fetchOpenTrades(result?.credentials?.apiId)

  //     return result;
 
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }



  const getGroupData = async(id) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${env.primeBridgeUrl}/api/account/lp-group-data/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setIsLoading(false)
        throw new Error("Getting account failed.");
      }

      const result = await response.json();

      console.log("GROUP DATAAAA  😱 :)", result)

      // setAccount(result)
      setTotalNOP(result.groupNop)

      setNopCapacity(result.nopCapacity)

      setGroupAccounts(result.tableData)
      // await fetchOpenTrades(result?.credentials?.apiId)

      
      setIsLoading(false)
      return result;
 
    } catch (error) {
      setIsLoading(false)
      console.log(error);
    }
  }




  // const getSum = async(nopLimit, accounts) => {
  //   let sum = 0,
  //   lot = 0, gain = 0, arr1 = [], lastUpdated = "",
  //   mt5TotalLots = 0

    

  //   for (let i = 0; i < accounts.length; i++) {
  //     const result =  await getProfits(accounts[i]?.lpAccount?.credentials?.apiId)
  //     const openLots =  await fetchOpenTrades(accounts[i]?.lpAccount?.credentials?.apiId)
  //     const openLotsMt5 =  await fetchOpenTrades(accounts[i]?.credentials?.apiId)

      

  //     const profits =  await getProfits(accounts[i]?.credentials?.apiId)

  //     console.log(`REACHED SUMMMMMMM :) ${accounts[i]?.credentials?.accountNumber}`, profits)
     
  //     sum += result ? result?.balance : 0
  //     lot += openLots ? openLots.volume : 0
  //     gain += result ? result?.gain : 0

  //     mt5TotalLots +=  openLotsMt5 ? openLotsMt5?.volume : 0

  //     // lastUpdated = openLots.allData[0]?.updateTime ? getDate(openLots.allData[0]?.updateTime) : ""

      
      

  //     // const newData = openLots.allData.map(obj => {
  //     //   const pair = Array.from(obj.symbol)  
  //     //   pair.splice(3, 0, '/')
  //     //   return {...obj, symbol: pair.join('') }
  //     //   } 
  //     // )

  //      arr1 = [...arr1, ...openLots.allData]
  //   }

  //   // var newSum = nFormatter(sum)
  //   // setBalanceSum(newSum)

  //   // console.log("TOTAL HERE :)", lot)

  //   var newLot = nFormatter(lot)
  //   setLotSum(newLot)

  //   // var newGain = nFormatter(gain)
  //   // setGainSum(newGain)

   

   
    
  //   let ans = arr1.reduce((agg,curr) => {
  //     let found = agg.find((x) => x.name === curr.symbol.substring(0,6));

  //     if(found){
  //       found.value = (found.value || 0) + curr.volume
  //       found.sell = (found.sell || 0) +  (curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0)
  //       found.buy = (found.buy || 0) + (curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0)
  //     }
  //     else{
  //        agg.push({
  //         name : curr.symbol.substring(0,6),
  //         pair: curr.symbol,
  //         value : curr.volume,
  //         sell: curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0,
  //         buy: curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0
  //        });
  //     }
  //     return agg;
  //     },[]);
      
  //     console.log("PIE ANSEWER" ,ans.length);

  //     setChartData(ans)

  //     let approximateLots = 0

  //     const total = ans.reduce((n, {value}) => n + value, 0)

  //     var nopTotal = 0
  //     var lotsTotal = 0

      

  //     for (let i = 0; i < ans.length; i++) {

  //       const returnValue = await getNetOpenPosition(ans[i].pair, ans[i].value, nopLimit, ans[i].sell, ans[i].buy)
  //        nopTotal += returnValue.nop

         
  //        const distribution = ans[i].value / total

  //        const nop = distribution * nopLimit 

  //        var str = ans[i].name.substring(0,3);

  //        let lotsLimit
         
  //        if(str === "USD"){
  //         lotsLimit = nop / 100000
  //        }else if(ans[i].name === "XAUUSD"){
  //         lotsLimit = nop / (100 * returnValue.result.bid)
  //        }else{
  //         lotsLimit = nop / (100000 * returnValue.result.bid)
  //        }

  //        approximateLots += lotsLimit
  //       console.log("NOP ", returnValue.result.bid);
  //     }

      
      
  //     // for (let i = 0; i < ans.length; i++) {
  //     //   const nop = await getNetOpenPosition(ans[i].name, ans[i].value, nopLimit)
  //     //    nopTotal += nop.nop
  //     //    lotsTotal += nop.lotsLimit
  //     // }

  //     // setTotalNOP(nFormatter(nopTotal))

  //     console.log("NOP TOTAL => ", approximateLots);

  //     // setTotalNOP(nopTotal)

  //     setTotalLots(nFormatter(approximateLots))

  //     const capacityNop = +nopLimit - +nopTotal

  //     // setNopCapacity(capacityNop)

  //     const capacityLot = +approximateLots - lot

  //     console.log("Total Opened Lots", lot)

  //     setLotCapacity(nFormatter(capacityLot))


  //     setTotalLotsMt5(mt5TotalLots)
      
      

  //     console.log("MT5 TOTAL LOTS", mt5TotalLots)
  // }




  // const getSum2 = async(nopLimit) => {
  //   let sum = 0,
  //   lot = 0, gain = 0, arr1 = [], lastUpdated = ""

  //   console.log("REACHED SUMMMMMMM :)", selectedOption?.lpAccounts);

  //   for (let i = 0; i < selectedOption?.lpAccounts.length; i++) {
  //     const result =  await getProfits(selectedOption?.lpAccounts[i]?.credentials?.apiId)
  //     const openLots =  await fetchOpenTrades(selectedOption?.lpAccounts[i]?.credentials?.apiId)
  //     sum += result ? result?.balance : 0
  //     lot += openLots ? openLots.volume : 0
  //     gain += result ? result?.gain : 0

  //     // lastUpdated = openLots.allData[0]?.updateTime ? getDate(openLots.allData[0]?.updateTime) : ""

      

  //     // const newData = openLots.allData.map(obj => {
  //     //   const pair = Array.from(obj.symbol)  
  //     //   pair.splice(3, 0, '/')
  //     //   return {...obj, symbol: pair.join('') }
  //     //   } 
  //     // )

  //      arr1 = [...arr1, ...openLots.allData]
  //   }

  //   // var newSum = nFormatter(sum)
  //   // setBalanceSum(newSum)

  //   console.log("TOTAL HERE :)", lot)

  //   var newLot = nFormatter(lot)


  //   // var newGain = nFormatter(gain)
  //   // setGainSum(newGain)

   

   
    
  //   let ans = arr1.reduce((agg,curr) => {
  //     let found = agg.find((x) => x.name === curr.symbol.substring(0,6));

  //     if(found){
  //       found.value = (found.value || 0) + curr.volume
  //       found.sell = (found.sell || 0) +  (curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0)
  //       found.buy = (found.buy || 0) + (curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0)
  //     }
  //     else{
  //        agg.push({
  //         name : curr.symbol.substring(0,6),
  //         pair: curr.symbol,
  //         value : curr.volume,
  //         sell: curr.type === "POSITION_TYPE_SELL" ? curr.volume : 0,
  //         buy: curr.type === "POSITION_TYPE_BUY" ? curr.volume : 0
  //        });
  //     }
  //     return agg;
  //     },[]);
      
  //     console.log("PIE ANSEWER" ,ans.length);

  //     setChartData(ans)

  //     let approximateLots = 0

  //     const total = ans.reduce((n, {value}) => n + value, 0)

  //     var nopTotal = 0
  //     var lotsTotal = 0

      

  //     for (let i = 0; i < ans.length; i++) {

  //       const returnValue = await getNetOpenPosition(ans[i].pair, ans[i].value, nopLimit, ans[i].sell, ans[i].buy)
  //        nopTotal += returnValue.nop

         
  //        const distribution = ans[i].value / total

  //        const nop = distribution * nopLimit 

  //        var str = ans[i].name.substring(0,3);

  //        let lotsLimit
         
  //        if(str === "USD"){
  //         lotsLimit = nop / 100000
  //        }else if(ans[i].name === "XAUUSD"){
  //         lotsLimit = nop / (100 * returnValue.result.bid)
  //        }else{
  //         lotsLimit = nop / (100000 * returnValue.result.bid)
  //        }

  //        approximateLots += lotsLimit
  //       console.log("NOP ", returnValue.result.bid);
  //     }

      
      
  //     // for (let i = 0; i < ans.length; i++) {
  //     //   const nop = await getNetOpenPosition(ans[i].name, ans[i].value, nopLimit)
  //     //    nopTotal += nop.nop
  //     //    lotsTotal += nop.lotsLimit
  //     // }

  //     // setTotalNOP(nFormatter(nopTotal))

  //     console.log("NOP TOTAL => ", nopTotal);

  //     // setTotalNOP(nopTotal)


  //     const capacityNop = +nopLimit - +nopTotal

  //     // setNopCapacity(capacityNop)
      
  // }


  // const getNetOpenPosition = async (pair, lots, nopLimit, sell, buy) => {
  //   try {
  //     const response = await fetch(`https://mt-client-api-v1.new-york.agiliumtrade.ai/users/current/accounts/80102068-bf41-4e79-88aa-eeb8fa042763/symbols/${(pair === "AUDJPY" || pair === "AUDNZD" || pair === "AUDCAD" ) ? "AUDUSD" : (pair === "CADCHF" || pair === "CADJPY") ? "USDCAD" : pair === "CHFJPY" ? "USDCHF" :  (pair === "EURAUD" || pair === "EURCAD" || pair === "EURCHF" || pair === "EURGBP" || pair === "EURJPY" || pair === "EURNZD") ? "EURUSD" : (pair === "NZDJPY" || pair === "NZDCAD" || pair === "NZDCHF") ? "NZDUSD" : (pair === "GBPAUD" || pair === "GBPCAD" || pair === "GBPCHF"  || pair === "GBPJPY" || pair === "GBPNZD") ? "GBPUSD" : pair}/current-price`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": token,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     let nop, lotsLimit

  //     var str = pair.substring(0,3);
  //     var str1 = pair.substring(0,6);
   
  //     const volume = Math.abs(buy - sell)

  //     console.log("BUY GROUP", buy)
  //     console.log("=========================")
  //     console.log("SELL GROUP", sell)
  //     console.log("=========================")
  //     console.log("VOLUME GROUP", volume)
  //     console.log("=========================")
  //     console.log("PAIRS  GROUP", pair)

  //     if(str === "USD"){
  //      nop = volume
  //     }else if(str1 === "CADCHF" || str1 === "CADJPY" || str1 === "CHFJPY"){
  //       nop = volume  * (1 / result.bid)
  //     }else {
  //       nop = volume * result.bid
  //     }

  //     if(str === "USD"){
  //       lotsLimit = nopLimit / 100000
  //      }else if(pair === "XAUUSD"){
  //       lotsLimit = nopLimit / (100 * result.bid)
  //      }else{
  //       lotsLimit = nopLimit / (100000 * result.bid)
  //      }

  //      console.log("FROM NOP", result)

  //     return {nop, lotsLimit, result};

    
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };



  // const getNetOpenPosition = async (pair, lots, nopLimit, sell, buy) => {
  //   try {
  //     const response = await fetch(`https://mt-client-api-v1.new-york.agiliumtrade.ai/users/current/accounts/80102068-bf41-4e79-88aa-eeb8fa042763/symbols/${(pair === "AUDJPY.mic" || pair === "AUDNZD.mic" || pair === "AUDCAD.mic" ) ? "AUDUSD.mic" : (pair === "CADCHF.mic" || pair === "CADJPY.mic") ? "USDCAD.mic" : pair === "CHFJPY.mic" ? "USDCHF.mic" :  (pair === "EURAUD.mic" || pair === "EURCAD.mic" || pair === "EURCHF.mic" || pair === "EURGBP.mic" || pair === "EURJPY.mic" || pair === "EURNZD.mic") ? "EURUSD.mic" : (pair === "NZDJPY.mic" || pair === "NZDCAD.mic" || pair === "NZDCHF.mic") ? "NZDUSD.mic" : (pair === "GBPAUD.mic" || pair === "GBPCAD.mic" || pair === "GBPCHF.mic"  || pair === "GBPJPY.mic" || pair === "GBPNZD.mic") ? "GBPUSD.mic" : pair}/current-price`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         "auth-token": token,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();
  //     let nop, lotsLimit

  //     var str = pair.substring(0,3);
  //     var str1 = pair.substring(0,6);
   
  //     const volume = Math.abs(buy - sell)

  //     console.log("BUY", buy)
  //     console.log("=========================")
  //     console.log("SELL", sell)
  //     console.log("=========================")
  //     console.log("VOLUME", volume)
  //     console.log("=========================")
  //     console.log("PAIRS", pair)

  //     if(str === "USD"){
  //      nop = volume
  //     }else if(str1 === "CADCHF" || str1 === "CADJPY" || str1 === "CHFJPY"){
  //       nop = volume  * (1 / result.bid)
  //     }else {
  //       nop = volume * result.bid
  //     }

  //     if(str === "USD"){
  //       lotsLimit = nopLimit / 100000
  //      }else if(pair === "XAUUSD"){
  //       lotsLimit = nopLimit / (100 * result.bid)
  //      }else{
  //       lotsLimit = nopLimit / (100000 * result.bid)
  //      }

  //      console.log("FROM NOP", result)

  //     return {nop, lotsLimit, result};

    
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };


  // const getProfits = async (id) => {
  //   try {
  //     const response = await fetch(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("sending plays failed.");
  //     }

  //     const result = await response.json();

  //     console.log("PROFIT APIIIIII  GROUP", result);
  //     return result;

    
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const fetchOpenTrades = async(id) => {
  //   try{
  //       const response = await fetch(
  //         `${env.pbTrackingUrl}/api/statistics/open-trades/${id}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("Fetching Open Trades failed.");
  //       }
  
  //       const result = await response.json();
  //       const newVolume = result.reduce((n, {volume}) => n + volume, 0)
  //       // const newVolume = result.reduce((n, {volume}) => n + volume, 0)
  
  //       // console.log("OPEN TRADES FROM THE API OVERVIEW  GROUP", result)

  //       // console.log("OPEN TRADES FROM THE API OVERVIEW VOLUME  GROUP", newVolume)
  
  //       return {volume: newVolume, allData: result};

  //     } catch (error) {
  //       console.log(error);
        
  //     }
  // };

  // const fetchSingleCompany =  async(companyId) => {
  //   try {
  //       const response = await fetch(
  //         `${env.primeBridgeUrl}/api/company/${companyId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  
  //       if (!response.ok) {
  //         throw new Error("fetching company failed.");
  //       }
  
  //       const result = await response.json();

  //       console.log("SINGLE COMPANY", result)
  //       setCurrentNopLimit(result.nopLimit)
        
  //       dispatch(fetchAllOverviewAccounts(companyId))
  //       dispatch(fetchAllManagementAccounts(companyId))
  //       dispatch(fetchAllLPGroups(companyId))
  //       return result;
  
  //     } catch (error) {
  //       console.log(error);
  //     }
  // };

  

  const statisticsData = [
    {
      desc: "NOP Limit:",
      amount: selectedOption?.nopLimit
    },
    {
      desc: "Group NOP:",
      amount: totalNOP
    },
    {
      desc: "Remaining NOP Capacity:",
      amount: nopCapacity
    }
  ]
    return(
     
       <div
            className={`d-flex justify-content-center align-items-center ${classes.upgradeModal}`}
          >
            <div className={classes.actionCard}>
              <div
                className={`${classes.cardHeader} d-flex justify-content-between align-items-center`}
              >
                <div></div>
                <CloseIcon onClick={closeModal} themeColor={themeMode === "light" ? "#141414" : "#fff"}/>
              </div>
              <div
                className={`d-flex flex-column justify-content-center align-items-center gap-2 ${classes.cardContent}`}
              >

{isLoading && <div className={`${classes.loadingWrapper} d-flex flex-column align-items-center `}>
      <div className="position-relative" style={{height: "250px"}}><Loader /></div>
      <p>Optimizing assets, please standby</p>
    </div>}
    {!isLoading && <>
  
                <div className="d-flex justify-content-between align-items-start w-100 ps-3 pe-5">
                  <div className={classes.dropDownWrapper}>
                    <div className={classes.groupDropDown}>
                      <div className="d-flex justify-content-between align-items-center" onClick={() => setShowDropDwon(!showDropDwon)} role="button">
                      {selectedOption?.groupName} <DropDownIcon themeColor="#0077B5" dropDown={showDropDwon}/>
                      </div>
                      {(showDropDwon && (lpGroups.length > 1)) &&  (<div className="mt-2">
                        {lpGroups.map((item, i) => (item.groupName !== selectedOption?.groupName && <div onClick={() => {setSelectedOption(item); setShowDropDwon(false)}} role="button">{item.groupName}</div>))}
                      </div>)}
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-end gap-2">
                    {statisticsData.map((item, i) => (<div className={classes.statistics} key={i}>{item.desc} <span>{nFormatter(item?.amount, 2)}</span></div>))}
                  </div>
                </div>
                 <div className={classes.tableDiv}>
        <table>
          <GroupDetailsHeader />
          
          {groupAccounts?.map((item, i) => (<GroupDetailsRow data={item} nopLimit={selectedOption?.nopLimit} key={i}  totalNOP={totalNOP} />))}
        </table>
        
      </div>
      </>}
              </div>
              <div></div>
            </div>
          </div>
    )
}

export default GroupDetailsModal