import React from 'react'
import './switcher.style.scss'

const Switcher = ({ value, onChange }) => {
  const change = () => {
    onChange(!value)
  }

  return (
    <div
      id='switcher'
      className={value ? 'switcher active' : 'switcher'}
      onClick={change}
    >
      <div className='handle'></div>
    </div>
  )
}

export default Switcher
