import { fullMonthDate, numberWithCommas } from "../../../../helpers/GetDuration";
import classes from "./ProfitChartCustomTooltip.module.css"

const ProfitChartCustomTooltip = ({ active, payload, label, selectedOption }) => {
    if (active && payload && payload.length) {
      return (
        <div className={`${classes.wrapper} gain-chart-tooltip-wrapper`}>
          <h6>Profit</h6>
          <h6>{fullMonthDate(payload[0]?.payload?.date)}</h6>
          <p className="label">{`${payload[0].value >= 0 ? "$" + numberWithCommas(payload[0].value) : "-$" + numberWithCommas(Math.abs(payload[0].value))}`}</p>
          {/* <div>
            {payload.map((pld) => (
              <div style={{ display: "inline-block", padding: 10 }}>
                <div style={{ color: pld.fill }}>{pld.value}</div>
                <div style={{ color: "gray" }}>{pld.dataKey} drivers</div>
              </div>
            ))}
          </div> */}
        </div>
      );
    }
  
    return null;
  };

  export default ProfitChartCustomTooltip