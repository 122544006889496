import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: "",
    token: ""
  },
  reducers: {

    getUser (state, action) {
      state.user = action.payload.user
    },
    setToken(state, action) {
      state.token = action.payload.token;
    },
  }
});

export const usersActions = usersSlice.actions;

export default usersSlice;
