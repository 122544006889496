import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { getCookie } from "../../utilities/functions";
import AddMember from "./AddMember";
import EditMember from "./EditMember";
import MembersTable from "./MembersTable";




const Team = () => {
  const navigate = useNavigate();
  const isAdmin = getCookie('isAdmin')

  useEffect(() => {
    if (!isAdmin) {
      navigate("/dashboard")
    }
  }, [])
  return (
    <div className="main-divs">
      <Routes>
        <Route path="/" element={<MembersTable />} />
        <Route path="/add-member" element={<AddMember />} />
        <Route path="/edit-member/:id" element={<EditMember />} />
      </Routes>
    </div>
  )
}

export default Team