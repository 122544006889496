import { create } from 'zustand'
import { DestOptions } from './constants'

const useSendFundsStore = create((set, get) => ({
    page: 0,
    pageNumber: 0,
    showBankAddresses: false,
    showCryptoAddresses: false,
    showDetails: false,
    firstDetails: {
        source: null,
        destination: ""
    },
    secondDetails: {
        code: "",
        amount: "",
    },
    cryptoDetails: {
        coin: "",
        walletAddress: "",
        saveAddress: false
    },
    bankDetails: {
        bankName: "",
        accountName: "",
        accountNumber: "",
        address: "",
        sortCode: "",
        swift: "",
        saveAddress: false
    },
    toggleBankAddresses: () => {
        return set(() => ({
            showBankAddresses: !get().showBankAddresses
        }))
    },
    toggleCryptoAddresses: () => {
        return set(() => ({
            showCryptoAddresses: !get().showCryptoAddresses
        }))
    },
    saveFirstDetails: ({ source, destination }) => set(() => ({
        firstDetails: {
            source, destination
        }
    })),
    saveSecondDetails: ({ code, amount }) => set(() => ({
        secondDetails: {
            amount, code
        }
    })),
    saveCryptoDetails: ({ coin, walletAddress, saveAddress }) => set(() => ({
        cryptoDetails: {
            walletAddress, coin, saveAddress
        }
    })),
    saveBankDetails: ({ bankName, accountName, accountNumber,
        address, sortCode, swift, saveAddress }) => set(() => ({
            bankDetails: {
                bankName,
                accountName,
                accountNumber,
                address,
                sortCode,
                swift,
                saveAddress
            }
        })),
    resetPage: () => set(() => ({
        page: 0,
        pageNumber: 0,
        showBankAddresses: false,
        showCryptoAddresses: false,
        showDetails: false,
        firstDetails: {
            source: null,
            destination: ""
        },
        secondDetails: {
            code: "",
            amount: "",
        },
        cryptoDetails: {
            coin: "",
            walletAddress: "",
            saveAddress: false
        },
        bankDetails: {
            bankName: "",
            accountName: "",
            accountNumber: "",
            address: "",
            sortCode: "",
            swift: "",
            saveAddress: false
        },
    })),
    isBankMethod: () => get().firstDetails.destination === DestOptions[0],
    isCryptoMethod: () => get().firstDetails.destination === DestOptions[1],
    isCashMethod: () => get().firstDetails.destination === DestOptions[2],
    nextPage: () => set((state) => ({
        page: state.page + 1
    })),
    completePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page + 1 ? state.pageNumber : state.page + 1
    })),
    inCompletePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page ? state.pageNumber - 1 : state.page
    }))
}))

export default useSendFundsStore;