import { HStack, VStack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import { useEffect, useState } from "react";
import { usePageContext } from "../../../contexts/page.context";
import { Text } from "../../../components/text";
import Tip from "./tip/Tip";
import { numberWithCommas } from "../../../../../utilities/functions";
import NextButton from "./../../../components/NextButton";
import useSendCode from "../hooks/useSendCode";
import useSendFundsStore from "../store";
import useVerifyCode from "../hooks/useVerifyCode";
import useVerifyTransferCode from "../hooks/useVerifyTransferCode";
import useSendTransferCode from "../hooks/useSendTransferCode";
import PercentButton from "./PercentButton/PercentButton";
import { env } from "../../../../../constants/env";
import axios from "axios";

const AmountInput = () => {
  const { lightMode } = usePageContext();
  const [amount, setAmount] = useState("");
  const [code, setCode] = useState("");
  const { mutateAsync: verifyCode } = useVerifyCode();
  const { mutateAsync: requestCode } = useSendCode();
  const { mutateAsync: verifyTransferCode } = useVerifyTransferCode();
  const { mutateAsync: requestTransferCode } = useSendTransferCode();
  const saveSecondDetails = useSendFundsStore((s) => s.saveSecondDetails);
  const source = useSendFundsStore(s => s.firstDetails.source)
  const isCashMethod = useSendFundsStore((s) => s.isCashMethod);
  const completePage = useSendFundsStore((s) => s.completePage);
  const nextPage = useSendFundsStore((s) => s.nextPage);
  const inCompletePage = useSendFundsStore((s) => s.inCompletePage);
  const [percentage, setPercentage] = useState()
  const [realizedProfit, setRealizedProfit] = useState(0)
  const [balance, setBalance] = useState(0)

  const sendCode = () => {
    requestCode();
  };

  console.log("ACCOUNTS SOURCE SAVED", source)

  const handleSubmit = () => {
    verifyCode(code).then(() => {
      if (parseFloat(amount) >= 1000) {
        saveSecondDetails({
          amount,
          code,
        });
        nextPage();
      }
    });
  };

  useEffect(() => {
    if (!!amount.length && !!code.length) completePage();
    else inCompletePage();
    return () => {};
  }, [amount, code]);

  useEffect(() => {
    getProfits(source?.credentials?.apiId)
  },[])


  const getProfits = async (id) => {
    try {
      const config = {
        headers: { 
          "Content-Type": "application/json",
        }
      };
      const result = await axios.get(`${env.pbTrackingUrl}/api/stats/profits-api/${id}`, config)
  
      const profit = result.data.balance - (source?.credentials?.depositCorrection ? source?.credentials?.depositCorrection : 0)

      console.log("REALISED PROFIT", source?.cashAccount)

      setBalance(result.data.balance)

      setRealizedProfit(profit)

  
      return result?.data;
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <HStack
      w={"100%"}
      mt={"8px"}
      gap={"54px"}
      justifyContent={"space-between"}
      alignItems={"flex-end"}
    >
      <VStack flexGrow={1} alignItems={"19px"}>
      <HStack justifyContent={"space-between"}>
      <HStack>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount
        </Text>
        <Tip text="The withdrawal limit is either equal to or less than your current realized profit." />
        </HStack>
        {(realizedProfit > 0 || (source?.cashAccount && (balance > 0))) && <HStack>
          <PercentButton number="25%" active={percentage === "25"} onClick={() => { setPercentage("25"); setAmount((0.25 * (source?.cashAccount ? balance : realizedProfit))?.toFixed(2)) }}/>
          <PercentButton number="50%" active={percentage === "50"} onClick={() => { setPercentage("50"); setAmount((0.5 * (source?.cashAccount ? balance : realizedProfit))?.toFixed(2)) }}/>
          <PercentButton number="100%" active={percentage === "100"} onClick={() => { setPercentage("100"); setAmount((source?.cashAccount ? balance : realizedProfit)?.toFixed(2))}}/>
        </HStack>}
      </HStack> 
        <HStack
          width={"100%"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          px={"18px"}
          py={"12px"}
        >
          <Text fontSize={"14px"} color={lightMode ? "black" : "white"}>
            US$
          </Text>
          <Input
            px={0}
            value={amount ? numberWithCommas(amount) : ""}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              setPercentage("")
              // const str = e.target.value.replace("$", "").replaceAll(",", "");

              const str = e.target.value.replaceAll(",", "");

              if (str.length === 0) setAmount("");
              if (/\d/.test(str))
                setAmount(e.target.value.replaceAll(",", ""));
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="Min. $1,000"
          />
          
        </HStack>
      </VStack>
      <VStack flexGrow={1} alignItems={"19px"}>
        <HStack justifyContent={"space-between"}>
          <HStack alignItems={"center"}>
            <Text fontSize={"14px"} fontWeight={"500"}>
              Security Code
            </Text>
            <Tip text="To confirm the transfer, insert the code that was sent to the admin’s
        email. Check Spam if you can't find it on the inbox." />
          </HStack>
          <Text
            cursor={"pointer"}
            onClick={sendCode}
            textDecoration={"underline"}
            color={lightMode ? "#000" : "#fff"}
            fontSize={"14px"}
            fontWeight={"500"}
          >
            Get Code
          </Text>
        </HStack>

        <HStack
          width={"100%"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          px={"18px"}
          py={"12px"}
        >
          <Input
            px={0}
            value={code}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="Insert code"
          />
        </HStack>
      </VStack>
      <NextButton
        active={!!amount && !!code && parseFloat(amount) >= 1000}
        onSubmit={handleSubmit}
      />
    </HStack>
  );
};

export default AmountInput;
