import AssetIcon from "../../icons/AssetIcon"
import TeamIcon from "../../icons/TeamIcon"
import TransactionIcon from "../../icons/TransactionIcon"
import classes from "./CategoriesMain.module.css"
import CategoryCard from "./components/CategoryCard"
import HelpCenterBreadCrumbs from "./components/HelpCenterBreadCrumb"

const CategoriesMain = () => {
    return (
        <div className={`${classes.wrapper} container`}>
            <HelpCenterBreadCrumbs mainText="Categories" root="/dashboard/help" rootText="Help Center"/>
            <div className={` d-flex justify-content-between align-items-center mt-4`}>
            <CategoryCard title={"My Assets"} name="assets">
                <AssetIcon category={true}/>
            </CategoryCard>
            <CategoryCard title={"Transactions"} name="transactions">
                <TransactionIcon category={true}/>
            </CategoryCard>
            <CategoryCard title={"Team"} name="team">
                <TeamIcon category={true}/>
            </CategoryCard>
        </div>
        </div>
        
    )
}

export default CategoriesMain