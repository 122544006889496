import { env } from "../constants/env";
import { statisticsActions } from "./statistics-slice";

export const fetchAllStatistics =  (id) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(`${env.pbTrackingUrl}/api/statistics/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Getting statistics failed.");
      }

      const result = await response.json();

      console.log("STATISTICS redux", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(statisticsActions.getStatistics({allStatistics: data}));
    } catch (error) {
      console.log(error);
    }
  };
};
