import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import PBAPIClient from "../../../../../services/prime-client";
import { getCookie } from "../../../../../utilities/functions";

const useHistoryList = () => {
  const authToken = getCookie("authToken");
  const companyId = getCookie("companyId");
  const apiClient = new PBAPIClient(
    `/transaction/company/history/${companyId}`,
    authToken
  );

  return useQuery({
    queryKey: ["history"],
    queryFn: () => apiClient.get(),
    staleTime: ms("1h"),
    enabled: !!companyId,
  });
};

export default useHistoryList;
