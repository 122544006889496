import React from 'react'
import Calendar from './Calendar'
import CalendarContextProvider from './calendar.context'

const CalendarWrapper = ({ onClose, dates }) => {
    return (
        <CalendarContextProvider>
            <Calendar dates={dates} onClose={onClose} />
        </CalendarContextProvider>
    )
}

export default CalendarWrapper