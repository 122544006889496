export const calcDuration = (date1, date2) => {
  const first = new Date(date1)
  const last = new Date(date2)
  const diffTime = last - first;
  var seconds = Math.floor((diffTime / 1000) % 60),
  minutes = Math.floor((diffTime / (1000 * 60)) % 60),
  hours = Math.floor((diffTime / (1000 * 60 * 60)) % 24),
  days = Math.floor((diffTime / (1000 * 60 * 60 * 24)) % 30),
  months = Math.floor((diffTime / (1000 * 60 * 60 * 24 * 30))),
  months = (months <= 0) ? "" : ` ${months} m`;
  days = (days <= 0) ? "" : ` ${days} d`;
  hours = (days <= 0) ? ((hours <= 0) ? "" : ` ${hours} h`) : "";
  minutes = (days || hours) ?  "" : ((minutes <= 0) ? "" : ` ${minutes} min `);
  seconds = (hours || minutes) ? "" :  ((seconds <= 0) ? "" : ` ${seconds} sec`);

  return months + days + hours + minutes + seconds;
}

export const checkNum = (num) => {
  if(num >= 0){
      return `$${numberWithCommas(Math.round(num * 100) / 100)} USD`
    }else{
      return `-$${numberWithCommas(Math.abs(Math.round(num * 100) / 100))} USD`
    }
}

export const numberWithCommas = (x) => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const getPercentage = (data, deposit) =>{
  var result = new Array(12);
  for(var i in data) {
    const percentage = (data[i].gain / deposit) * 100;
    const newPer = Math.round(percentage * 100) / 100
    var obj = {month: data[i].month, percentage: newPer, positive: +percentage >= 0 , negative: +percentage < 0 };
  
    if(data[i].month === 0) {
      result[0] = obj;
    }else if(data[i].month === 1){
      result[1] = obj;
    }else if(data[i].month === 2){
      result[2] = obj;
    }else if(data[i].month === 3){
      result[3] = obj;
    }else if(data[i].month === 4){
      result[4] = obj;
    }else if(data[i].month === 5){
      result[5] = obj;
    }else if(data[i].month === 6){
      result[6] = obj;
    }else if(data[i].month === 7){
      result[7] = obj;
    }else if(data[i].month === 8){
      result[8] = obj;
    }else if(data[i].month === 8){
      result[9] = obj;
    }else if(data[i].month === 10){
      result[10] = obj;
    }else if(data[i].month === 11){
      result[11] = obj;
    }
  }
  return result;
}

export const groupByMonth = (data) => {
    
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var result = [];  
  var dateArr = []; 
  let initialValue = 0;

  var dataByMonth= data.reduce(function(prev, currValue){ 

  let date = new Date(currValue.OpenTime); 
  let newDate = date.getMonth(); 
          
  var index = dateArr.indexOf(newDate); 
  if (index == -1) {  
      dateArr.push(newDate);  
      var obj = {month: newDate, gain: currValue.Profit};
      result.push(obj); 
  } 
  else {
  
  result[index].gain += currValue.Profit; 
  }
  return prev +currValue; 
  } //end of reduce

  , initialValue); 
  return result;
}


export const getStartDate = (days) => {
  var d = new Date();
  d.setDate(d.getDate() - days);
  var month = d.getMonth() + 1,
      day = d.getDate(),
      year = d.getFullYear()


      if (day < 10) 
        day = '0' + day;

      if (month < 10) 
        month = '0' + month;

  var date = `${year}-${month}-${day}`;

  return date
}

export const fullMonthDate = (date) => {
  var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  var d = new Date(date);
  var month = monthNames[d.getMonth()],
      day = d.getDate(),
      year = d.getFullYear()


      if (day < 10) 
        day = '0' + day;

      if (month < 10) 
        month = '0' + month;

  var date = `${month} ${day}, ${year}`;

  return date
}
