import { useEffect, useState } from "react";
import CloseIcon from "../../icons/CloseIcon";
import DropDownIcon from "../../icons/DropDownIcon";
import LinkIcon from "../../icons/LinkIcon";
import { env } from "../../constants/env";
import { getCookie } from "../../utilities/functions";
import { toast } from "react-hot-toast";

const periods = ["Real Time", "Bi Weekly", "Monthly", "Quaterly"]

const PortfolioLinkModal = ({classes, themeMode, setShowShareLink, accountId}) => {

    const [showPeriods, setShowPeriods] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState("Real Time")
    const [copiedLink, setCopiedLink] = useState(false)
    const [generatedLink, setGeneratedLink] = useState("www.trackingprimebridge.shareablelink/1234");
    const [showLink, setShowLink] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [defaultChecked, setDefaultChecked]  = useState("")
    const [currentDefault, setCurrentDefault] = useState("")

    const userId = getCookie('userId')
    const companyId = getCookie('companyId')

    useEffect(() => {
      getDafaultFrequency(userId, companyId)
    },[])

    const frequencyDefault  = async(frequency) => {
      const dataContent = {user: userId, company: companyId, frequency}
      const sendRequest = async () => {
        setIsLoading(true)
        const response = await fetch(`${env.primeBridgeUrl}/api/company/default-frequency`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent)
        });
    
        if (!response.ok) {
          setIsLoading(false)
          const result = await response.json();
          console.log("ERROR RESPONSE", result)
          throw new Error("sending plays failed.");
        }
    
        const result = await response.json();
        setIsLoading(false)
        
    
        console.log("Frequecy RESONSE", result);
        setCurrentDefault(result.frequency)
        setDefaultChecked(result.frequency)
        // toast.success(`${result.frequency} succefully set as default`)
    
        return result;
      };
    
      try {
          await sendRequest();
      } catch (error) {
        setIsLoading(false)
        console.log(error);
      }
    }

    const getDafaultFrequency = async (user, company) => {
      const sendRequest = async () => {
        const response = await fetch(
          `${env.primeBridgeUrl}/api/company/default-frequency/${user}/${company}`,
          {
            method: "GET",
            headers: {
              "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
            },
          }
        );
  
        if (!response.ok) {
          const result = await response.json();
          console.log("ERRRORR", result);
          throw new Error("Fetching Bagley failed.");
        }
  
        const result = await response.json();
        console.log("GET Frequency RESUTLS", result);

        setDefaultChecked(result.frequency)
        setSelectedPeriod(result.frequency)
  
        return result;
      };
  
      try {
         await sendRequest();
      } catch (error) {
        console.log(error);
      }
    };

    const removeFrequencyDefault  = async(frequency) => {
      const dataContent = {user: userId, company: companyId, frequency}
      const sendRequest = async () => {
        setIsLoading(true)
        const response = await fetch(`${env.primeBridgeUrl}/api/company/delete/default-frequency`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataContent)
        });
    
        if (!response.ok) {
          setIsLoading(false)
          const result = await response.json();
          console.log("ERROR RESPONSE", result)
          throw new Error("sending plays failed.");
        }
    
        const result = await response.json();
        setIsLoading(false)
        
        setDefaultChecked("")
    
        console.log("Frequecy Deleted", result);
    
        return result;
      };
    
      try {
          await sendRequest();
      } catch (error) {
        setIsLoading(false)
        console.log(error);
      }
    }

    return(
        <div className={classes.shareLinkContainer}>
          <div className="d-flex flex-row-reverse">
            <CloseIcon
              onClick={setShowShareLink}
              themeColor={themeMode === "light" ? "#000" : "#fff"}
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center mt-2">
            <h4>Portfolio Shareable Link</h4>
            <div
              className={`d-flex justify-content-center align-items-center my-3 ${classes.credentials}`}
            >
              <div className={classes.labelText}>Choose data update frequency</div>
              <div className={`${classes.periodOption}`}>
          <div
            className={`${classes.dropDownField} ${
              showPeriods ? classes.bridgeDropDown : undefined
            }`}
            onClick={() =>
               setShowPeriods(!showPeriods)
            }
            role="button"
          >
            <div
              className={`${classes.selectedOption} d-flex justify-content-between align-items-center `}
            >
              <div className="d-flex align-items-center justify-content-between w-100">
              {selectedPeriod}
                <div className={classes.iconWrapper}>
                    <DropDownIcon
                      dropDown={showPeriods}
                      themeColor={themeMode === "light" ? "#141414" : "#fff"}
                    />
                </div>
              </div>
            </div>
            {showPeriods &&
              periods.filter(function (el) {
                return el != selectedPeriod;
              }).map((item, i) => (
                <div
                  className={classes.dropDownOption}
                  onClick={() => {setSelectedPeriod(item); setCopiedLink(false); setShowLink(false)}}
                  role="button"
                  key={i}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      {/* <div className={classes.iconWrapper}>
                        {item.accountName === selectedOption.accountName && <CheckIcon />}
                      </div> */}
                      {item}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
            </div>
            {(!showLink && (defaultChecked !== selectedPeriod) && (currentDefault !== selectedPeriod )) && <button onClick={() => {setShowLink(true); setGeneratedLink(`${env.trackingUrl}/?id=${selectedPeriod === 'Real Time' ? accountId : selectedPeriod === 'Bi Weekly' ?  accountId + '&days=14' : selectedPeriod === 'Monthly' ?  accountId + '&days=30' : selectedPeriod === 'Quaterly' &&  accountId + '&days=90'}&company=${companyId}`)}}>Generate link</button>}
            {(showLink || (defaultChecked === selectedPeriod) || (currentDefault === selectedPeriod )) && <div className={`${classes.copyInput} d-flex justify-content-between align-items-center`}>
              <LinkIcon />
              <input type="text" className="form-control" value={generatedLink} />
              <button type="button" className={copiedLink && classes.copiedLinkButton} onClick={() => {setCopiedLink(true); navigator.clipboard.writeText(`${env.trackingUrl}/?id=${selectedPeriod === 'Real Time' ? accountId : selectedPeriod === 'Bi Weekly' ?  accountId + '&days=14' : selectedPeriod === 'Monthly' ?  accountId + '&days=30' : selectedPeriod === 'Quaterly' &&  accountId + '&days=90'}&company=${companyId}`)}}>{copiedLink ? "Copied!" : "Copy"}</button>
            </div>}
            {((defaultChecked !== selectedPeriod) || (currentDefault === selectedPeriod )) && <div
              className={`d-flex justify-content-center align-items-center mt-3 ${classes.checkboxContainer}`}
            >
              <input
              className={classes.checkInput}
              type="checkbox"
              name="default"
              value={selectedPeriod}
              checked={currentDefault === selectedPeriod}
              onChange={(e) => {
                if(!isLoading && (defaultChecked !== selectedPeriod)){
                  frequencyDefault(e.target.value)
                }else if(defaultChecked === selectedPeriod){
                  removeFrequencyDefault(e.target.value)
                }
              }}
            />
              <span>Set as default</span>
            </div>}
          </div>
        </div>
    )
}

export default PortfolioLinkModal