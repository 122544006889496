import { HStack, VStack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import React, { useEffect, useState } from "react";
import { Text } from "../../../../components/text";
import { usePageContext } from "../../../../contexts/page.context";
import NextButton from "./../../../../components/NextButton";
import { numberWithCommas } from "../../../../../../utilities/functions";
import useAddFundsStore from "../../store";

const BankInput = () => {
  const { lightMode } = usePageContext();
  const [amount, setAmount] = useState("");
  const nextPage = useAddFundsStore((s) => s.nextPage);
  const completePage = useAddFundsStore((s) => s.completePage);
  const inCompletePage = useAddFundsStore((s) => s.inCompletePage);
  const saveBankDetails = useAddFundsStore((s) => s.saveBankDetails);

  const handleSubmit = () => {
    if (parseFloat(amount) >= 10000) {
      saveBankDetails({
        amount,
      });
      nextPage();
    }
  };

  useEffect(() => {
    if (!!amount) completePage();
    else inCompletePage();
    return () => {};
  }, [amount]);

  return (
    <HStack w={"100%"} justifyContent={"space-between"} alignItems={"flex-end"}>
      <VStack alignItems={"19px"}>
        <Text fontSize={"14px"} fontWeight={"500"}>
          Amount
        </Text>
        <HStack
          width={"300px"}
          borderRadius={"50px"}
          bg={lightMode ? "#f5f5f5" : "#01293E"}
          px={"18px"}
          py={"12px"}
        >
          <Input
            px={0}
            value={amount ? "$" + numberWithCommas(amount) : ""}
            height={"100%"}
            flexGrow={1}
            color={lightMode ? "black" : "white"}
            onChange={(e) => {
              const str = e.target.value.replace("$", "").replaceAll(",", "");

              if (str.length === 0) setAmount("");
              if (/\d/.test(str))
                setAmount(e.target.value.replace("$", "").replaceAll(",", ""));
            }}
            border={"none"}
            outlineColor={"transparent"}
            focusBorderColor={"transparent"}
            _placeholder={{
              color: lightMode ? "#7f7f7f" : "#d0d0d0",
            }}
            fontSize={"14px"}
            bg={"transparent"}
            placeholder="Min. $10,000"
          />
          <Text fontSize={"14px"} color={lightMode ? "black" : "white"}>
            USD
          </Text>
        </HStack>
      </VStack>
      <NextButton
        active={!!amount && parseFloat(amount) >= 10000}
        onSubmit={handleSubmit}
      />
    </HStack>
  );
};

export default BankInput;
