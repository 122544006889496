import $ from 'jquery'
import { createContext, useContext, useState } from 'react'

const AddFundsContext = createContext({
    isBankMethod: false,
    isCryptoMethod: false,
    isCashMethod: false,
    page: 0,
    pageNumber: 0,
    coin: "",
    setCoin: (c) => { },
    setPage: (p) => { },
    setPageNumber: (p) => { },
    method: "",
    setMethod: (p) => { return p },
    account: "",
    setAccount: (p) => { return p },
    amount: "",
    setAmount: (p) => { },
    showDetails: false,
    toggleDetails: () => { },
})

export const exampleAccs = [
    "ABC Trading - 123456",
    "XYZ Trading - 098765",
    "Cash Account"
]

export const Coins = [
    "BTC",
    "ETH",
    "USDT",
    "USDC"
]

export const exampleMethods = [
    "Wire Transfer · 3-5 business days ",
    "Crypto · 2* confirmations",
    "Cash Account"
]

const AddFundsContextProvider = ({ children }) => {
    const [page, setPage] = useState(0)
    const [pageNumber, setPageNumber] = useState(0)
    const [coin, setCoin] = useState("")
    const [method, setMethod] = useState("")
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [showDetails, setShowDetails] = useState(false)

    const toggleDetails = () => {
        $("#toggleDetails").slideToggle()
        $("#toggleHow").slideToggle()
        var $elem = $("#toggleDetailsBtn").find("svg");
        $({ deg: showDetails ? 180 : 0 }).animate({ deg: showDetails ? 0 : 180 }, {
            duration: 200,
            step: function (now) {
                $elem.css({
                    transform: 'rotate(' + now + 'deg)'
                });
            }
        });
        setShowDetails(!showDetails)
    }

    const isBankMethod = method === exampleMethods[0]
    const isCryptoMethod = method === exampleMethods[1]
    const isCashMethod = method === exampleMethods[2]

    return (
        <AddFundsContext.Provider value={{
            coin, setCoin,
            toggleDetails,
            isBankMethod,
            isCashMethod,
            isCryptoMethod,
            page,
            pageNumber,
            setPage,
            setPageNumber,
            method,
            setMethod,
            account,
            setAccount,
            amount,
            setAmount,
            showDetails: false
        }}>
            {children}
        </AddFundsContext.Provider>
    )
}

export const AddFundsContextCustomer = ({ children }) => {
    return (
        <AddFundsContext.Consumer>
            {children}
        </AddFundsContext.Consumer>
    )
}

export const useAddFundsContext = () => {
    const context = useContext(AddFundsContext)
    if (context === undefined) {
        throw new Error('useAddFundsContext must be used within a AddFundsContextProvider')
    }
    return context
}

export default AddFundsContextProvider