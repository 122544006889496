import { Box, Center } from "@chakra-ui/layout";
import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import { usePageContext } from "../../../../contexts/page.context";
import { v4 as uuidv4 } from "uuid";

const MenuTip = ({ children, icon, value, isEditing, isDeleting }) => {
  // const id = useId()
  const [id] = useState(uuidv4());
  const [hover, setHover] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const [infoWidth, setInfoWidth] = useState(0);
  const { lightMode } = usePageContext();
  const ref = useRef();

  useEffect(() => {
    var infoWidth = $(`#${id} #info`).width();
    setInfoWidth(infoWidth);
    return () => {};
  }, []);

  useEffect(() => {
    setHover(false);

    return () => {};
  }, [value, isDeleting, isEditing]);

  const getTipDimensions = () => {
    // var top = $(`#${id}`).position().top;
    // var left = $(`#${id}`).position().left;
    var height = $(`#${id}`).height();

    var top = document.getElementById(id).getBoundingClientRect().top;
    var left = document.getElementById(id).getBoundingClientRect().left;
    setTop(top + height + 13);

    setLeft(left - infoWidth / 4);
  };

  return (
    <Box id={id}>
      <Box
        position={"absolute"}
        right={"24px"}
        top={"50%"}
        onClick={() => {
          getTipDimensions();
          setHover(!hover);
        }}
        transform={"translate(0%, -50%)"}
        id="icon"
        zIndex={"20"}
        cursor={"pointer"}
      >
        {icon}
      </Box>

      <Center
        ref={ref}
        id="info"
        _before={{
          content: '""',
          borderStyle: "solid",
          borderWidth: "0px 7px 10px 7px",
          borderColor: ` transparent  transparent ${
            lightMode ? "#f9f9f9" : "#343C40"
          } transparent`,
          position: "absolute",
          left: `${-6 + infoWidth / 4}px`,
          top: "-9px",
        }}
        zIndex={"40000"}
        fontSize={"12px"}
        lineHeight={"16px"}
        letterSpacing={"1.3px"}
        textAlign={"center"}
        position={"absolute"}
        py={"10px"}
        backgroundColor={lightMode ? "#f9f9f9" : "#343C40"}
        px={"14px"}
        borderRadius={"6px"}
        // transform={"translate(0, 100%)"}
        top={`calc(100% - 4px)`}
        left={`calc(100% - ${3 + infoWidth / 2}px)`}
        color={lightMode ? "#000" : "#fff"}
        display={hover ? "block" : "none"}
        width={"fit-content"}
      >
        {children}
      </Center>
    </Box>
  );
};

export default MenuTip;
