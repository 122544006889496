const InfoAltIcon = ({
  active,
  category,
  themeColor,
  onMouseOver,
  onMouseOut,
}) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="button"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <g id="info (18) 1" clip-path="url(#clip0_8_958)">
        <path
          id="Vector"
          d="M5.5 0C4.41221 0 3.34884 0.322569 2.44437 0.926917C1.5399 1.53126 0.834947 2.39025 0.418665 3.39524C0.00238307 4.40023 -0.106535 5.5061 0.105683 6.573C0.317902 7.63989 0.841726 8.6199 1.61092 9.38909C2.3801 10.1583 3.36011 10.6821 4.42701 10.8943C5.4939 11.1065 6.59977 10.9976 7.60476 10.5813C8.60975 10.1651 9.46874 9.46011 10.0731 8.55564C10.6774 7.65117 11 6.5878 11 5.5C10.9984 4.04179 10.4185 2.64376 9.38735 1.61265C8.35624 0.581546 6.95821 0.00157716 5.5 0ZM5.5 10.0833C4.5935 10.0833 3.70736 9.81453 2.95364 9.3109C2.19991 8.80728 1.61246 8.09146 1.26555 7.25397C0.918653 6.41647 0.827888 5.49492 1.00474 4.60584C1.18159 3.71676 1.61811 2.90008 2.2591 2.25909C2.90009 1.6181 3.71676 1.18158 4.60584 1.00473C5.49492 0.827885 6.41647 0.918651 7.25397 1.26555C8.09146 1.61245 8.80728 2.19991 9.3109 2.95364C9.81453 3.70736 10.0833 4.5935 10.0833 5.5C10.082 6.71517 9.59869 7.88018 8.73943 8.73943C7.88018 9.59869 6.71517 10.082 5.5 10.0833Z"
          fill={themeColor ? themeColor : "#383838"}
        />
        <path
          id="Vector_2"
          d="M5.49992 4.58333H5.04159C4.92003 4.58333 4.80345 4.63162 4.71749 4.71757C4.63154 4.80353 4.58325 4.9201 4.58325 5.04166C4.58325 5.16322 4.63154 5.2798 4.71749 5.36575C4.80345 5.45171 4.92003 5.49999 5.04159 5.49999H5.49992V8.24999C5.49992 8.37155 5.54821 8.48813 5.63416 8.57409C5.72012 8.66004 5.83669 8.70833 5.95825 8.70833C6.07981 8.70833 6.19639 8.66004 6.28234 8.57409C6.3683 8.48813 6.41659 8.37155 6.41659 8.24999V5.49999C6.41659 5.25688 6.32001 5.02372 6.1481 4.85181C5.97619 4.67991 5.74303 4.58333 5.49992 4.58333Z"
          fill={themeColor ? themeColor : "#383838"}
        />
        <path
          id="Vector_3"
          d="M5.5 3.66667C5.8797 3.66667 6.1875 3.35887 6.1875 2.97917C6.1875 2.59948 5.8797 2.29167 5.5 2.29167C5.1203 2.29167 4.8125 2.59948 4.8125 2.97917C4.8125 3.35887 5.1203 3.66667 5.5 3.66667Z"
          fill={themeColor ? themeColor : "#383838"}
        />
      </g>
      <defs>
        <clipPath id="clip0_8_958">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default InfoAltIcon;
