import React, { useEffect, useState } from "react";
import InfoIcon from "../../../components/Info.icon";
import { Icon } from "@chakra-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { Box, Center } from "@chakra-ui/layout";
import { usePageContext } from "../../../contexts/page.context";
import $ from "jquery";

const Tip = () => {
  const [id] = useState(uuidv4());
  const { currentTheme } = usePageContext();
  const [hover, setHover] = useState(false);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const getTipDimensions = () => {
    var height = $(`#${id} #icon`).height();
    var width = $(`#${id} #icon`).width();
    var iconsTop = document.getElementById(id)?.getBoundingClientRect().top;
    var iconsLeft = document.getElementById(id)?.getBoundingClientRect().left;
    setTop(iconsTop + height + 13);
    setLeft(iconsLeft - 500 + 13 + width);
  };

  return (
    <Box id={`${id}`} position={"relative"}>
      <Box
        onMouseEnter={() => {
          getTipDimensions();
          setHover(true);
        }}
        onMouseLeave={() => setHover(false)}
        id="icon"
        cursor={"pointer"}
      >
        <Icon display={"inline-block"} as={InfoIcon} />
      </Box>

      <Center
        id="info"
        _before={{
          content: '""',
          borderStyle: "solid",
          borderWidth: "0 7px 10px 7px",
          borderColor: `transparent  transparent ${
            currentTheme === "light" ? "#f9f9f9" : "#343C40"
          } transparent`,
          position: "absolute",
          right: "15px",
          top: "-9px",
        }}
        whiteSpace={"normal"}
        zIndex={"300"}
        fontSize={"14px"}
        lineHeight={"16px"}
        letterSpacing={"1.3px"}
        fontWeight={"400"}
        textAlign={"center"}
        position={"fixed"}
        py={"10px"}
        bg={currentTheme === "light" ? "#f9f9f9" : "#343C40"}
        px={"14px"}
        borderRadius={"6px"}
        top={`${top}px`}
        left={`${left}px`}
        color={currentTheme === "light" ? "#000" : "#fff"}
        display={hover ? "block" : "none"}
        width={"500px"}
      >
        These are profits or losses from positions that have been closed.
        Realized profits can be transferred to your cash account or directly to
        an external bank or crypto account.
      </Center>
    </Box>
  );
};

export default Tip;
