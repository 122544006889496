import { create } from "zustand"

const useTransferFundsStore = create((set, get) => ({
    page: 0,
    pageNumber: 0,
    source: null,
    destination: null,
    amount: "",
    code: "",
    saveSecondDetails: ({ amount, code }) => set(() => ({
        amount, code
    })),
    saveFirstDetails: ({ source, destination }) => set(() => ({
        source, destination
    })),
    resetPage: () => set(() => ({
        page: 0,
        pageNumber: 0,
        source: null,
        destination: null,
        amount: "",
        code: "",
    })),
    nextPage: () => set((state) => ({
        page: state.page + 1
    })),
    completePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page + 1 ? state.pageNumber : state.page + 1
    })),
    inCompletePage: () => set((state) => ({
        pageNumber: state.pageNumber > state.page ? state.pageNumber - 1 : state.page
    }))
}))

export default useTransferFundsStore