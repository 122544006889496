import React from "react";
import { PRIMARY_COLOR } from "../../../constants/colors";
import { usePageContext } from "../contexts/page.context";

const CaretUpIcon = () => {
  const { lightMode } = usePageContext();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
    >
      <path
        d="M1 5.33105L4.97769 1.35336C5.17295 1.1581 5.48953 1.1581 5.6848 1.35337L9.66249 5.33105"
        stroke={lightMode ? PRIMARY_COLOR : "white"}
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CaretUpIcon;
