import { useEffect, useState } from 'react';
import { env } from '../../constants/env';
import MailIcon from '../../icons/MailIcon'
import update from 'immutability-helper';
import Setup from '../UI/Setup'
import classes from './TwoFactorMain.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, setCookie } from '../../utilities/functions';
import { fetchSingleUser } from '../../Redux/users-actions';
import { toast } from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TwoFactorMain = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [loginCode, setLoginCode] = useState(['','','','','',''])
    const [searchParams, setSearchParams] = useSearchParams();
    const defaultRoute = searchParams.get("default-route")

    const userId = getCookie('userId')

    const singleUser = useSelector(state => state.users.user)

    const token = useSelector(state => state.users.token)

    const companyId = getCookie('companyId')

    useEffect(() => {
        if(loginCode.includes('')){
            console.log("STILL EMPTY")
        }else{
            const code = loginCode?.toString()?.replace(/,/g, '')
            console.log("SUCCESS", code)
            compareCode(code)
        }
    },[loginCode])

    
  
    useEffect(() => {
      dispatch(fetchSingleUser(userId))
    }, [userId])


    var elts = document.getElementsByClassName('code-input')
    Array.from(elts).forEach(function(elt){
    elt.addEventListener("keyup", function(event) {
            // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13 || elt.value.length === 1) {
            // Focus on the next sibling
            elt.nextElementSibling.focus()
        }
    });
    })

    const sendLoginCode = async () => {
        let dataContent = { user: userId, company: companyId}
        const sendRequest = async () => {
          const response = await fetch(`${env.primeBridgeUrl}/api/auth/two-factor`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataContent)
          });
    
          if (!response.ok) {
            const error = await response.json();
            console.log("SEND CODE ERROR", error);
            throw new Error("sending plays failed.");
          }
    
          const result = await response.json();
    
          console.log("Code Result", result);
          toast.success(result.message)
          setLoginCode(['','','','','',''])
    
          return result;
        };
    
        try {
          await sendRequest();
        } catch (error) {
          console.log(error);
        }
      };


      const compareCode = async (enteredCode) => {
        let dataContent = { user: userId, company: companyId, enteredCode}
        const sendRequest = async () => {
          const response = await fetch(`${env.primeBridgeUrl}/api/auth/compare-code`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataContent)
          });
    
          if (!response.ok) {
            const error = await response.json();
            toast.error(error.error)
            console.log("SEND CODE ERROR", error);
            throw new Error("sending plays failed.");
          }
    
          const result = await response.json();

          setLoginCode(['','','','','',''])
    
          console.log("Match Result", result.status);

          setCookie("authToken", token)

          navigate(`/${defaultRoute}`)
    
          return result;
        };
    
        try {
          await sendRequest();
        } catch (error) {
          console.log(error);
        }
      };

    return(
        <Setup>
            <div className={`${classes.mailIconContainer} d-flex justify-content-center align-items-center mb-4`}>
                <MailIcon />
            </div>
            <div className={`w-100 d-flex flex-column justify-content-center align-items-center ${classes.headingText}`}>
            <h3>Verification Required</h3>
            <p>Enter the code sent to {singleUser?.email}</p>
            <div className={`${classes.warningContainer} d-flex justify-content-center align-items-center`}>
            <p>ⓘ Can't find your verification code?<br />
Make sure to check your spam folder. </p>
</div>
            </div>
            <div className={`${classes.numberInputContainer} d-flex justify-content-between align-items-center`}>
            {loginCode.map((item, i) => (<input type='text' value={item} maxLength={1} className='code-input' onChange={(e) =>  {setLoginCode(update(loginCode, {$splice: [[i, 1, e.target.value]]}))} }   key={i}/>))}
            </div>

            <div className={`${classes.footer} w-100 d-flex flex-column justify-content-center align-items-center`}>
            <h6 onClick={sendLoginCode} role='button'>Send a New Code</h6>
            <div className={classes.dividerLine}></div>
            <p>Can't access your account? <a href='mailto:accounts@primebridge.io'>Contact Support</a></p>
            </div>
        </Setup>
    )
}

export default TwoFactorMain