const SearchIcon = ({ onClick, themeColor, width, height }) => {
  return (
    <svg width="21" height="22" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 47603">
    <circle id="Ellipse 167" cx="10.5" cy="10.5" r="9" stroke="#ABABAB" stroke-width="3"/>
    <rect id="Rectangle 5964" x="15" y="16.9424" width="3" height="13.1583" rx="1.5" transform="rotate(-40.3606 15 16.9424)" fill="#ABABAB"/>
    </g>
    </svg>
  );
};

export default SearchIcon;
