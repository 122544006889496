import { useMutation } from '@tanstack/react-query'
import React from 'react'
import PBAPIClient from '../../../../../services/pbapi-client'
import { getCookie } from '../../../../../utilities/functions'

const useGetBalance = () => {
    const authToken = getCookie("authToken")
    const apiClient = new PBAPIClient('/balance/api-balance', authToken)
    return useMutation({
        mutationFn: (id) => apiClient.get(id),
    })
}

export default useGetBalance