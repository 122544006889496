const CloseIcon = ({ onClick, themeColor, width, height }) => {
  return (
    <svg
      width={width ? width : "21"}
      height={height ? height : "20"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      role="button"
    >
      <path
        d="M2.09821 2L19 18M18.9018 2L2 18"
        stroke={themeColor}
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default CloseIcon;
