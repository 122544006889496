import { extendTheme } from '@chakra-ui/react'
import Text from './themes/text'
import Heading from './themes/heading'
import colors from './themes/colors'

// 2. Add your color mode config
const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
    config, colors,

    components: {
        Text,
        Heading
    }
})

export default theme