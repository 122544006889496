import { Icon } from "@chakra-ui/icon";
import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/layout";
import React, { useMemo, useState } from "react";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "../../../../../constants/colors";
import CalendarIcon from "../../../components/Calendar.icon";
import DownIcon from "../../../components/Down.icon";
import { Pagination } from "../../../components/pagination";
import RightIcon from "../../../components/Right.icon";
import CashIcon from "../../../icons/Cash.icon";
import DepositIcon from "../../../icons/Deposit.icon";
import TransferIcon from "../../../icons/Transfer.icon";
import WithdrawalIcon from "../../../icons/Withdrawal.icon";
import $ from "jquery";
import { Calendar } from "../../../components/calendar";
import { usePageContext } from "../../../contexts/page.context";
import { START_DATE } from "@datepicker-react/hooks";
import {
  getCookie,
  numberWithCommas,
} from "../../../../../utilities/functions";
import useHistoryList from "../hooks/useHistoryList";
import moment from "moment";
import months from "../../../../../constants/months";
import DownloadPage from "./DownloadPage";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import useTradingAccounts from "../../accounts/hooks/useTradingAccounts";
import { v4 as uuidv4 } from "uuid";
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import InternalTransfer from "./InternalTransfer";

const SORT_TYPES = ["All", "Add", "Send", "Transfer", "Cash Account"];

const HistoryItem = ({ item }) => {
  const [id] = useState(uuidv4());
  const [open, setOpen] = useState(false);
  const { currentTheme } = usePageContext();
  const companyId = getCookie("companyId");
  const { data: accounts } = useTradingAccounts(companyId);

  const filterDate = (date) => {
    return (
      months[moment(date).month()] +
      " " +
      moment(date).date() +
      ", " +
      moment(date).year()
    );
  };

  const toggle = () => {
    $(`#${id}`).toggle();
    setOpen(!open);
  };

  return (
    <VStack
      w={"100%"}
      borderBottom={`1px solid ${
        currentTheme === "light" ? "#e1e1e1" : "#343C40"
      } `}
      pb={"23px"}
      mb={"27px"}
    >
      <HStack
        cursor={"pointer"}
        onClick={toggle}
        alignItems={"center"}
        w={"100%"}
      >
        <Box w={"65px"}>
          <Center width={"38px"}>
            <Icon
              width={"auto"}
              height={"28px"}
              as={
                item.transactionType === "add-funds"
                  ? DepositIcon
                  : item.transactionType === "send-funds"
                  ? item.paymentMethod === "internal-transfer"
                    ? TransferIcon
                    : item.paymentMethod === "cash-account"
                    ? CashIcon
                    : WithdrawalIcon
                  : ""
              }
            />
          </Center>
        </Box>
        <HStack w={"100%"} alignItems={"flex-end"}>
          <HStack flex={1}>
            <VStack flex={1} alignItems={"flex-start"}>
              <Text
                color={currentTheme === "light" ? "black" : "white"}
                fontSize={"16px"}
                fontWeight={"400"}
              >
                {item.transactionType === "add-funds"
                  ? "Deposit"
                  : item.transactionType === "send-funds"
                  ? item.paymentMethod === "internal-transfer"
                    ? "Transfer"
                    : "Withdrawal"
                  : item.transactionType === "transfer-funds"
                  ? "Transfer"
                  : ""}
              </Text>

              <Text
                opacity={!open ? 1 : 0}
                color={currentTheme === "light" ? "black" : "white"}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                {item.paymentMethod === "wire-transfer"
                  ? "Bank Transfer"
                  : item.paymentMethod === "crypto"
                  ? "Crypto"
                  : item.paymentMethod === "internal-transfer"
                  ? "From " + item?.sourceAccount?.accountName
                  : "Cash Account"}
              </Text>
            </VStack>
          </HStack>

          <Text
            opacity={!open ? 1 : 0}
            color={currentTheme === "light" ? "black" : "white"}
            fontSize={"14px"}
            fontWeight={"400"}
          >
            {filterDate(item.createdAt)}
          </Text>

          <HStack pr={"10px"} flex={1} justifyContent={"flex-end"}>
            <VStack alignItems={"flex-end"}>
              <Text
                color={currentTheme === "light" ? "black" : "white"}
                fontSize={"16px"}
                fontWeight={"400"}
              >
                {numberWithCommas(item.totalWithdrawal)} USD
              </Text>

              <HStack
                opacity={!open ? 1 : 0}
                w={"100%"}
                gap={2}
                justifyContent={"flex-end"}
              >
                <Box
                  w={"10px"}
                  height={"10px"}
                  borderRadius={"50%"}
                  bg={
                    item.status === "pending"
                      ? "#e1e1e1"
                      : item.status === "failed" ||
                        item.status === "not-transferred" ||
                        item.status === "not-paid" ||
                        item.status === "not-received"
                      ? "#BD3437"
                      : item.status === "received" ||
                        item.status === "transferred" ||
                        item.status === "paid"
                      ? "#5DBE94"
                      : ""
                  }
                />
                <Text
                  color={currentTheme === "light" ? "black" : "white"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  {item.status === "pending"
                    ? "Pending"
                    : item.status === "failed" ||
                      item.status === "not-paid" ||
                      item.status === "not-transferred" ||
                      item.status === "not-received"
                    ? "Failed"
                    : item.status === "received" ||
                      item.status === "paid" ||
                      item.status === "transferred"
                    ? "Completed"
                    : ""}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        </HStack>
      </HStack>
      <HStack w={"100%"}>
        {item?.transactionType === "add-funds" && (
          <Deposit item={item} id={id} />
        )}
        {item?.transactionType === "send-funds" &&
          item?.paymentMethod !== "internal-transfer" && (
            <Withdrawal item={item} id={id} />
          )}
        {item?.transactionType === "send-funds" &&
          item?.paymentMethod === "internal-transfer" && (
            <InternalTransfer item={item} id={id} />
          )}
      </HStack>
    </VStack>
  );
};

const HistoryList = () => {
  const [page, setPage] = useState(1);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    focusedInput: START_DATE,
  });
  const companyId = getCookie("companyId");
  const { data: accounts } = useTradingAccounts(companyId);
  const { currentTheme } = usePageContext();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [sort, setSort] = useState(SORT_TYPES[0]);
  const { lightMode } = usePageContext();
  const { data: history, status } = useHistoryList();
  const companyName = useSelector((state) => state.company.companyName);

  const openSortMenu = () => {
    $("#sortMenu").toggle();
    var $elem = $("#sortMenu").parent().find("svg");
    $({ deg: isOpen ? 180 : 0 }).animate(
      { deg: isOpen ? 0 : 180 },
      {
        duration: 200,
        step: function (now) {
          $elem.css({
            transform: "rotate(" + now + "deg)",
          });
        },
      }
    );
    setisOpen(!isOpen);
  };

  const toggleCalendar = () => {
    $("#calendar").toggle();

    setIsCalendarOpen(!isCalendarOpen);
  };

  const filterByDate = (x) => {
    if (!dates?.startDate || !dates?.endDate) return true;
    const withinRange =
      new Date(x.createdAt).setHours(0, 0, 0, 0) >=
        new Date(dates.startDate).setHours(0, 0, 0, 0) &&
      new Date(x.createdAt).setHours(0, 0, 0, 0) <=
        new Date(dates.endDate).setHours(0, 0, 0, 0);

    return withinRange;
  };

  const filterByType = (x) => {
    if (sort === SORT_TYPES[0]) return true;

    if (sort === SORT_TYPES[1] && x.transactionType === "add-funds")
      return true;

    if (
      sort === SORT_TYPES[2] &&
      x.transactionType === "send-funds" &&
      x.paymentMethod !== "internal-transfer"
    )
      return true;

    if (
      sort === SORT_TYPES[3] &&
      x.transactionType === "send-funds" &&
      x.paymentMethod === "internal-transfer"
    )
      return true;

    if (sort === SORT_TYPES[4] && x.paymentMethod === "cash-account")
      return true;

    return false;
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * 5;
    const lastPageIndex = firstPageIndex + 5;
    return !!history
      ? history
          .filter(filterByDate)
          .filter(filterByType)
          .slice(firstPageIndex, lastPageIndex)
      : [];
  }, [page, history, sort, dates]);

  const dateRange = !!history?.length
    ? `${moment(history[history?.length - 1].createdAt).format(
        "DD/MM/YY"
      )} - ${moment(history[0].createdAt).format("DD/MM/YY")}`
    : "";
  return (
    <VStack w={"100%"} gap={"48px"}>
      <HStack w={"100%"} alignItems={"center"} justifyContent={"space-between"}>
        <Heading
          color={currentTheme === "light" ? "black" : "white"}
          fontSize={"24px"}
          fontWeight={"700"}
        >
          History
        </Heading>

        <HStack gap={"15px"}>
          <Box position={"relative"}>
            <HStack
              cursor={"pointer"}
              bg={
                isCalendarOpen || !!dates?.startDate
                  ? lightMode
                    ? SECONDARY_COLOR
                    : "#01293E"
                  : "transparent"
              }
              onClick={() => {
                if (!isCalendarOpen) toggleCalendar();
              }}
              gap={"13px"}
              px={"20px"}
              py={"12px"}
              border={`1px solid ${
                isCalendarOpen || !!dates?.startDate
                  ? PRIMARY_COLOR
                  : currentTheme === "light"
                  ? "#e1e1e1"
                  : "#343C40"
              }`}
              borderRadius={"50px"}
            >
              <CalendarIcon active={isCalendarOpen || !!dates?.startDate} />
              <Text
                color={
                  isCalendarOpen || !!dates?.startDate
                    ? PRIMARY_COLOR
                    : currentTheme === "light"
                    ? "black"
                    : "white"
                }
                mb={0}
                fontSize={"14px"}
                fontWeight={"500"}
              >
                Date
              </Text>
            </HStack>

            <VStack
              display={"none"}
              position={"absolute"}
              id="calendar"
              border={`1.5px solid ${lightMode ? "#d0d0d0" : "#343C40"}`}
              borderRadius={"30px"}
              top={"calc(100% + 13px)"}
              py={"17px"}
              px={"22px"}
              zIndex={"100"}
              bg={lightMode ? "white" : "#000A10"}
              left={"50%"}
              transform={"translate( -50%, 0%)"}
            >
              {isCalendarOpen ? (
                <Calendar
                  dates={dates}
                  onClose={(dates) => {
                    setDates(dates);
                    toggleCalendar();
                  }}
                />
              ) : null}
            </VStack>
          </Box>
          <PDFDownloadLink
            style={{
              display: "flex",
              alignItems: "center",
              borderRadius: "50px",
              gap: "13px",
              height: "50px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingVertical: "12px",
              border: `1px solid ${
                currentTheme === "light" ? "#e1e1e1" : "#343C40"
              } `,
            }}
            document={
              <DownloadPage
                accounts={accounts}
                companyName={companyName}
                history={
                  !!history
                    ? history.filter(filterByDate).filter(filterByType)
                    : []
                }
              />
            }
            fileName={`${companyName} - ${dateRange}.pdf`}
          >
            {({ loading }) => (
              <>
                <Icon width={"10px"} height={"auto"} as={DownIcon} />
                <Text
                  color={currentTheme === "light" ? "black" : "white"}
                  mb={0}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  {loading ? "Downloading..." : "Download"}
                </Text>
              </>
            )}
          </PDFDownloadLink>
          {/* <HStack onClick={() => {

                    }} cursor={"pointer"} gap={"13px"} px={"20px"} py={"12px"} border={`1px solid ${currentTheme === 'light' ? "#e1e1e1" : "#343C40"} `} borderRadius={"50px"}>

                    </HStack> */}

          <Box position={"relative"}>
            <HStack
              onClick={openSortMenu}
              cursor={"pointer"}
              w={"162px"}
              gap={"13px"}
              px={"20px"}
              py={"12px"}
              border={`1px solid ${
                currentTheme === "light" ? "#e1e1e1" : "#343C40"
              } `}
              borderRadius={"50px"}
            >
              <HStack
                zIndex={"10"}
                w={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text
                  color={currentTheme === "light" ? "black" : "white"}
                  mb={0}
                  fontSize={"14px"}
                  fontWeight={"500"}
                >
                  {sort}
                </Text>

                <Icon width={"4.5px"} height={"auto"} as={RightIcon} />
              </HStack>
            </HStack>
            <VStack
              id="sortMenu"
              display={"none"}
              w={"162px"}
              gap={0}
              top={"50%"}
              position={"absolute"}
              bg={currentTheme === "light" ? "white" : "black"}
              borderRight={`1px solid ${
                currentTheme === "light" ? "#e1e1e1" : "#343C40"
              } `}
              borderLeft={`1px solid ${
                currentTheme === "light" ? "#e1e1e1" : "#343C40"
              } `}
              borderBottom={`1px solid ${
                currentTheme === "light" ? "#e1e1e1" : "#343C40"
              } `}
              borderRadius={"0px 0px 20px 20px"}
              pt={"20px"}
            >
              {SORT_TYPES.map((x, i) => (
                <HStack
                  onClick={() => {
                    setSort(x);
                    openSortMenu();
                  }}
                  cursor={"pointer"}
                  width={"100%"}
                  _hover={{
                    bg: currentTheme === "light" ? "#f9f9f9" : "#111",
                  }}
                  w={"100%"}
                  px={"20px"}
                  key={i}
                  py={"8px"}
                >
                  <Text
                    color={currentTheme === "light" ? "black" : "white"}
                    mb={0}
                    fontSize={"14px"}
                    fontWeight={sort === x ? "700" : "500"}
                  >
                    {x}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </Box>
        </HStack>
      </HStack>

      <Wrap
        w={"100%"}
        borderRadius={"31px"}
        border={`1px solid ${
          currentTheme === "light" ? "#e1e1e1" : "#343C40"
        } `}
        px={"40px"}
        pt={"28px"}
        pb={"32px"}
      >
        <VStack w={"100%"}>
          {currentTableData.map((x, i) => (
            <HistoryItem item={x} key={i} />
          ))}

          {/* <HStack mb={"27px"} w={"100%"} pb={"23px"} borderBottom={`1px solid ${currentTheme === 'light' ? "#e1e1e1" : "#343C40"} `} alignItems={"flex-end"}>
                        <HStack flex={1}>
                            <Box w={"65px"}>
                                <Center width={"38px"} >
                                    <Icon width={"auto"} height={"28px"} as={TransferIcon} />
                                </Center>
                            </Box>
                            <VStack flex={1} alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"16px"} fontWeight={"500"}>Internal Transfer</Text>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>From ABC Trading</Text>
                            </VStack>
                        </HStack>

                        <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"400"}>Oct 22, 2023</Text>

                        <HStack pr={"10px"} flex={1} justifyContent={"flex-end"}>
                            <VStack alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"20px"} fontWeight={"500"}>10,120.20 USD</Text>
                                <HStack w={"100%"} justifyContent={"flex-end"} gap={2}>
                                    <Box w={"10px"} height={"10px"} borderRadius={"50%"} bg={"#5DBE94"} />
                                    <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Completed</Text>
                                </HStack>
                            </VStack>
                        </HStack>

                    </HStack>
                    <HStack mb={"27px"} w={"100%"} pb={"23px"} borderBottom={`1px solid ${currentTheme === 'light' ? "#e1e1e1" : "#343C40"} `} alignItems={"flex-end"}>
                        <HStack flex={1}>
                            <Box w={"65px"}>
                                <Center width={"38px"} >
                                    <Icon width={"auto"} height={"28px"} as={WithdrawalIcon} />
                                </Center>
                            </Box>
                            <VStack flex={1} alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"16px"} fontWeight={"500"}>Withdrawal</Text>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Bank Transfer</Text>
                            </VStack>
                        </HStack>

                        <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"400"}>Oct 19, 2023</Text>

                        <HStack pr={"10px"} flex={1} justifyContent={"flex-end"}>
                            <VStack alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"20px"} fontWeight={"500"}>30,954.20 USD</Text>
                                <HStack w={"100%"} justifyContent={"flex-end"} gap={2}>
                                    2<Box w={"10px"} height={"10px"} borderRadius={"50%"} bg={"#BD3437"} />
                                    <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Failed</Text>
                                </HStack>
                            </VStack>
                        </HStack>

                    </HStack>
                    <HStack mb={"27px"} w={"100%"} pb={"23px"} borderBottom={`1px solid ${currentTheme === 'light' ? "#e1e1e1" : "#343C40"} `} alignItems={"flex-end"}>
                        <HStack flex={1}>
                            <Box w={"65px"}>
                                <Center width={"38px"} >
                                    <Icon width={"auto"} height={"28px"} as={CashIcon} />
                                </Center>
                            </Box>
                            <VStack flex={1} alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"16px"} fontWeight={"500"}>Withdrawal</Text>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Cash Account</Text>
                            </VStack>
                        </HStack>

                        <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"400"}>Oct 15, 2023</Text>

                        <HStack pr={"10px"} flex={1} justifyContent={"flex-end"}>
                            <VStack alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"20px"} fontWeight={"500"}>12,341.20 USD</Text>
                                <HStack w={"100%"} justifyContent={"flex-end"} gap={2}>
                                    2<Box w={"10px"} height={"10px"} borderRadius={"50%"} bg={"#e1e1e1"} />
                                    <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Pending</Text>
                                </HStack>
                            </VStack>
                        </HStack>
                    </HStack>
                    <HStack mb={"27px"} w={"100%"} pb={"23px"} borderBottom={`1px solid ${currentTheme === 'light' ? "#e1e1e1" : "#343C40"} `} alignItems={"flex-end"}>
                        <HStack flex={1}>
                            <Box w={"65px"}>
                                <Center width={"38px"} >
                                    <Icon width={"auto"} height={"28px"} as={TransferIcon} />
                                </Center>
                            </Box>
                            <VStack flex={1} alignItems={"flex-start"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"16px"} fontWeight={"500"}>Internal Transfer</Text>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>From ABC Trading</Text>
                            </VStack>
                        </HStack>

                        <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"400"}>Oct 22, 2023</Text>

                        <HStack pr={"10px"} flex={1} justifyContent={"flex-end"}>
                            <VStack alignItems={"flex-end"}>
                                <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"20px"} fontWeight={"500"}>10,120.20 USD</Text>
                                <HStack gap={2}>
                                    2<Box w={"10px"} height={"10px"} borderRadius={"50%"} bg={"#5DBE94"} />
                                    <Text color={currentTheme === 'light' ? "black" : "white"} fontSize={"14px"} fontWeight={"500"}>Completed</Text>
                                </HStack>
                            </VStack>
                        </HStack>
                    </HStack> */}
        </VStack>

        <Pagination
          siblingCount={1}
          onPageChange={setPage}
          pageSize={5}
          totalCount={
            !!history
              ? history?.filter(filterByDate).filter(filterByType)?.length
              : 0
          }
          currentPage={page}
        />
      </Wrap>

      <VStack
        borderRadius={"20px"}
        bg={currentTheme === "light" ? SECONDARY_COLOR : "#01293E"}
        py={"14px"}
        w={"100%"}
        gap={"8px"}
      >
        <Text
          color={currentTheme === "light" ? "black" : "white"}
          fontSize={"14px"}
          fontWeight={"500"}
        >
          Powered by
        </Text>
        <Heading
          fontSize={"18px"}
          fontWeight={"700"}
          color={lightMode ? PRIMARY_COLOR : "white"}
        >
          Prime Custody
        </Heading>
      </VStack>
      <VStack w={"100%"} h={"966px"} display={"none"} py={"100px"}>
        {/* <PDFViewer>
                    <DownloadPage
                        accounts={accounts}
                        companyName={companyName}
                        history={!!history ? history
                            .filter(filterByDate)
                            .filter(filterByType) : []} />
                </PDFViewer> */}
      </VStack>
    </VStack>
  );
};

export default HistoryList;
