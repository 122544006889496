import { Box } from '@chakra-ui/layout';
import React from 'react'
import MenuContextProvider from './menu.context';

const Menu = ({ children, value, onChange, border, px, py, bg, width, }) => {
    return (
        <MenuContextProvider onChange={onChange} border={border} px={px} py={py}
            bg={bg} width={width} value={value}>
            <Box position={"relative"}>
                {children}
            </Box>
        </MenuContextProvider>

    )
}

export default Menu