import { env } from "../constants/env";
import { tradesActions } from "./trades-slice";

export const fetchClosedTrades = (id, page) => {
  return async (dispatch) => {
    // const sendRequest = async () => {
    //   const response = await fetch(
    //     `${env.pbTrackingUrl}/api/trades/closed/${id}?page=${page}&results=10`,
    //     {
    //       method: "GET",
    //       headers: {
    //         "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
    //       },
    //     }
    //   );

    //   if (!response.ok) {
    //     throw new Error("Fetching Closed Trades failed.");
    //   }

    //   const result = await response.json();

    //   return result;
    // };


    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/trades/history/${id}?page=${page}&results=6`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fetching Closed Trades failed.");
      }

      const result = await response.json();

      console.log("History", result)
      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(tradesActions.getClosedTrades({ closedTrades: data?.data }));
      dispatch(
        tradesActions.getTotalClosedTrades({ totalClosedTrades: data?.total })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchOpenTrades = (id) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/statistics/open-trades/${id}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fetching Open Trades failed.");
      }

      const result = await response.json();

      console.log("OPEN TRADES FROM THE API", result)

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(tradesActions.getOpenTrades({ openTrades: data}));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchMonthlyGain = (id) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/trades/monthlygain/${id}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Fetching Open Trades failed.");
      }

      const result = await response.json();

      return result;
    };

    try {
      const data = await sendRequest();
      // dispatch(tradesActions.getOpenTrades({ openTrades: data?.data }));
      dispatch(tradesActions.getMonthlyGain({ monthlyGain: data }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchStatistics = (id) => {
  return async (dispatch) => {
    const sendRequest = async () => {
      const response = await fetch(
        `${env.pbTrackingUrl}/api/trades/statistic/${id}`,
        {
          method: "GET",
          headers: {
            "x-api-key": "hv3zmZLmRLM6ZPIQKPhRXWRNMf71MSZE",
          },
        }
      );

      if (!response.ok) {
        const result = await response.json();
        console.log("ERRRORR", result);
        throw new Error("Fetching Bagley failed.");
      }

      const result = await response.json();
      console.log("STATISTICS DATA FROM REDUX", result);

      return result;
    };

    try {
      const data = await sendRequest();
      dispatch(tradesActions.getStatistics({ statistics: data }));
    } catch (error) {
      console.log(error);
    }
  };
};
