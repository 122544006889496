import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'company',
  initialState: {
    companyName: "",
    companyDirector: "",
    refNumber: "",
    twoFactor: false,
    custodyProvider: false,
    depositSettings: "",
    withdrawalSettings: "",
    pbShareSettings: ""
  },
  reducers: {

    getCompany (state, action) {
      state.companyName = action.payload.companyName
    },
    getDirector (state, action) {
      state.companyDirector = action.payload.companyDirector
    },

    getRefNumber (state, action) {
      state.refNumber = action.payload.refNumber
    },

    getTwoFactorStatus (state, action) {
      state.twoFactor = action.payload.twoFactor
    },

    getCustodyProviderStatus (state, action) {
      state.custodyProvider = action.payload.custodyProvider
    },

    getDepositSettings (state, action) {
      state.depositSettings = action.payload.depositSettings
    },

    getWithdrawalSettings  (state, action) {
      state.withdrawalSettings = action.payload.withdrawalSettings
    },

    getPBShareSettings (state, action) {
      state.pbShareSettings = action.payload.pbShareSettings
    },
  }
});

export const companyActions = companySlice.actions;

export default companySlice;
