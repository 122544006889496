import { createSlice } from '@reduxjs/toolkit';

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState: {
    allStatistics: [],
  },
  reducers: {

    getStatistics (state, action) {
      state.allStatistics = action.payload.allStatistics
    },
  }
});

export const statisticsActions = statisticsSlice.actions;

export default statisticsSlice;
