import { Box } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import useAddFundsStore from '../store'
import BankDetails from './bank/BankDetails'
import CryptoDetails from './crypto/CryptoDetails'

const MethodDetails = () => {
    const isBankMethod = useAddFundsStore(s => s.isBankMethod)
    const isCryptoMethod = useAddFundsStore(s => s.isCryptoMethod)
    const page = useAddFundsStore(s => s.page)

    return page === 2 ? (
        <Box w={"100%"}>
            {isBankMethod() && <BankDetails />}
            {isCryptoMethod() && <CryptoDetails />}
        </Box>
    ) : null
}

export default MethodDetails