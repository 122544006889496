import { useNavigate } from "react-router-dom";
import AuthCard from "../../components/Auth/AuthCard";
import AuthWrapper from "../../components/Auth/AuthWrapper";
import LogoContainer from "../../components/Auth/LogoContainer";
import classes from "./MobilePage.module.css";

const MobilePage = () => {
  return (
    <div className={classes.wrapper}>
      <div className="container">
        <LogoContainer />
        <div className="d-flex justify-content-center align-items-center w-100">
        <div className={`${classes.card} d-flex flex-column justify-content-center align-items-center gap-3`}>
          <img src="https://s3.amazonaws.com/cdn.prime-bridge/prime-bridge-portal/Computer_Question.svg" alt=""/>
          <div>
          <h3>Sign in on Desktop</h3>
          <p>To get the best of Prime Bridge, access the portal from your computer or laptop.</p>
          </div>
        </div>
        </div>
       
      </div>
    </div>
  );
};
export default MobilePage;
