const CopyIcon = ({ onClick, currentTheme }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      role="button"
    >
      <rect y="3" width="11" height="11" rx="2" fill= {currentTheme === "light" ? "black" : "white"} />
      <rect
        x="3.25"
        y="0.25"
        width="10.5"
        height="10.5"
        rx="1.75"
        fill={currentTheme === "light" ? "black" : "white"}
        stroke={currentTheme === "light" ? "white" : "black"}
        stroke-width="0.5"
      />
    </svg>
  );
};

export default CopyIcon;
