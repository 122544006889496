import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PoliciesCard from "../../components/AcceptPolicies/PoliciesCard";
import CompanyDocumentsCard from "../../components/CompanyDocuments/CompanyDocumentsCard";
import YourInfoCard from "../../components/GeneralInfo/YourInfoCard";
import PersonalDocumentsCard from "../../components/PersonalDocuments/PersonalDocumentsCard";
import Setup from "../../components/UI/Setup";
import VerifyCard from "../../components/Verification/VerifyCard";
import VerificationPendingCard from "../../components/VerificationPending/VerificationPendingCard";
import { env } from "../../constants/env";
import useWindowDimensions from "../../hooks/getWindowDimensions";
import { getCookie } from "../../utilities/functions";

const Verification = () => {
  const [policyAgreement, setPolicyAgreement] = useState(true);
  const [verification, setVerification] = useState(false);
  const [yourInfo, setYourInfo] = useState(false);
  const [companyDocuments, setCompanyDocuments] = useState(false);
  const [personalDocuments, setPersonalDocuments] = useState(false);
  const [pending, setPending] = useState(false);
  const [companyFiles, setCompanyFiles] = useState([]);
  const [personalFiles, setPersonalFiles] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [formObject, setFormObject] = useState();
  const { width } = useWindowDimensions();

  const navigate = useNavigate();

  const authToken = getCookie('authToken')
  const userId = getCookie('userId')

  const isAdmin = getCookie('isAdmin')
  const companyId = getCookie('companyId')


 

  const scriptURL =
    "https://script.google.com/macros/s/AKfycbyKczn-SXTu_yiKjkIsGVi-xjA5M4ke1f-FWGZ6EtvxwM05HRoJnoZqTqwhfUv3diCU/exec";




  
    useEffect(() => {
      if(isAdmin){
        getUser();
      }else{
        navigate("/dashboard")
      }
      
    }, [])
  
  
    const getUser = async () => {
      const sendRequest = async () => {
        const response = await fetch(`${env.primeBridgeUrl}/api/company/user/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}` 
          },
        });
  
        if (!response.ok) {
          throw new Error("sending plays failed.");
        }
  
        const result = await response.json();

        if(result.verification === "pending"){
          setPolicyAgreement(false)
          setVerification(false)
          setYourInfo(false)
          setCompanyDocuments(false)
          setPersonalDocuments(false)
          setPending(true);
        }else if(result.verification === "verified"){
          navigate("/dashboard")
        }
        
  
        console.log("company data", result);
  
        return result;
      };
  
      try {
        await sendRequest();
      } catch (error) {
        console.log(error);
      }
    };   


    

  const uploadImages = async () => {
    console.log("formObject", formObject);

    const arr = companyFiles.concat(personalFiles);

    const formData = new FormData();
    arr.forEach((file) => {
      formData.append("file", file);
    });

    formData.append("companyId", companyId)
    formData.append("dateOfBirth", formObject.dateOfBirth);
    formData.append("companyName", formObject.companyName);
    formData.append("phone", `(${formObject.countryNumberCode}) ${formObject.phoneNumber}`);
    formData.append("address", formObject.address);
    formData.append("city", formObject.city);
    formData.append("postalCode", formObject.postalCode);
    formData.append("province", formObject.province);
    formData.append("country", formObject.country);
    formData.append("selectedBridgeUse", formObject.selectedBridgeUse);
    formData.append("selectedSource", formObject.selectedSource);
    formData.append("verification", "pending");

    const sendRepliedMessage = async () => {
      setIsSending(true);
      // formData.append("document", file)
      const response = await fetch(
        `${env.primeBridgeUrl}/bridge-compliance/verification-documents`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        setIsSending(false);
        const error = await response.json();
        console.log("ERRROR", error)
        alert("Something went wrong. Please try again");
        throw new Error("posting data failed.");
      }

      const result = await response.json();

      console.log("Reply to message sent", result);
      onSendComplianceHandler(result);
      setPersonalDocuments(false);
      setPending(true);

      setIsSending(false);

      return result;
    };

    try {
      sendRepliedMessage();
    } catch (error) {
      console.log(error);
      setIsSending(false);
    }
  };

  const onSendComplianceHandler = async (result) => {
    const formData = new FormData();

    formData.append("submissionId", result._id);
    formData.append("firstName", result.firstName);
    formData.append("lastName", result.lastName);
    formData.append("dateOfBirth", result.dateOfBirth);
    formData.append("companyName", result.companyName);
    formData.append("email", result.email);
    formData.append("phoneNumber", result.phoneNumber);
    formData.append("address", result.address);
    formData.append("city", result.city);
    formData.append("postalCode", result.postalCode);
    formData.append("province", result.province);
    formData.append("country", result.country);
    formData.append("selectedBridgeUse", result.selectedBridgeUse);
    formData.append("selectedSource", result.selectedSource);
    formData.append("filesUploaded", result.filesUploaded);
    formData.append("verification", "pending");

    fetch(scriptURL, { method: "POST", body: formData })
      .then((response) => {
        console.log("Success!", response);
      })
      .catch((error) => {
        console.error("Error!", error.message);
      });
  };


  const updateCompany = async () => {
    let dataContent = { 
      companyName: formObject.companyName,
      phone: formObject.phoneNumber,
      address: formObject.address,
      city: formObject.city,
      province: formObject.province,
      postalCode: formObject.postalCode,
      country: formObject.country,
      verification: "pending"
    }
    const sendRequest = async () => {
      setIsSending(true);
      const response = await fetch(`${env.primeBridgeUrl}/api/company/update/${userId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${authToken}` 
        },
        body: JSON.stringify(dataContent)
      });

      if (!response.ok) {
        setIsSending(false);
        throw new Error("sending plays failed.");
      }

      const result = await response.json();

      console.log("company data", result);
      setPersonalDocuments(false);
      setPending(true);

      setIsSending(false);

      return result;
    };

    try {
      await sendRequest();
    } catch (error) {
      setIsSending(false);
      console.log(error);
    }
  };
  
  return (
    <Setup
      style={{
        height: companyDocuments && "800px",
        borderRadius: width <= 768 && 0,
        width: width <= 768 && "100vw",
      }}
    >
      {policyAgreement && (
        <PoliciesCard
          onClick={() => {
            setPolicyAgreement(false);
            setVerification(true);
          }}
        />
      )}
      {verification && (
        <VerifyCard
          onClick={() => {
            setVerification(false);
            setYourInfo(true);
          }}
        />
      )}
      <YourInfoCard
        onClick={() => {
          setYourInfo(false);
          setCompanyDocuments(true);
        }}
        formSubmition={(object) => setFormObject(object)}
        show={yourInfo}
      />
      <CompanyDocumentsCard
        onClick={() => {
          setCompanyDocuments(false);
          setPersonalDocuments(true);
        }}
        getDocuments={(files) => setCompanyFiles(files)}
        backToPrev={() => {
          setYourInfo(true);
          setCompanyDocuments(false);
        }}
        show={companyDocuments}
      />
      <PersonalDocumentsCard
        onClick={() => {uploadImages()}}
        getPersonalDocuments={(files) => setPersonalFiles(files)}
        isSending={isSending}
        backToPrev={() => {
          setCompanyDocuments(true);
          setPersonalDocuments(false);
        }}
        show={personalDocuments}
      />
      {pending && <VerificationPendingCard />}
    </Setup>
  );
};

export default Verification;
