import { fullMonthDate, numberWithCommas } from "../../../../helpers/GetDuration";
import classes from "./GainChartCustomTooltip.module.css"

const GainChartCustomTooltip = ({ active, payload, label, selectedOption }) => {
    if (active && payload && payload.length) {
      return (
        <div className={`${classes.wrapper} gain-chart-tooltip-wrapper`}>
          <h6>Gain ({(selectedOption === "Deposit") ? "Deposit" : "Liquidity"})</h6>
          <h6>{fullMonthDate(payload[0]?.payload?.date)}</h6>
          <p className="label">{`${payload[0].value}%`}</p>
          {/* <div>
            {payload.map((pld) => (
              <div style={{ display: "inline-block", padding: 10 }}>
                <div style={{ color: pld.fill }}>{pld.value}</div>
                <div style={{ color: "gray" }}>{pld.dataKey} drivers</div>
              </div>
            ))}
          </div> */}
        </div>
      );
    }
  
    return null;
  };

  export default GainChartCustomTooltip