import { useNavigate } from "react-router-dom";
import classes from "./CategoryCard.module.css"

const CategoryCard = ({children, title, name}) =>{
    const navigate = useNavigate();
    return (
        <div className={`d-flex flex-column justify-content-center align-items-center ${classes.wrapper}`} role="button" onClick={() => navigate(`/dashboard/help/categories/category/${name}`)}>
                {children}
                <h4>{title}</h4>
        </div>
    )
}   
export default CategoryCard